import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Spacing, Colors } from '../../../foundations';
import { Touchable, Icon } from '../../../components';

const Model = styled.div`
  z-index: 5;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);

  ${({ isVisible }) =>
    isVisible &&
    `
  display: block;
  pointer-events:
    `}
`;

const Header = styled.header`
  display: flex;
  margin-bottom: ${Spacing.l};
  justify-content: flex-end;
  padding: ${Spacing.s} 0;
`;

const StyledIcons = styled(Icon)`
  max-width: 15px;
`;

const Container = styled.div`
  position: fixed;
  background-color: white;
  width: 60%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 0 ${Spacing.m} ${Spacing.m} ${Spacing.m};
  width: 720px;
  height: 520px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

const DesktopInfo = ({ isVisible, onClose, children }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isVisible]);

  return (
    <Model isVisible={isVisible}>
      <Container ref={containerRef}>
        <Header>
          <Touchable
            onClick={() => {
              onClose();
            }}>
            <StyledIcons fill={Colors.black} icon="close" />
          </Touchable>
        </Header>
        <Wrapper>{children}</Wrapper>
      </Container>
    </Model>
  );
};

export default DesktopInfo;
