import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Text, Icon, Slider } from '../../components';
import { Spacing } from '../../foundations';

const StyledIcon = styled(Icon)`
  width: 110px;
  height: 110px;
  margin-bottom: ${Spacing.m};
`;

const StyledSlider = styled(Slider)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.xl};
`;

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${Spacing.xxxxl};
  right: ${Spacing.l};
  border-radius: 50%;
  padding: 8px;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  animation: pulse 2s infinite;
  cursor: pointer;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`;

const CapabilityInput = ({ title, description, name, onInfo, icon }) => (
  <React.Fragment>
    <StyledIcon icon={icon} />
    <Text align="center" size="l">
      {title}
    </Text>
    <Field component={StyledSlider} name={name} />
    <StyledText size="m" align="center" lineHeight={1.7}>
      {description}
    </StyledText>
    <InfoWrapper className="gtm-info">
      <Icon icon="info" onClick={onInfo} />
    </InfoWrapper>
  </React.Fragment>
);

export default CapabilityInput;
