import React, { createContext, useContext, useMemo } from 'react';
import useMedia from 'use-media';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  desktop: '(min-width: 1280px)',
  tablet: '(max-width: 768px)',
  mobile: '(max-width: 411px)',
};

export default function MediaQueryProvider({ children }) {
  const isMobile = useMedia(mediaQueries.mobile);
  const isDesktop = useMedia(mediaQueries.desktop);
  const isTablet = useMedia(mediaQueries.tablet);
  const value = useMemo(() => ({ isMobile, isDesktop, isTablet }), [
    isMobile,
    isDesktop,
    isTablet,
  ]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}
