import React, { useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import styled from 'styled-components';

import {
  Capability,
  Text,
  Loader,
  Layout,
  useMediaQueryContext,
  Icon,
  ReadMore,
  Button,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { copyToClipboard } from '../../utils/helpers';

const CenterGrid = styled.div`
  width: 100%;
  margin-bottom: ${Spacing.xxl};

  ${props =>
    !props.isResponsive &&
    `  overflow-y: scroll;
    flex-grow: 1;
      padding: 0px ${Spacing.l};
      max-height: 480px;`}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const GridWrapper = styled.div`
  position: relative;
`;

const ArrowWrapper = styled.div`
  padding: ${Spacing.s};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.l};
  margin-left: 0;
  width: ${props => (props.isResponsive ? '100%' : '300px')};
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: ${Spacing.s};
`;

const ButtonWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CapabilityWrapper = styled.div`
  margin-bottom: ${Spacing.s};
`;

const ConditionalWrapper = ({ conditional, isResponsive, children }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const elementsNumber = Children.count(children);
  const hasMoreThanThreeElements = elementsNumber > 3;
  const isArrowVisible = !isResponsive && hasMoreThanThreeElements;

  const handleScrollDown = () => {
    document.querySelector('.js-projects-grid').scroll({
      top: scrollPosition + 100,
      behavior: 'smooth',
    });

    setScrollPosition(scrollPosition + 100);
  };

  return (
    <GridWrapper>
      <CenterGrid className="js-projects-grid" isResponsive={isResponsive}>
        {children}
      </CenterGrid>
      {isArrowVisible && (
        <ArrowWrapper onClick={() => handleScrollDown(1)}>
          <Icon icon="arrow-down" />
        </ArrowWrapper>
      )}
    </GridWrapper>
  );
};

const tagManagerArgs = {
  dataLayer: {
    page: 'surveys',
  },
  dataLayerName: 'PageDataLayer',
};

const Surveys = ({ getSurveys, removeSurvey, isLoading, surveys }) => {
  const { id } = useParams();

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {
    getSurveys(id);
  }, [getSurveys, id]);

  const hasSurveysCreated = surveys && surveys.length > 0;

  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;
  const history = useHistory();

  return (
    <>
      {isLoading ? (
        <Layout
          hasNavigation
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          linkElement={Link}
          multiple
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Collect survey data to build your model.
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  Use this survey to gather data from people using the space
                  that you want to evaluate, or from the potential users of a
                  future space.
                  <br />
                  <br />
                  Create a new survey collector link (Copy link button) or
                  retrieve existing survey data and use it to create a model
                  (Upload ratings button).
                </ReadMore>
              </StyledText>
              <StyledButton
                isResponsive={isResponsive}
                onClick={() => history.push(`/projects/${id}/surveys/new`)}>
                <ButtonWithIconWrapper>
                  <StyledIcon icon="plus" />
                  Create new survey link
                </ButtonWithIconWrapper>
              </StyledButton>
            </React.Fragment>
          }
          rightColumn={
            <React.Fragment>
              <ConditionalWrapper
                conditional={hasSurveysCreated}
                isResponsive={isResponsive}>
                {hasSurveysCreated &&
                  surveys.map(survey => (
                    <CapabilityWrapper>
                      <Capability
                        title={survey.name}
                        description={`Participation: ${survey.answersCount} ${
                          survey.answersCount === 1 ? 'response' : 'responses'
                        }`}
                        isExtended
                        removable
                        mainAction={{
                          name: 'Upload ratings',
                          action: () =>
                            history.push(
                              `/projects/${id}/surveys/${survey.id}/details`,
                            ),
                        }}
                        secondaryAction={{
                          name: 'Copy link',
                          action: () => {
                            const surveyLink = `${process.env.REACT_APP_URL}/projects/${id}/surveys/${survey.id}`;
                            copyToClipboard(
                              surveyLink,
                              'The link to the survey has been copied correctly!',
                            );
                          },
                        }}
                        onRemove={event => {
                          const response = window.confirm(
                            'Are you sure you want to delete this survey?',
                          );

                          event.preventDefault();
                          if (response === true) removeSurvey(id, survey.id);
                        }}
                      />
                    </CapabilityWrapper>
                  ))}
              </ConditionalWrapper>
            </React.Fragment>
          }
          hasNavigation
        />
      )}
    </>
  );
};

Surveys.propTypes = {
  removeProject: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

Surveys.defaultProps = {
  projects: [],
};

export default Surveys;
