import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired, isEmail } from '@humanspace/utils/validations';

import ContactForm from './ContactForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  initialValues: state.projects.project,
});

const form = 'ContactForm';

const validate = ({ name, email, company, interested, message }) => ({
  name: isRequired(name),
  email: isRequired(email) || isEmail(email),
  company: isRequired(company),
  interested: isRequired(interested),
  message: isRequired(message),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ContactForm);

export default connect(mapStateToProps, null)(DecoratedForm);
