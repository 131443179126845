import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';

import {
  Text,
  Breadcrumb,
  Loader,
  Modal,
  Card,
  Carousel,
  Layout,
  ReadMore,
  useMediaQueryContext,
} from '@humanspace/components';
import { Colors, Spacing } from '@humanspace/foundations';

import rateYourSpace from '@humanspace/assets/images/rate_your_space.svg';
import dontSweatDetails from '@humanspace/assets/images/dont_sweat_details.svg';
import getYourResults from '@humanspace/assets/images/get_your_results.svg';

import { RatingForm, SurveySection } from '../../components';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledCarousel = styled(Carousel)`
  ${props =>
    !props.isResponsive &&
    css`
      margin-top: 60px;
    `}
`;

const ProjectRating = ({
  getModel,
  getCapabilities,
  getProject,
  isLoading,
  project,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  const isNewProject = localStorage.getItem('newProject');
  const hasRatingZeroValues =
    project.workPlacePercentageChange === 0 ||
    project.changeManagementPercentageChange === 0 ||
    project.activityPercentageChange === 0 ||
    project.interiorDesignPercentageChange === 0 ||
    project.groupSpacePercentageChange === 0 ||
    project.brandPercentageChange === 0;

  useEffect(() => {
    // if (isNewProject) setModalIsVisible(true);
    if (id) getProject(id);
  }, [getProject, id, isNewProject]);

  return (
    <React.Fragment>
      <Layout
        breadcrumb={[
          {
            text: 'Edit project information',
            onClick: () => history.push(`/projects/${id}/details`),
            active: false,
          },
          {
            text: 'People details',
            onClick: () => history.push(`/projects/${id}/details?step=2`),
            active: false,
          },
          {
            text: 'Workspace ratings',
            onClick: () => history.push(`/projects/${id}/rating`),
            active: true,
          },
        ]}
        hasNavigation
        linkElement={Link}
        multiple
        leftColumn={
          <React.Fragment>
            <Text size="xl" bold lineHeight={1.1}>
              Rate your workspace
            </Text>
            <StyledText size="m" lineHeight={1.7}>
              <ReadMore>
                To get an immediate rating of your workspace, use the buttons.
                Each button also provides information on what to consider when
                rating each capability.
              </ReadMore>
            </StyledText>

            <StyledText size="m" bold lineHeight={1.7}>
              You can rate the capabilities in any order you like, but make sure
              to provide a rating for each category.
            </StyledText>
            {!isResponsive && (
              <SurveySection isResponsive={isResponsive} id={id} />
            )}
          </React.Fragment>
        }
        rightColumn={
          <React.Fragment>
            {id && isLoading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <>
                <RatingForm
                  showContinueButton={isNewProject || hasRatingZeroValues}
                  isEdit={!isNewProject}
                  onSubmit={values => {
                    getModel(id, values);
                    getCapabilities(id, values);
                    localStorage.removeItem('newProject');
                  }}
                />
                {isResponsive && (
                  <SurveySection isResponsive={isResponsive} id={id} />
                )}
              </>
            )}

            {id && (
              <StyledBreadcrumb
                elements={[
                  {
                    text: 'Project',
                    onClick: () =>
                      history.push(`/projects/${id}/details?step=1`),
                  },
                  {
                    text: 'People',
                    onClick: () =>
                      history.push(`/projects/${id}/details?step=2`),
                  },
                  {
                    text: 'Ratings',
                    onClick: () => history.push(`/projects/${id}/rating`),
                    active: true,
                  },
                  ...(!isNewProject && !hasRatingZeroValues
                    ? [
                        {
                          text: 'Results',
                          onClick: () =>
                            history.push(`/projects/${id}/results`),
                        },
                      ]
                    : []),
                ]}
              />
            )}
            <Modal
              backgroundColor={Colors.secondary}
              withoutHeader
              isVisible={modalIsVisible}
              render={
                <StyledCarousel
                  isResponsive={isResponsive}
                  onSkip={() => setModalIsVisible(false)}>
                  <Card
                    background={rateYourSpace}
                    title="Rate your space"
                    description='Rate your workplace on six categories. As you rate your space, give  your "best guess" in percentage terms, on how much better, or worse it is, than the average office space.'
                  />
                  <Card
                    background={dontSweatDetails}
                    title="Don’t sweat the details!"
                    description="Walk around your office and rate your space, ask your work friends what they think. Don’t worry over your ratings - you can always go back and change them."
                  />
                  <Card
                    background={getYourResults}
                    title="Get your results!"
                    description="When you finish rating all six capabilities, using the magic of AI, HumanSpace™ will immediately provide your results."
                  />
                </StyledCarousel>
              }></Modal>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

ProjectRating.propTypes = {
  getModel: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProjectRating;
