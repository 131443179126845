import * as React from 'react';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy to clipboard:', err);
    }
  };

  return { isCopied, copyToClipboard };
};

export default useCopyToClipboard;
