import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  Login,
  Register,
  RegisterConfirmation,
  RequestPassword,
  ResetPassword,
  RequestPasswordConfirmation,
  ResetPasswordConfirmation,
} from './auth/screens';
import {
  Projects,
  ProjectDetails,
  ProjectRating,
  ProjectResults,
  Benchmark,
  ModelDetails,
  Surveys,
  CreateSurvey,
  CreatedSurvey,
  SurveyRating,
  CompletedSurvey,
  SurveyDetails,
  SurveyResults,
} from './projects/screens';
import { NewModel, ModelResults } from './models/screens';
import { PrivateRoute, LogoutRoute } from './shared/components';

const routes = (
  <Switch>
    <LogoutRoute exact path="/logout" />
    <PrivateRoute exact path="/projects">
      <Projects />
    </PrivateRoute>
    <PrivateRoute path="/projects/new">
      <ProjectDetails />
    </PrivateRoute>
    <PrivateRoute exact path="/projects/:id/details">
      <ProjectDetails />
    </PrivateRoute>
    <PrivateRoute path="/projects/:id/rating">
      <ProjectRating />
    </PrivateRoute>
    <PrivateRoute path="/projects/:id/results">
      <ProjectResults />
    </PrivateRoute>
    <PrivateRoute exact path="/projects/:id/surveys">
      <Surveys />
    </PrivateRoute>
    <PrivateRoute exact path="/projects/:id/surveys/new">
      <CreateSurvey />
    </PrivateRoute>
    <Route exact path="/projects/:id/surveys/:surveyId">
      <SurveyRating />
    </Route>
    <PrivateRoute exact path="/projects/:id/surveys/:surveyId/created">
      <CreatedSurvey />
    </PrivateRoute>
    <Route exact path="/projects/:id/surveys/:surveyId/completed">
      <CompletedSurvey />
    </Route>
    <Route exact path="/projects/:id/surveys/:surveyId/details">
      <SurveyDetails />
    </Route>
    <PrivateRoute exact path="/projects/:id/surveys/:surveyId/results">
      <SurveyResults />
    </PrivateRoute>
    <PrivateRoute path="/projects/:id/benchmark">
      <Benchmark />
    </PrivateRoute>
    <PrivateRoute path="/projects/:id/model-details">
      <ModelDetails />
    </PrivateRoute>
    <PrivateRoute path="/models/new">
      <NewModel />
    </PrivateRoute>
    <PrivateRoute path="/models/:id/results">
      <ModelResults />
    </PrivateRoute>

    <Route path="/login">
      <Login />
    </Route>
    <Route path="/register">
      <Register />
    </Route>
    <Route path="/register-confirmation">
      <RegisterConfirmation />
    </Route>
    <Route path="/request-password">
      <RequestPassword />
    </Route>
    <Route path="/request-password-confirmation">
      <RequestPasswordConfirmation />
    </Route>
    <Route path="/reset-password">
      <ResetPassword />
    </Route>
    <Route path="/reset-password-confirmation">
      <ResetPasswordConfirmation />
    </Route>
    <Redirect from="/" to="/projects" />
  </Switch>
);

export default routes;
