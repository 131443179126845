import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '../Text';
import Icon from '../Icon';

import { Spacing, Colors } from '../../foundations';

const getBorderRadius = ({ isLast, isFirst }) => {
  if (isLast)
    return `
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0;
  `;

  if (isFirst)
    return `
  border-top-left-radius:8px;
  border-top-right-radius: 8px;
  `;

  return 'border-radius: 0';
};

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  white-space: nowrap;
  align-self: stretch;
  box-sizing: border-box;
  height: 100%;

  ${props => getBorderRadius(props)}
`;

const PointerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.s} ${Spacing.m};
  cursor: pointer;
  background-color: ${props => (props.isOpen ? Colors.primary : 'transparent')};

  ${props =>
    props.someSectionIsOpen &&
    !props.isOpen &&
    `mix-blend-mode: soft-light;
     opacity: 0.2;
    `}

  ${props => getBorderRadius(props)}
`;

const AccordionSection = ({
  children,
  isOpen,
  isFirst,
  isLast,
  someSectionIsOpen,
  label,
  onClick,
  value,
}) => (
  <Wrapper isLast={isLast} isFirst={isFirst} isOpen={isOpen}>
    <PointerSection
      someSectionIsOpen={someSectionIsOpen}
      isOpen={isOpen}
      isLast={isLast}
      isFirst={isFirst}
      onClick={onClick}>
      <Text>
        {label} {value && `(${value})`}
      </Text>
      <div>
        {!isOpen && <Icon icon="arrow-down" />}
        {isOpen && <Icon icon="arrow-up" />}
      </div>
    </PointerSection>
    {isOpen && <div>{children}</div>}
  </Wrapper>
);

AccordionSection.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

AccordionSection.defaultProps = {
  isOpen: false,
};

export default AccordionSection;
