export const isRequired = (value, name) => {
  const finalValue = typeof value === 'number' ? String(value) : value;
  return finalValue ? undefined : `${name} is required`;
};

export const isFileRequired = (value, name) =>
  value instanceof File ? undefined : `${name} is required`;

export const isEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email is invalid'
    : undefined;

export const isDifferentFrom = (value, condition) =>
  value !== undefined && value !== condition ? undefined : 'Input is invalid';

export const isFileType = (value, fileTypes) => {
  if (fileTypes.indexOf(value.type) > -1) {
    return undefined;
  }

  return 'File should be a CSV or CAD file';
};

export const passwordMatch = (valueOne, valueTwo) =>
  valueTwo === valueOne ? undefined : "Your password doesn't match";

export const minLength = min => (value, name) =>
  value && value.length < min
    ? `Your ${name} must be ${min} characters or more`
    : undefined;
