import startCase from 'lodash/startCase';

export const getLastSize = (Sizes, size) => {
  const currentPosition = Object.keys(Sizes).indexOf(size);
  const previousSize = Object.keys(Sizes)[currentPosition - 1];
  return Sizes[previousSize];
};

export const getProperSize = (Sizes, size, isMobile) => {
  if (isMobile && size === 'xs') return Sizes[size];
  if (isMobile) getLastSize(Sizes, size);
  return Sizes[size];
};

export const filterObject = (obj = {}, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key], key))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

export const normalizeVariableName = string =>
  startCase(string).replace(/ /g, '');

export const getArrayOfRolesThatAreNotIntoProjectRoleCharacteristics = (
  roles,
  roleCharacteristics,
) =>
  roles
    .map(x => (roleCharacteristics.find(y => y.role === x) || {}).role)
    .filter(x => x !== undefined);

export const generateObjectWithDefaultValuesMissingInProjectRoleCharacteristics = ({
  defaultRolesCharacteristics,
  rolesInsideProjectRoleCharacteristicsArray,
  project,
}) => {
  const rolesNumberInput = {
    administrative: 'numberOfAdministrative',
    executive: 'numberOfExecutives',
    technical: 'numberOfProfesionalOrTechnical',
    manager: 'numberOfManagers',
  };

  const preFormattedObject = Object.keys(defaultRolesCharacteristics)
    .filter(key => !rolesInsideProjectRoleCharacteristicsArray.includes(key))
    .reduce((obj, key) => {
      obj[key] = {
        role: key,
        number: project[rolesNumberInput[key]],
        characteristics: defaultRolesCharacteristics[key],
      };

      return obj;
    }, {});

  return Object.values(preFormattedObject);
};
