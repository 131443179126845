import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { InputGroup, Input, Button, InputFile } from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`;

const StyledInputGroup = styled(InputGroup)``;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${props => (!props.isResponsive ? '0' : Spacing.xxxxxl)};
  margin-left: auto;
  margin-right: auto;

  ${props =>
    !props.isResponsive &&
    `
position: absolute;
left: 50%;
transform: translate(-50%, 0);

&:after {
  position: absolute;
  content: '';
  bottom: -${Spacing.m};
  height: ${Spacing.m};
  width: 1px;
}

`}
`;

const NewModelForm = ({
  onNextStep,
  isResponsive,
  handleSubmit,
  synchronousError,
  invalid,
  submitting,
  formErrors,
  formMeta,
}) => {
  const isValid =
    Object.values(synchronousError).every(el => el === undefined) && !invalid;

  const isDisabled = submitting || !isValid;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledInputGroup meta={formMeta} errors={formErrors}>
        <Field
          secondary
          component={Input}
          type="text"
          name="modelName"
          placeholder="Model"
        />
        <Field
          secondary
          component={InputFile}
          name="modelFile"
          placeholder="CVS or CAD file"
        />
      </StyledInputGroup>
      <StyledButton
        disabled={isDisabled}
        backgroundColor={Colors.heliotrope}
        isResponsive={isResponsive}
        onClick={() => onNextStep(2)}>
        {'Run model'}
      </StyledButton>
    </StyledForm>
  );
};

NewModelForm.propTypes = {
  currentStep: PropTypes.number,
  onNextStep: PropTypes.func,
  isResponsive: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

NewModelForm.defaultProps = {
  currentStep: 1,
  onNextStep: () => {},
  isLoading: false,
};

export default NewModelForm;
