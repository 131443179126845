import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import { Text, Loader, Layout, ReadMore } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { RatingForm } from '../../components';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const SurveyRating = ({ isLoading, answerSurvey }) => {
  const { id, surveyId } = useParams();

  return (
    <React.Fragment>
      <Layout
        linkElement={Link}
        multiple
        leftColumn={
          <React.Fragment>
            <Text size="xl" bold lineHeight={1.1}>
              Rate your workspace
            </Text>
            <StyledText size="m" lineHeight={1.7}>
              <ReadMore>
                Use the buttons to rate your workspace experience.
                Please provide ratings for all six. We want your opinion - there
                are no right or wrong answers.
                <br />
                <br />
                When you click each button, you can get more information
                (including video) on how to rate that aspect of your office.
                This survey should only take you a few minutes and is completely
                confidential.
              </ReadMore>
            </StyledText>
          </React.Fragment>
        }
        rightColumn={
          <React.Fragment>
            {isLoading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <RatingForm
                isEdit
                showContinueButton
                onSubmit={values => {
                  answerSurvey(surveyId, id, values);
                }}
              />
            )}
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

SurveyRating.propTypes = {
  getModel: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SurveyRating;
