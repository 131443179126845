import React from 'react';
import PropTypes from 'prop-types';

import { Text, Info, Video } from '@humanspace/components';
import { Colors } from '@humanspace/foundations';

const CapabilityInfoModal = ({ isOpen, onClose, type }) => {
  switch (type) {
    case 'workPlacePercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            Think about the quality of the individual workspace features listed
            below. Then overall, rate how well your typical individual workspace
            is performing.
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Task chair
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Task lighting
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Work surface/fixed table
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Guest seating
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Height adjustable table
              </Text>
            </li>

            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Storage in workspace or locker
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Adjustable monitor arm
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/01 Individual Workspace.mp4"
          />
        </Info>
      );
    case 'groupSpacePercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            How well do your social and meeting spaces perform? Consider the
            following aspects:
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Meeting room size, location, flexibility
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Meeting room technology and furnishings
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Social spaces provide comfort, promote interaction, social
                support
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/06 Social _ group spaces.mp4"
          />
        </Info>
      );
    case 'brandPercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            How much better or worse than the average office does your office
            space reflect your brand and aesthetics? Consider the following
            features:
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Aesthetic appeal of interior design (finishes, colors,
                branding)
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Quality of Artwork in facility
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Space reflects brand and story of company
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/02 Brand _ Aesthetics.mp4"
          />
        </Info>
      );
    case 'interiorDesignPercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            Make sure you consider all of the following aspects of the space you
            are evaluating:
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Floorplan easy to learn and navigate
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Visual access within, outisde of building
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Visual landmarks available
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Social, casual interaction spaces
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Intended use of workspaces is clear
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/05 Space planning.mp4"
          />
        </Info>
      );
    case 'changeManagementPercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            How well does your workplace provide programs communicating
            workplace change, training for use of space and ergonomics, compared
            to the typical office? Consider the following aspects:
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Workplace Change
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Understand reasons for workplace change
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Workstation ergonomic assessment, training
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/03 Communication _ training.mp4"
          />
        </Info>
      );

    case 'activityPercentageChange':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <Text color={Colors.black} size="s" lineHeight={1.7}>
            Please consider the following aspects of the ambient environment:
          </Text>
          <ul>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Air quality (stuffiness, temperature, humidity)
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Acoustics, noise level
              </Text>
            </li>
            <li>
              <Text color={Colors.black} size="s" lineHeight={1.7}>
                - Quality of lighting
              </Text>
            </li>
          </ul>
          <Video
            pauseCondition={!isOpen}
            source="/videos/04 Ambient environment.mp4"
          />
        </Info>
      );
    default:
      return null;
  }
};

CapabilityInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
};

CapabilityInfoModal.defaultProps = {
  isOpen: false,
  type: undefined,
};

export default CapabilityInfoModal;
