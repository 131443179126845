import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Text, Loader, Layout, ReadMore } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';
import { NewModelForm } from '../../components/NewModelForm';

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const NewModel = ({ isLoading, createModel }) => {
  return (
    <React.Fragment>
      {isLoading ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          breadcrumb={[
            {
              text: 'Create model',
              active: true,
            },
          ]}
          multiple
          hasNavigation
          linkElement={Link}
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Create model and upload information
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  Start by naming your model, and upload the CVS or CAD file to
                  calculate the stress level impact of your workspace.
                </ReadMore>
              </StyledText>
            </React.Fragment>
          }
          rightColumn={
            <React.Fragment>
              <NewModelForm
                onSubmit={values => {
                  createModel(values);
                }}
              />
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

NewModel.propTypes = {
  createProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NewModel;
