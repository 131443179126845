import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layer = styled.div`
  background-color: ${props =>
    props.layerColor ? props.layerColor : 'rgba(0, 0, 0, 0.4)'};
`;

const Wrapper = styled.video`
  width: 100%;
  height: 100%;
  outline: 0;
`;

const Video = ({
  source,
  pauseCondition,
  autoplay,
  playsinline,
  controls,
  layerColor,
  ...props
}) => {
  const video = document.querySelector('.js-video');

  useEffect(() => {
    if (video) {
      video.load();
      if (pauseCondition) video.pause();
    }
  }, [pauseCondition, video]);

  return (
    <Layer layerColor={layerColor}>
      <Wrapper
        controls={controls}
        {...props}
        className="js-video"
        playsInline={playsinline}
        autoPlay={autoplay}>
        <source src={source} type="video/mp4" />
        Your browser does not support HTML5 video.
      </Wrapper>
    </Layer>
  );
};

Video.propTypes = {
  source: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  playsinline: PropTypes.bool,
};

Video.defaultProps = {
  autoplay: false,
  controls: true,
  playsinline: true,
};

export default Video;
