import { reduxForm, change, getFormSyncErrors, getFormMeta } from 'redux-form';
import { connect } from 'react-redux';

import {
  isRequired,
  isFileRequired,
  isFileType,
} from '@humanspace/utils/validations';

import NewModelForm from './NewModelForm';

const form = 'NewModelForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  synchronousError: getFormSyncErrors(form)(state),
  formErrors: getFormSyncErrors(form)(state),
  formMeta: getFormMeta(form)(state),
});

const mapDispatchToProps = dispatch => ({
  setRoles: value => dispatch(change(form, 'roles', value)),
});

const validate = ({ modelName, modelFile }) => ({
  modelName: isRequired(modelName, 'Model'),
  modelFile:
    isFileRequired(modelFile, 'CSV or CAD') ||
    isFileType(modelFile, ['text/csv', '']),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(NewModelForm);

export default connect(mapStateToProps, mapDispatchToProps)(DecoratedForm);
