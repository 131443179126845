import { connect } from 'react-redux';

import actions from '../../duck/actions';

import Surveys from './Surveys';

const mapStateToProps = state => ({
  surveys: state.projects.surveys,
  isLoading: state.shared.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getSurveys: projectId => dispatch(actions.getSurveys(projectId)),
  removeSurvey: (projectId, surveyId) =>
    dispatch(actions.removeSurvey(projectId, surveyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
