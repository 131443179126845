import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  cursor: pointer;
`;

const Touchable = ({ onClick, children, ...props }) => (
  <StyledButton
    {...props}
    onClick={event => {
      event.preventDefault();
      onClick();
    }}>
    {children}
  </StyledButton>
);

Touchable.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Touchable.defaultProps = {
  onClick: () => {},
};

export default Touchable;
