import { connect } from 'react-redux';
import { initialize } from 'redux-form';

import actions from '../../duck/actions';

import ProjectDetails from './ProjectDetails';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  project: state.projects.project,
});

const mapDispatchToProps = dispatch => ({
  createProject: projectConfig =>
    dispatch(actions.createProject(projectConfig)),
  updateProject: (projectConfig, projectId) =>
    dispatch(actions.updateProject(projectConfig, projectId)),
  getProject: projectId => dispatch(actions.getProject(projectId)),
  resetForms: () => {
    dispatch(initialize('DetailsForm'));
    dispatch(initialize('RatingForm'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
