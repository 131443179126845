import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { removeUserId, removeUserToken } from '../../../auth/services';

const LogoutRoute = ({ ...props }) => (
  <Route
    {...props}
    render={({ location }) => {
      removeUserId();
      removeUserToken();
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }}
  />
);

export default LogoutRoute;
