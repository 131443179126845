const greyScale = {
  black: '#000000',
  black200: 'rgba(0,0,0,0.15)',
  grey700: '#969696',
  grey300: '#F2F2F2',
  white: '#FFFFFF',
  white200: 'rgba(255, 255, 255, 0.15)',
  background: 'rgba(32,32,32, 0.3)',
};

const corporateColors = {
  primary: '#23A89C',
  secondary: '#3B2D78',
  secondary200: 'rgba(59,45,120, 0.6)',
  tertiary: '#BDBCD8',
  verticalGradient: 'linear-gradient(to bottom, #23a89c 0%,#3b2d78 100%)',
  horizontalGradient: 'linear-gradient(to right, #23a89c 0%,#3b2d78 100%)',
};

const shadows = {
  colorShadow: 'rgba(34, 6, 59, 0.4)',
  whiteShadow: 'rgba(255, 255, 255, 0.5)',
  whiteShadow200: 'rgba(255, 255, 255, 0.15)',
};

const secondaryColors = {
  anakiwa: '#83FCFD',
  anakiwaBackground: 'rgba(131,252,253, 0.6)',
  lightPink: '#FF4FB3',
  heliotrope: '#7F60FF',
  violet: '#22063B',
  maroon: '#A82358',
  cerise: '#E73D8D',
};

const flashColors = {
  error: '#EC5871',
  success: '#87CA81',
  warning: '#FFC122',
  info: '#2E87EB',
};

const Colors = {
  ...greyScale,
  ...corporateColors,
  ...secondaryColors,
  ...flashColors,
  ...shadows,
};

export default Colors;
