import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { InputGroup, Input, Button } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`;

const StyledInputGroup = styled(InputGroup)``;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${props => (!props.isResponsive ? '0' : Spacing.xxxxxl)};
  margin-left: auto;
  margin-right: auto;

  ${props =>
    !props.isResponsive &&
    `
position: absolute;
left: 50%;
transform: translate(-50%, 0);

&:after {
  position: absolute;
  content: '';
  bottom: -${Spacing.m};
  height: ${Spacing.m};
  width: 1px;
}

`}
`;

const CreateSurveyForm = ({
  isResponsive,
  handleSubmit,
  submitting,
  invalid,
  isLoading,
}) => (
  <StyledForm onSubmit={handleSubmit}>
    <StyledInputGroup>
      <Field component={Input} name="surveyName" placeholder="Insert name" />
    </StyledInputGroup>
    <StyledButton
      type="submit"
      isResponsive={isResponsive}
      isLoading={isLoading}
      disabled={submitting || invalid}>
      Create survey link
    </StyledButton>
  </StyledForm>
);

CreateSurveyForm.propTypes = {
  isResponsive: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

CreateSurveyForm.defaultProps = {
  isLoading: false,
};

export default CreateSurveyForm;
