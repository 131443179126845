import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';
import { useMediaQueryContext } from '../MediaQueryProvider';
import { Spacing, Colors, Sizes } from '../../foundations';

const getProperSize = (size, isResponsive) => {
  if (isResponsive && size === 'xxs') return Sizes[size];
  if (isResponsive) {
    const currentPosition = Object.keys(Sizes).indexOf(size);
    const previousSize = Object.keys(Sizes)[currentPosition - 1];
    return Sizes[previousSize];
  }
  return Sizes[size];
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px;

  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  border-bottom: 2px solid ${Colors.black200};
  width: 100%;
  flex: 1;

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0;
  `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
  `};
`;

const StyledSelect = styled.select`
  font-family: 'Montserrat', serif;
  color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
  font-size: ${({ isResponsive }) => getProperSize('s', isResponsive)};
  line-height: 1.4;
  box-shadow: none;
  border: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  outline: 0;
  padding: ${Spacing.s} ${Spacing.m};
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &::placeholder {
    color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
    opacity: 1;
  }

  & > option {
    color: ${Colors.black};
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${Spacing.m};
  pointer-events: none;
  transform: rotate(180deg);
`;

const Select = ({
  isLast,
  isFirst,
  disabled,
  options,
  placeholder,
  input,
  dark,
  meta: { touched, error },
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <Wrapper dark={dark} isLast={isLast} isFirst={isFirst}>
      <StyledSelect
        isResponsive={isResponsive}
        dark={dark}
        disabled={disabled}
        {...input}
        error={error && touched}>
        <option>{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      <StyledIcon icon="arrow" />
    </Wrapper>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

Select.defaultProps = {
  options: [],
  placeholder: '',
  disabled: false,
  isLast: false,
  isFirst: false,
  meta: {
    error: undefined,
    touched: false,
  },
};

export default Select;
