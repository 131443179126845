import { fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { error, success } from 'react-toastify-redux';

import { parseJwt } from '@humanspace/utils/api';

import {
  login,
  register,
  requestPassword,
  resetPassword,
  setUserId,
  setUserToken,
} from '../services';

import actions from './actions';
import types from './types';

function* loginProcess(action) {
  try {
    const { email, password } = action.payload;
    const response = yield call(login, email, password);

    if (response && response.token) {
      yield put(actions.loginSuccess());
      const decodedJwt = yield call(parseJwt, response.token);
      yield call(setUserToken, response.token);
      yield call(setUserId, decodedJwt.userId);
      yield put(push('/projects'));
    }

    if (response && response.code === 401) {
      yield put(error(response.message));
      yield put(actions.loginFailure(response.message));
    }
  } catch (error) {
    yield put(actions.loginFailure(error));
  }
}

function* registerProcess(action) {
  try {
    const response = yield call(register, action.payload);

    if (response && response.code && response.code) {
      yield put(error(response.message));
      yield put(actions.registerFailure(response.message));
    }

    if (response && response.id) {
      yield put(success('Your account was created successfully'));
      yield put(actions.registerSuccess());
      yield put(push('/register-confirmation'));
    }
  } catch (error) {
    yield put(actions.registerFailure(error));
  }
}

function* passwordRequestProcess(action) {
  try {
    const response = yield call(requestPassword, action.payload);

    if (response && response.code === 401) {
      yield put(error(response.message));
      yield put(actions.passwordRequestFailure(response.message));
    }

    if (response === true) {
      yield put(actions.passwordRequestSuccess());
      yield put(push('/request-password-confirmation'));
    }
  } catch (error) {
    yield put(actions.passwordRequestFailure(error));
  }
}

function* passwordResetProcess(action) {
  try {
    const response = yield call(resetPassword, action.payload);

    if (response && response.code) {
      yield put(error(response.message));
      yield put(actions.passwordResetFailure(response.message));
    }

    if (response === true) {
      yield put(actions.passwordResetSuccess());
      yield put(push('/reset-password-confirmation'));
    }
  } catch (error) {
    yield put(actions.passwordResetFailure(error));
  }
}

function* watchLogin() {
  yield takeLatest(types.LOGIN, loginProcess);
}

function* watchRegister() {
  yield takeLatest(types.REGISTER, registerProcess);
}

function* watchPasswordRequest() {
  yield takeLatest(types.PASSWORD_REQUEST, passwordRequestProcess);
}

function* watchPasswordReset() {
  yield takeLatest(types.PASSWORD_RESET, passwordResetProcess);
}

export default function* watchAuth() {
  yield all([
    fork(watchLogin),
    fork(watchRegister),
    fork(watchPasswordRequest),
    fork(watchPasswordReset),
  ]);
}
