import React from 'react';

import { useMediaQueryContext } from '../MediaQueryProvider';
import DesktopInfo from './DesktopInfo';
import MobileInfo from './MobileInfo';

const Info = ({ children, visible, onClose }) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  if (isResponsive)
    return (
      <MobileInfo
        onClose={() => {
          onClose();
        }}
        isVisible={visible}>
        {children}
      </MobileInfo>
    );

  return (
    <DesktopInfo
      onClose={() => {
        onClose();
      }}
      isVisible={visible}>
      {children}
    </DesktopInfo>
  );
};

export default Info;
