import { change } from 'redux-form';
import { connect } from 'react-redux';

import actions from '../../duck/actions';

import ProjectResults from './ProjectResults';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  results: state.projects.results,
  capabilities: state.projects.capabilities.features,
  squareFeet: state.projects.project.squareFeet,
  totalEmployees:
    state.projects.project.numberOfExecutives +
    state.projects.project.numberOfManagers +
    state.projects.project.numberOfAdministrative +
    state.projects.project.numberOfProfesionalOrTechnical,
  initialValues: state.projects.project,
});

const mapDispatchToProps = dispatch => ({
  getModel: (projectId, ratingConfig) =>
    dispatch(actions.getModel(projectId, ratingConfig)),
  setInterestedValue: value =>
    dispatch(change('ContactForm', 'interested', value)),
  contact: (projectId, contactInfo) =>
    dispatch(actions.contact(projectId, contactInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectResults);
