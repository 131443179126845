import { combineReducers } from 'redux';
import types from './types';

const predictionResults = (state = null, action) => {
  switch (action.type) {
    case types.GET_STRESS_MODEL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const stressModels = (state = [], action) => {
  switch (action.type) {
    case types.GET_STRESS_MODELS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  predictionResults,
  stressModels,
});

export default reducer;
