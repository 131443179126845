import { fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { success, error } from 'react-toastify-redux';

import { getFileContent } from '@humanspace/utils/helpers';

import actions from './actions';
import types from './types';

import {
  calculateIfPredictionStressedOrNot,
  createStressModel,
  generatePrediction,
  getStressModel,
  getStressModels,
  removeStressModel,
} from '../services';

import {
  formatPredictionForDB,
  formatPredictionFromDB,
} from '../utils/transformers';

function* createPredictionModelProcess(action) {
  try {
    const { modelName, modelFile } = action.payload;
    const userId = yield call([localStorage, 'getItem'], 'userId');

    const prediction = yield call(generatePrediction, { modelFile });

    if (prediction && prediction.lenght === 0) {
      const errorMessage = 'The prediction could not be generated correctly.';
      yield put(error(errorMessage));
      yield put(actions.createPredictionModelFailure({ error: errorMessage }));
      return;
    }

    const contentFile = yield call(getFileContent, modelFile);
    const predictionForDB = yield call(formatPredictionForDB, prediction);

    if (!predictionForDB) {
      const errorMessage =
        'The file you have uploaded is not correct. Remember that it should have only 12 rows.';
      yield put(error(errorMessage));
      yield put(actions.createPredictionModelFailure({ error: errorMessage }));
      return;
    }

    const response = yield call(createStressModel, {
      userId,
      name: modelName,
      prediction: predictionForDB,
      file: contentFile,
    });

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.createPredictionModelFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.createPredictionModelFailure(response.error.message));
      return;
    }

    yield put(success('The model was created correctly'));
    yield put(push(`/models/${response.id}/results`));
  } catch (error) {
    console.log('error: ', error.message);
    yield put(actions.createPredictionModelFailure({ error }));
  }
}

function* getStressModelsProcess() {
  try {
    const userId = yield call([localStorage, 'getItem'], 'userId');
    const response = yield call(getStressModels, userId);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getStressModelFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getStressModelFailure(response.error.message));
      return;
    }

    yield put(actions.getStressModelsSuccess(response));
  } catch (error) {
    yield put(actions.getStressModelsFailure({ error }));
  }
}

function* getStressModelProcess(action) {
  try {
    const { stressModelId } = action.payload;

    const response = yield call(getStressModel, stressModelId);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getStressModelFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getStressModelFailure(response.error.message));
      return;
    }

    const prediction = yield call(formatPredictionFromDB, response.prediction);

    if (!prediction) {
      yield put(push('/projects'));
    }

    const predictionResult = yield call(
      calculateIfPredictionStressedOrNot,
      prediction,
    );

    yield put(
      actions.getStressModelSuccess({
        predictionResult,
      }),
    );
  } catch (error) {
    console.log('error: ', error);
    yield put(actions.getStressModelFailure({ error }));
  }
}

function* removeStressModelProcess(action) {
  try {
    const { stressModelId } = action.payload;
    const response = yield call(removeStressModel, stressModelId);

    if (response === true) {
      yield put(actions.removeStressModelSuccess());
      yield put(actions.getStressModels());
      yield put(success('Model was removed succesfully'));
      return;
    }

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.removeStressModelFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.removeStressModelFailure(response.error.message));
      return;
    }
  } catch (error) {
    yield put(actions.removeStressModelFailure(error));
  }
}

function* watchCreatePredictionModel() {
  yield takeLatest(types.CREATE_PREDICTION_MODEL, createPredictionModelProcess);
}

function* watchGetStressModels() {
  yield takeLatest(types.GET_STRESS_MODELS, getStressModelsProcess);
}

function* watchGetStressModel() {
  yield takeLatest(types.GET_STRESS_MODEL, getStressModelProcess);
}

function* watchRemoveStressModel() {
  yield takeLatest(types.REMOVE_STRESS_MODEL, removeStressModelProcess);
}

export default function* watchModels() {
  yield all([
    fork(watchCreatePredictionModel),
    fork(watchGetStressModel),
    fork(watchGetStressModels),
    fork(watchRemoveStressModel),
  ]);
}
