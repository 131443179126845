import React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';
import Icon from '../Icon';
import Touchable from '../Touchable';

const MODULE_HEIGHT = '240px';

const StyledIcon = styled(Icon)`
  max-height: 70px;
  margin-bottom: ${Spacing.xs};
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.m} ${Spacing.s};
  background: ${props => (props.secondary ? Colors.secondary : Colors.primary)};
  border-radius: 20px;
  max-width: 200px;
  cursor: pointer;
  align-self: stretch;
  min-height: ${MODULE_HEIGHT};
  max-height: ${MODULE_HEIGHT};

  box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
`;

const StyledContent = styled(Text)`
  margin-top: ${Spacing.s};
`;

const Module = ({ title, content, icon, onClick, secondary, ...props }) => (
  <Touchable {...props} onClick={onClick}>
    <Wrapper secondary={secondary}>
      {icon && <StyledIcon icon={icon} />}
      <Text align="center" size="l" bold>
        {title}
      </Text>
      {content && (
        <StyledContent align="center" size="xxs" lineHeight={1.5}>
          {content}
        </StyledContent>
      )}
    </Wrapper>
  </Touchable>
);

export default Module;
