import React from 'react';
import styled from 'styled-components';

import { Text, Layout } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { ResetPasswordForm } from '../../components';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const ResetPassword = ({ passwordReset }) => {
  const token = new URLSearchParams(window.location.search).get('token');

  return (
    <Layout
      render={
        <>
          <StyledText align="center" size="l" bold>
            Create new password
          </StyledText>
          <ResetPasswordForm
            onSubmit={({ newPassword }) =>
              passwordReset({ newPassword, token })
            }
          />
        </>
      }
    />
  );
};

export default ResetPassword;
