import {
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
  change,
} from 'redux-form';
import { connect } from 'react-redux';

import { isRequired } from '@humanspace/utils/validations';

import DetailsForm from './DetailsForm';

const form = 'DetailsForm';

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  initialValues: state.projects.project,
  detailsFormValues: {
    numberOfExecutives: selector(state, 'numberOfExecutives'),
    numberOfManagers: selector(state, 'numberOfManagers'),
    numberOfProfesionalOrTechnical: selector(
      state,
      'numberOfProfesionalOrTechnical',
    ),
    numberOfAdministrative: selector(state, 'numberOfAdministrative'),
  },
  synchronousError: getFormSyncErrors(form)(state),
});

const mapDispatchToProps = dispatch => ({
  setRoles: value => dispatch(change(form, 'roles', value)),
});

const validate = ({
  name,
  squareFeet,
  numberOfExecutives,
  numberOfManagers,
  numberOfProfesionalOrTechnical,
  numberOfAdministrative,
  cost,
}) => ({
  name: isRequired(name),
  squareFeet: isRequired(squareFeet),
  numberOfExecutives: isRequired(numberOfExecutives),
  numberOfManagers: isRequired(numberOfManagers),
  numberOfProfesionalOrTechnical: isRequired(numberOfProfesionalOrTechnical),
  numberOfAdministrative: isRequired(numberOfAdministrative),
  cost: isRequired(cost),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(DetailsForm);

export default connect(mapStateToProps, mapDispatchToProps)(DecoratedForm);
