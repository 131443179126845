import { makeRequest } from '@humanspace/infrastructure';

export const getProjects = async userId =>
  makeRequest({
    path: `/projects?${new URLSearchParams({
      userId,
    })}`,
  });

export const getProjectById = async (projectId, userId) =>
  makeRequest({
    path: `/projects/${projectId}?${new URLSearchParams({
      userId,
    })}`,
  });

export const getProjectRoleCharacteristics = async (projectId, userId) =>
  makeRequest({
    path: `/projects/${projectId}/roles/characteristics?${new URLSearchParams({
      userId,
    })}`,
  });

export const setProjectRoleCharacteristics = async (projectId, role) =>
  makeRequest({
    path: `/projects/${projectId}/roles/characteristics`,
    method: 'POST',
    body: role,
    contentType: 'application/json',
  });

export const createProject = async projectConfig =>
  makeRequest({
    path: `/projects`,
    method: 'POST',
    body: projectConfig,
  });

export const updateProject = async (projectConfig, projectId) =>
  makeRequest({
    path: `/project`,
    method: 'PATCH',
    body: {
      ...{ id: projectId },
      ...projectConfig,
    },
  });

export const removeProject = async projectId =>
  makeRequest({
    path: `/projects/${projectId}`,
    method: 'DELETE',
    body: {
      ...{ id: projectId },
    },
  });

export const generateModel = async (projectId, modelConfig, userId) =>
  makeRequest({
    path: `/projects/${projectId}/models`,
    method: 'POST',
    body: {
      ...modelConfig,
      ...{ userId },
    },
  });

export const getCapabilities = async (projectId, config) => {
  const params = new URLSearchParams(config).toString();
  return makeRequest({
    path: `/projects/${projectId}/identify?${params}`,
    method: 'GET',
  });
};

export const getModel = async (projectId, modelConfig, userId) =>
  makeRequest({
    path: `/projects/${projectId}/model?${new URLSearchParams({
      ...modelConfig,
      ...{ userId },
    })}`,
  });

export const getSurveys = async projectId =>
  makeRequest({
    path: `/projects/${projectId}/surveys`,
  });

export const createSurvey = async (projectId, surveyName) =>
  makeRequest({
    path: `/projects/${projectId}/surveys`,
    method: 'POST',
    body: {
      name: surveyName,
    },
  });

export const answerSurvey = async (surveyId, capabilitiesValues) =>
  makeRequest({
    path: `/surveys/${surveyId}/answers`,
    method: 'POST',
    body: {
      capabilitiesValues: capabilitiesValues,
    },
  });

export const getSurveyDetails = async surveyId =>
  makeRequest({
    path: `/surveys/${surveyId}`,
  });

export const removeSurvey = async surveyId =>
  makeRequest({
    path: `/surveys/${surveyId}`,
    method: 'DELETE',
  });

export const contact = async (projectId, contactInfo) =>
  makeRequest({
    path: `/projects/${projectId}/contacts`,
    method: 'POST',
    body: contactInfo,
  });

export const getConfig = async () =>
  makeRequest({
    path: '/config',
  });
