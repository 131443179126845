import PropTypes from 'prop-types';
import { Colors } from '../../foundations';
import {
  Logo,
  WorkSpace,
  Menu,
  Close,
  FullLogo,
  FullLogoInverse,
  Plus,
  Social,
  Brand,
  Space,
  Communication,
  User,
  Back,
  Impact,
  Money,
  Building,
  Arrow,
  ResultsPositive,
  ResultsNegative,
  Info,
  Model,
  Survey,
  Identify,
  Contact,
  Trash,
  Accuracy,
  ImpactOnStress,
  ProcessTime,
  Retention,
  HCImpact,
  ArrowRight,
  Calendar,
  ArrowDown,
  ArrowUp,
  MenuInverse,
  KebadMenu,
  Vector,
} from './icons';

const Icon = props => {
  const { icon } = props;

  switch (icon) {
    case 'logo':
      return Logo(props);
    case 'full-logo':
      return FullLogo(props);
    case 'full-logo-inverse':
      return FullLogoInverse(props);
    case 'workspace':
      return WorkSpace(props);
    case 'menu':
      return Menu(props);
    case 'menu-inverse':
      return MenuInverse(props);
    case 'close':
      return Close(props);
    case 'plus':
      return Plus(props);
    case 'social':
      return Social(props);
    case 'brand':
      return Brand(props);
    case 'space':
      return Space(props);
    case 'communication':
      return Communication(props);
    case 'user':
      return User(props);
    case 'back':
      return Back(props);
    case 'impact':
      return Impact(props);
    case 'money':
      return Money(props);
    case 'building':
      return Building(props);
    case 'arrow':
      return Arrow(props);
    case 'results-positive':
      return ResultsPositive(props);
    case 'results-negative':
      return ResultsNegative(props);
    case 'info':
      return Info(props);
    case 'survey':
      return Survey(props);
    case 'model':
      return Model(props);
    case 'identify':
      return Identify(props);
    case 'contact':
      return Contact(props);
    case 'trash':
      return Trash(props);
    case 'accuracy':
      return Accuracy(props);
    case 'impact-on-stress':
      return ImpactOnStress(props);
    case 'process-time':
      return ProcessTime(props);
    case 'retention':
      return Retention(props);
    case 'hc-impact':
      return HCImpact(props);
    case 'arrow-right':
      return ArrowRight(props);
    case 'arrow-up':
      return ArrowUp(props);
    case 'arrow-down':
      return ArrowDown(props);
    case 'calendar':
      return Calendar(props);
    case 'kebad-menu':
      return KebadMenu(props);
    case 'vector':
      return Vector(props);
    default:
      return;
  }
};

Icon.propTypes = {
  fill: PropTypes.string,
};

Icon.defaultProps = {
  fill: Colors.white,
};

export default Icon;
