const Sizes = {
  xxs: '12px',
  xs: '14px',
  s: '16px',
  m: '18px',
  l: '30px',
  xl: '48px',
  xxl: '60px',
  xxxl: '72px',
};

export default Sizes;
