import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../Text';
import { Colors, Sizes, Spacing } from '../../foundations';

const StyledLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;

const StyledButton = styled.a`
  background-color: ${Colors.primary};
  border-radius: 25px;
  padding: ${Spacing.xs} ${Spacing.s};
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  font-family: 'Montserrat', serif;
  font-weight: bold;
  font-size: ${Sizes.s};
  color: ${Colors.white};
  box-shadow: 0px 20px 40px rgba(34, 6, 59, 0.4), inset 0px 1px 3px rgba(255, 255, 255, 0.5);
  height: 44px;
  }
`;

const Link = ({ children, href, type, target, ...props }) => {
  if (type === 'button') {
    return (
      <StyledButton href={href} target={target} {...props}>
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledLink isLink href={href} target={target} {...props}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Link.defaultProps = {
  target: undefined,
};

export default Link;
