import { reduxForm, getFormSyncErrors, getFormMeta } from 'redux-form';
import { connect } from 'react-redux';

import {
  isRequired,
  passwordMatch,
  minLength,
} from '@humanspace/utils/validations';

import ResetPasswordForm from './ResetPasswordForm';

const form = 'ResetPasswordForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  formErrors: getFormSyncErrors(form)(state),
  formMeta: getFormMeta(form)(state),
});

const validate = ({ newPassword, repeatNewPassword }) => ({
  newPassword:
    isRequired(newPassword, 'password') ||
    minLength(8)(newPassword, 'password'),
  repeatNewPassword:
    isRequired(repeatNewPassword, 'confirmed password') ||
    minLength(8)(repeatNewPassword, 'password') ||
    passwordMatch(newPassword, repeatNewPassword),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ResetPasswordForm);

export default connect(mapStateToProps, null)(DecoratedForm);
