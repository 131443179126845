import { fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { success, error } from 'react-toastify-redux';

import {
  getArrayOfRolesThatAreNotIntoProjectRoleCharacteristics,
  generateObjectWithDefaultValuesMissingInProjectRoleCharacteristics,
} from '@humanspace/utils/transformers';

import {
  priceFormat,
  numberFormat,
  percentageFormat,
} from '@humanspace/utils/formatters';

import {
  createProject,
  updateProject,
  getProjects,
  getProjectById,
  getProjectRoleCharacteristics,
  generateModel,
  getCapabilities,
  getModel,
  removeProject,
  contact,
  getConfig,
  setProjectRoleCharacteristics,
  getSurveys,
  createSurvey,
  removeSurvey,
  answerSurvey,
  getSurveyDetails,
} from '../services';

import actions from './actions';
import types from './types';

const projectRole = {
  ROLE_EXECUTIVE: 'executive',
  ROLE_MANAGER: 'manager',
  ROLE_TECHNICAL: 'technical',
  ROLE_ADMINISTRATIVE: 'administrative',
};

function* createProjectProcess(action) {
  try {
    const {
      name,
      squareFeet,
      cost,
      numberOfExecutives,
      numberOfManagers,
      numberOfProfesionalOrTechnical,
      numberOfAdministrative,
      roles,
    } = action.payload;

    const response = yield call(createProject, {
      name,
      squareFeet,
      cost,
      numberOfExecutives,
      numberOfManagers,
      numberOfProfesionalOrTechnical,
      numberOfAdministrative,
    });

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.createProjectFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.createProjectFailure(response.error.message));
      return;
    }

    if (roles) {
      const characteristics = {
        executive: roles.filter(
          role => role.role === projectRole.ROLE_EXECUTIVE,
        )[0],
        manager: roles.filter(
          role => role.role === projectRole.ROLE_MANAGER,
        )[0],
        technical: roles.filter(
          role => role.role === projectRole.ROLE_TECHNICAL,
        )[0],
        administrative: roles.filter(
          role => role.role === projectRole.ROLE_ADMINISTRATIVE,
        )[0],
      };

      if (Object.keys(characteristics.executive.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.executive,
        );

      if (Object.keys(characteristics.manager.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.manager,
        );

      if (Object.keys(characteristics.technical.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.technical,
        );

      if (
        Object.keys(characteristics.administrative.characteristics).length > 0
      )
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.administrative,
        );
    }

    yield put(actions.createProjectSuccess());
    yield put(success('Project was created succesfully'));
    yield put(push(`/projects/${response.id}/rating`));
  } catch (error) {
    yield put(actions.createProjectFailure(error));
  }
}

function* createSurveyProcess(action) {
  try {
    const { projectId, surveyName } = action.payload;

    const response = yield call(createSurvey, projectId, surveyName);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.createSurveyFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.createSurveyFailure(response.error.message));
      return;
    }

    yield put(actions.createSurveySuccess());
    yield put(success('Survey was created succesfully'));
    yield put(push(`/projects/${projectId}/surveys/${response.id}/created`));
  } catch (error) {
    yield put(actions.createSurveyFailure(error));
  }
}

function* answerSurveyProcess(action) {
  try {
    const { surveyId, projectId, capabilitiesValues } = action.payload;

    const response = yield call(answerSurvey, surveyId, capabilitiesValues);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.answerSurveyFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.answerSurveyFailure(response.error.message));
      return;
    }

    yield put(actions.answerSurveySuccess());
    yield put(push(`/projects/${projectId}/surveys/${surveyId}/completed`));
  } catch (error) {
    yield put(actions.answerSurveyFailure(error));
  }
}

function* updateProjectProcess(action) {
  try {
    const {
      name,
      squareFeet,
      cost,
      numberOfExecutives,
      numberOfManagers,
      numberOfProfesionalOrTechnical,
      numberOfAdministrative,
      projectId,
      roles,
    } = action.payload;

    const response = yield call(
      updateProject,
      {
        name,
        squareFeet,
        cost,
        numberOfExecutives,
        numberOfManagers,
        numberOfProfesionalOrTechnical,
        numberOfAdministrative,
      },
      projectId,
    );

    if (roles) {
      const characteristics = {
        executive: roles.filter(
          role => role.role === projectRole.ROLE_EXECUTIVE,
        )[0],
        manager: roles.filter(
          role => role.role === projectRole.ROLE_MANAGER,
        )[0],
        technical: roles.filter(
          role => role.role === projectRole.ROLE_TECHNICAL,
        )[0],
        administrative: roles.filter(
          role => role.role === projectRole.ROLE_ADMINISTRATIVE,
        )[0],
      };

      if (Object.keys(characteristics.executive.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.executive,
        );

      if (Object.keys(characteristics.manager.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.manager,
        );

      if (Object.keys(characteristics.technical.characteristics).length > 0)
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.technical,
        );

      if (
        Object.keys(characteristics.administrative.characteristics).length > 0
      )
        yield call(
          setProjectRoleCharacteristics,
          response.id,
          characteristics.administrative,
        );
    }

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.updateProjectFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.updateProjectFailure(response.error.message));
      return;
    }

    yield put(actions.updateProjectSuccess());
    yield put(success('Project was updated succesfully'));
    yield put(push(`/projects/${projectId}/rating`));
  } catch (error) {
    yield put(actions.updateProjectFailure(error));
  }
}

function* removeProjectProcess(action) {
  try {
    const projectId = action.payload;
    const response = yield call(removeProject, projectId);

    if (response === true) {
      yield put(actions.removeProjectSuccess());
      yield put(actions.getProjects());
      yield put(success('Project was removed succesfully'));
      return;
    }

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.removeProjectFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.removeProjectFailure(response.error.message));
      return;
    }
  } catch (error) {
    yield put(actions.removeProjectFailure(error));
  }
}

function* removeSurveyProcess(action) {
  try {
    const { projectId, surveyId } = action.payload;
    const response = yield call(removeSurvey, surveyId);

    if (response === true) {
      yield put(actions.removeSurveyFailure());
      yield put(actions.getSurveys(projectId));
      yield put(success('Survey was removed succesfully'));
      return;
    }

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.removeSurveyFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.removeSurveyFailure(response.error.message));
      return;
    }
  } catch (error) {
    yield put(actions.removeSurveyFailure(error));
  }
}

function* getProjectsProcess() {
  try {
    const userId = yield call([localStorage, 'getItem'], 'userId');
    const response = yield call(getProjects, userId);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getProjectsFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getProjectsFailure(response.error.message));
      return;
    }

    yield put(actions.getProjectsSuccess(response));
  } catch (error) {
    yield put(actions.getProjectsFailure(error));
  }
}

function* getSurveysProcess(action) {
  try {
    const projectId = action.payload;
    const response = yield call(getSurveys, projectId);

    let finalResponse = response;

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getSurveysFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getSurveysFailure(response.error.message));
      return;
    }

    const isResponseAnObject = typeof response === 'object';

    if (isResponseAnObject) {
      finalResponse = Object.keys(response).map(key => {
        return response[key];
      });
    }

    yield put(actions.getSurveysSuccess(finalResponse));
  } catch (error) {
    yield put(actions.getSurveysFailure(error));
  }
}

function* getSurveyDetailsProcess(action) {
  try {
    const { surveyId } = action.payload;
    const response = yield call(getSurveyDetails, surveyId);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getSurveyDetailsFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getSurveyDetailsFailure(response.error.message));
      return;
    }

    yield put(actions.getSurveyDetailsSuccess(response));
  } catch (error) {
    yield put(actions.getSurveyDetailsFailure(error));
  }
}

function* getCapabilitiesProcess(action) {
  try {
    const {
      workPlacePercentageChange,
      changeManagementPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      interiorDesignPercentageChange,
      projectId,
      surveyId,
      isComingFromSurvey,
    } = action.payload;

    const response = yield call(getCapabilities, projectId, {
      workPlacePercentageChange,
      changeManagementPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      interiorDesignPercentageChange,
    });

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getCapabilitiesFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getModelFailure(response.error.message));
      return;
    }

    const userId = yield call([localStorage, 'getItem'], 'userId');
    const project = yield call(getProjectById, projectId, userId);

    const projectModelConfig = {
      ...project,
      workPlacePercentageChange,
      changeManagementPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      interiorDesignPercentageChange,
    };

    const model = yield call(getModel, projectId, projectModelConfig, userId);

    yield put(actions.getModelSuccess(model));
    yield put(actions.getCapabilitiesSuccess(response));

    if (isComingFromSurvey && surveyId) {
      yield put(push(`/projects/${projectId}/surveys/${surveyId}/results`));
      return;
    }

    yield put(push(`/projects/${projectId}/results`));
  } catch (error) {
    yield put(actions.getCapabilitiesFailure(error));
  }
}

function* getProjectProcess(action) {
  try {
    const projectId = action.payload;
    const userId = yield call([localStorage, 'getItem'], 'userId');
    const project = yield call(getProjectById, projectId, userId);
    const projectRoleCharacteristics = yield call(
      getProjectRoleCharacteristics,
      projectId,
      userId,
    );

    if (project && project.code && project.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getProjectFailure(project.error.message));
      return;
    }

    if (
      projectRoleCharacteristics &&
      projectRoleCharacteristics.code &&
      projectRoleCharacteristics.code === 401
    ) {
      yield put(push(`/logout`));
      yield put(
        actions.getProjectRoleCharacteristicsFailure(project.error.message),
      );
      return;
    }

    if (project && project.error) {
      yield put(error(project.error.message));
      yield put(actions.getProjectFailure(project.error.message));
      return;
    }

    if (projectRoleCharacteristics && projectRoleCharacteristics.error) {
      yield put(error(projectRoleCharacteristics.error.message));
      yield put(
        actions.getProjectRoleCharacteristicsFailure(
          projectRoleCharacteristics.error.message,
        ),
      );
      return;
    }

    const projectRoles = [
      'executive',
      'manager',
      'technical',
      'administrative',
    ];

    const config = yield call(getConfig);

    const rolesInsideProjectRoleCharacteristicsArray = getArrayOfRolesThatAreNotIntoProjectRoleCharacteristics(
      projectRoles,
      projectRoleCharacteristics,
    );

    const defaultRolesMissingInProjectCharacteristics = generateObjectWithDefaultValuesMissingInProjectRoleCharacteristics(
      {
        defaultRolesCharacteristics: config.rolesCharacteristics,
        rolesInsideProjectRoleCharacteristicsArray,
        project,
      },
    );

    yield put(actions.getProjectSuccess(project));
    yield put(
      actions.getProjectRoleCharacteristicsSuccess([
        ...defaultRolesMissingInProjectCharacteristics,
        ...projectRoleCharacteristics,
      ]),
    );
  } catch (error) {
    yield put(actions.getProjectFailure(error));
    yield put(actions.getProjectRoleCharacteristicsFailure(error));
  }
}

function* getBenchmarkModelProcess(action) {
  try {
    const {
      workPlacePercentageChange,
      changeManagementPercentageChange,
      activityPercentageChange,
      interiorDesignPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      projectId,
    } = action.payload;

    const userId = yield call([localStorage, 'getItem'], 'userId');

    const model = yield call(
      getModel,
      projectId,
      {
        workPlacePercentageChange,
        changeManagementPercentageChange,
        activityPercentageChange,
        interiorDesignPercentageChange,
        groupSpacePercentageChange,
        brandPercentageChange,
      },
      userId,
    );

    if (model && model.code && model.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getBenchmarkModelFailure(model.error.message));
      return;
    }

    if (model.error) {
      yield put(error(model.error.message));
      yield put(actions.getBenchmarkModelFailure(model.error.message));
      return;
    }

    yield put(actions.getBenchmarkModelSuccess(model));
  } catch (error) {
    yield put(actions.getBenchmarkModelFailure(error));
  }
}

function* createModelProcess(action) {
  try {
    const {
      workPlacePercentageChange,
      changeManagementPercentageChange,
      activityPercentageChange,
      interiorDesignPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      projectId,
    } = action.payload;

    const userId = yield call([localStorage, 'getItem'], 'userId');

    const response = yield call(
      generateModel,
      projectId,
      {
        workPlacePercentageChange,
        changeManagementPercentageChange,
        activityPercentageChange,
        interiorDesignPercentageChange,
        groupSpacePercentageChange,
        brandPercentageChange,
      },
      userId,
    );

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.getModelFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.getModelFailure(response.error.message));
      return;
    }

    yield put(actions.getModelSuccess(response));
    yield put(push(`/projects/${projectId}/results`));
  } catch (error) {
    yield put(actions.getModelFailure(error));
  }
}

function* contactProcess(action) {
  try {
    const { projectId, contactInfo } = action.payload;

    const response = yield call(contact, projectId, contactInfo);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    yield put(actions.contactSuccess(response));
    yield put(success('Email has been sent successfully'));
  } catch (error) {
    yield put(actions.contactFailure(error));
  }
}

function* getBenchmarkDataProcess() {
  try {
    const response = yield call(getConfig);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    yield put(actions.getBenchmarkDataSuccess(response));
  } catch (error) {
    yield put(actions.getBenchmarkDataFailure(error));
  }
}

function* getModelDetailsDataProcess(action) {
  try {
    const {
      workPlacePercentageChange,
      changeManagementPercentageChange,
      groupSpacePercentageChange,
      brandPercentageChange,
      interiorDesignPercentageChange,
      projectId,
    } = action.payload;

    const userId = yield call([localStorage, 'getItem'], 'userId');

    const response = yield call(
      getModel,
      projectId,
      {
        workPlacePercentageChange,
        changeManagementPercentageChange,
        groupSpacePercentageChange,
        brandPercentageChange,
        interiorDesignPercentageChange,
      },
      userId,
    );

    const project = yield call(getProjectById, projectId, userId);

    if (response && response.code && response.code === 401) {
      yield put(push(`/logout`));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    if (response && response.error) {
      yield put(error(response.error.message));
      yield put(actions.contactFailure(response.error.message));
      return;
    }

    const employees =
      project.numberOfExecutives +
      project.numberOfManagers +
      project.numberOfAdministrative +
      project.numberOfProfesionalOrTechnical;

    const modelDetailsConfig = {
      general: [
        {
          name: 'Employees',
          value: employees,
        },
        {
          name: 'Compensation',
          value: priceFormat(response.totalAnnualCompensation),
        },
        {
          name: 'Cost',
          value: priceFormat(project.cost),
        },
        {
          name: 'Project square foot',
          value: numberFormat(project.squareFeet),
        },
        {
          name: 'HC value per square foot',
          value: priceFormat(response.humanCapitalImpactPerSquareFoot),
        },
      ],
      accuracy: [
        {
          name: 'Current percentage rework',
          value: percentageFormat(
            response.accuracyDetails.currentPercentageOfRework,
          ),
        },
        {
          name: 'Potential percentage rework',
          value: percentageFormat(
            response.accuracyDetails.potentialPercentageOfRework,
          ),
        },
        {
          name: 'Annual "process work" hours per employee',
          value: response.accuracyDetails.annualProcessWorkHoursPerEmployee,
        },
        {
          name: 'Current annual hours of rework per employee',
          value: response.accuracyDetails.currentAnnualHoursOfReworkPerEmployee,
        },
        {
          name: 'Potential annual hours of rework per employee',
          value:
            response.accuracyDetails.potentialAnnualHoursOfReworkPerEmployee,
        },
        {
          name: 'Average cost per hour of rework',
          value: response.accuracyDetails.averageCostPerHourOfRework,
        },
        {
          name: 'Annual rework hours saved per employee',
          value: response.accuracyDetails.annualReworkHoursSavedPerEmployee,
        },
        {
          name: 'Rework annual savings',
          value: priceFormat(response.percentageOfReworkAnnualSavings),
        },
      ],
      time: [
        {
          name: 'Current process time per employee',
          value: response.timeDetails.currentProcessTimePerEmployee,
        },
        {
          name: 'Potential proccess time per employee',
          value: response.timeDetails.potentialProccessTimePerEmployee,
        },
        {
          name: 'Process time change (hours per employee)',
          value: response.timeDetails.processTimeChangeInHoursPerEmployee,
        },
        {
          name:
            'Current annual number of completed work processes per employee',
          value:
            response.timeDetails
              .currentAnnualNumberOfCompletedWorkProccessesPerEmployee,
        },
        {
          name:
            'Potential annual number of completed work processes per employee',
          value:
            response.timeDetails
              .potentialAnnualNumberOfCompletedWorkProcessesPerEmployee,
        },
        {
          name: 'Potential change in annual number of completed processes',
          value:
            response.timeDetails
              .potentialChangeInAnnualNumberOfCompletedProccesses,
        },
        {
          name: 'Potential total annual change in hours',
          value: response.timeDetails.potentialTotalAnnualChangeInHours,
        },
        {
          name: 'Process annual savings',
          value: priceFormat(response.processTimeAnnualSavings),
        },
      ],
      retention: [
        {
          name: 'Current retention rate',
          value: percentageFormat(
            response.retentionDetails.currentRetentionRate,
          ),
        },
        {
          name: 'Potential retention rate',
          value: percentageFormat(
            response.retentionDetails.potentialRetentionRate,
          ),
        },
        {
          name: 'Current annual replacement FTEs required',
          value: response.retentionDetails.currentAnnualReplacementFTEsRequired,
        },
        {
          name: 'Potential annual replacement FTEs required',
          value:
            response.retentionDetails.potentialAnnualReplacmentFTEsRequired,
        },
        {
          name: 'Potential annual change in replacement FTEs',
          value:
            response.retentionDetails.potentialAnnualChangeInReplacementFTEs,
        },
        {
          name: 'Cost to replace employee (as a % of compensation)',
          value:
            response.retentionDetails
              .costToReplaceEmployeeAsPercentageOfCompensation,
        },
        {
          name: 'Current annual employee replacement cost',
          value: response.retentionDetails.currentAnnualEmployeeReplacementCost,
        },
        {
          name: 'Retention annual savings',
          value: priceFormat(response.retentionRateAnnualSavings),
        },
      ],
    };

    yield put(actions.getModelDetailsDataSuccess(modelDetailsConfig));
  } catch (error) {
    yield put(actions.getModelDetailsDataFailure(error));
  }
}

function* watchCreateProject() {
  yield takeLatest(types.CREATE_PROJECT, createProjectProcess);
}

function* watchGetProjects() {
  yield takeLatest(types.GET_PROJECTS, getProjectsProcess);
}

function* watchGetProject() {
  yield takeLatest(types.GET_PROJECT, getProjectProcess);
}

function* watchGetModel() {
  yield takeLatest(types.CREATE_MODEL, createModelProcess);
}

function* watchGetCapabilities() {
  yield takeLatest(types.GET_CAPABILITIES, getCapabilitiesProcess);
}

function* watchUpdateProject() {
  yield takeLatest(types.UPDATE_PROJECT, updateProjectProcess);
}

function* watchRemoveProject() {
  yield takeLatest(types.REMOVE_PROJECT, removeProjectProcess);
}

function* watchContact() {
  yield takeLatest(types.CONTACT, contactProcess);
}

function* watchGetBenchmarkData() {
  yield takeLatest(types.GET_BENCHMARK_DATA, getBenchmarkDataProcess);
}

function* watchGetBenchmarkModel() {
  yield takeLatest(types.GET_BENCHMARK_MODEL, getBenchmarkModelProcess);
}

function* watchGetModelDetailsData() {
  yield takeLatest(types.GET_MODEL_DETAILS_DATA, getModelDetailsDataProcess);
}

function* watchGetSurveys() {
  yield takeLatest(types.GET_SURVEYS, getSurveysProcess);
}

function* watchRemoveSurvey() {
  yield takeLatest(types.REMOVE_SURVEY, removeSurveyProcess);
}

function* watchCreateSurvey() {
  yield takeLatest(types.CREATE_SURVEY, createSurveyProcess);
}

function* watchAnswerSurvey() {
  yield takeLatest(types.ANSWER_SURVEY, answerSurveyProcess);
}

function* watchGetSurveyDetails() {
  yield takeLatest(types.GET_SURVEY_DETAILS, getSurveyDetailsProcess);
}

export default function* watchProjects() {
  yield all([
    fork(watchCreateProject),
    fork(watchGetProjects),
    fork(watchGetProject),
    fork(watchGetModel),
    fork(watchGetCapabilities),
    fork(watchUpdateProject),
    fork(watchRemoveProject),
    fork(watchContact),
    fork(watchGetBenchmarkData),
    fork(watchGetBenchmarkModel),
    fork(watchGetModelDetailsData),
    fork(watchGetSurveys),
    fork(watchRemoveSurvey),
    fork(watchCreateSurvey),
    fork(watchAnswerSurvey),
    fork(watchGetSurveyDetails),
  ]);
}
