import { makeRequest } from '@humanspace/infrastructure';

export const login = async (username, password) =>
  makeRequest({
    path: '/login',
    method: 'POST',
    body: { username, password },
  });

export const register = async ({ name, email, company, plainPassword }) =>
  makeRequest({
    path: '/users',
    method: 'POST',
    body: { name, email, company, plainPassword },
  });

export const requestPassword = async ({ email }) =>
  makeRequest({
    path: '/users/password/request',
    method: 'POST',
    body: { email },
  });

export const resetPassword = async ({ newPassword, token }) =>
  makeRequest({
    path: '/users/password/reset',
    method: 'POST',
    body: { newPassword, token },
  });

export const getUserId = () => window.localStorage.getItem('userId');
export const setUserId = id => window.localStorage.setItem('userId', id);
export const removeUserId = () => window.localStorage.removeItem('userId');

export const getUserToken = () => window.localStorage.getItem('userToken');
export const setUserToken = token =>
  window.localStorage.setItem('userToken', token);
export const removeUserToken = () =>
  window.localStorage.removeItem('userToken');
