import { connect } from 'react-redux';

import ModelDetails from './ModelDetails';

import actions from '../../duck/actions';

const mapStateToProps = state => ({
  project: state.projects.project,
  modelDetails: state.projects.modelDetails,
  isLoading: state.shared.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getProject: projectId => dispatch(actions.getProject(projectId)),
  getModelDetailsData: (projectId, modelDetailsConfig) =>
    dispatch(actions.getModelDetailsData(projectId, modelDetailsConfig)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelDetails);
