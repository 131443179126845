import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';

import {
  Text,
  Breadcrumb,
  Loader,
  Modal,
  Card,
  Carousel,
  useMediaQueryContext,
  Layout,
  ReadMore,
  Icon,
} from '@humanspace/components';
import { Colors, Spacing } from '@humanspace/foundations';

import letsStart from '@humanspace/assets/images/lets_start.svg';
import anythingElse from '@humanspace/assets/images/anything_else.svg';

import { DetailsForm } from '../../components';

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const StyledIcon = styled(Icon)`
  margin-left: ${Spacing.xs};
  margin-right: ${Spacing.s};
`;

const StyledCarousel = styled(Carousel)`
  ${props =>
    !props.isMobile &&
    css`
      margin-top: 60px;
    `}
`;

const ProjectDetails = ({
  createProject,
  updateProject,
  getProject,
  resetForms,
  isLoading,
  project,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentStepFromParams = searchParams && searchParams.get('step');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [detailsFormStep, setDetailsFormStep] = useState(
    Number(currentStepFromParams) || 1,
  );
  const { id } = useParams();
  const history = useHistory();
  const { isMobile, isTablet } = useMediaQueryContext();

  const isResponsive = isMobile || isTablet;

  const isNewProject = localStorage.getItem('newProject');
  const hasRatingZeroValues =
    project.workPlacePercentageChange === 0 ||
    project.changeManagementPercentageChange === 0 ||
    project.activityPercentageChange === 0 ||
    project.interiorDesignPercentageChange === 0 ||
    project.groupSpacePercentageChange === 0 ||
    project.brandPercentageChange === 0;

  useEffect(() => {
    if (!id) {
      resetForms();
      localStorage.setItem('newProject', true);
      // setModalIsVisible(true);
    } else {
      getProject(id);
    }
  }, [getProject, resetForms, id]);

  return (
    <React.Fragment>
      {id && isLoading ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          breadcrumb={[
            {
              text: 'Edit project information',
              onClick: () => setDetailsFormStep(1),
              active: detailsFormStep === 1,
            },
            ...(detailsFormStep === 2
              ? [
                  {
                    text: 'People details',
                    onClick: () => setDetailsFormStep(2),
                    active: detailsFormStep === 2,
                  },
                ]
              : []),
          ]}
          multiple
          hasNavigation
          linkElement={Link}
          leftColumn={
            detailsFormStep === 1 ? (
              <React.Fragment>
                <Text size="xl" bold lineHeight={1.1}>
                  Add or edit project information
                </Text>
                <StyledText size="m" lineHeight={1.7}>
                  <ReadMore>
                    Start by naming your project. You can add information about
                    square footage or project cost now or anytime later.
                  </ReadMore>
                </StyledText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Text size="xl" bold lineHeight={1.1}>
                  People details
                </Text>
                <StyledText size="m" lineHeight={1.7}>
                  <ReadMore>
                    To start, we need information about the people using the space you are evaluating. Enter information for each of the four broad job levels to the right. Start by entering the number of people in each role category. You must enter a number in each category, or you cannot go on to the next step. Enter a zero “0” if there are no people in a role.
                    <br />
                    <br />
                    Click the <StyledIcon icon="arrow-down" />
                    to enter the detailed information for each role.
                  </ReadMore>
                </StyledText>
              </React.Fragment>
            )
          }
          rightColumn={
            <React.Fragment>
              <DetailsForm
                isResponsive={isResponsive}
                isEdit={id !== undefined}
                currentStep={detailsFormStep}
                onNextStep={currentStep => setDetailsFormStep(currentStep)}
                onSubmit={values =>
                  !id ? createProject(values) : updateProject(values, id)
                }
              />
              {id && (
                <StyledBreadcrumb
                  elements={[
                    {
                      text: 'Project',
                      onClick: () => setDetailsFormStep(1),
                      active: detailsFormStep === 1,
                    },
                    {
                      text: 'People',
                      onClick: () => setDetailsFormStep(2),
                      active: detailsFormStep === 2,
                    },
                    {
                      text: 'Ratings',
                      onClick: () => history.push(`/projects/${id}/rating`),
                    },
                    ...(!isNewProject && !hasRatingZeroValues
                      ? [
                          {
                            text: 'Results',
                            onClick: () =>
                              history.push(`/projects/${id}/results`),
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </React.Fragment>
          }
        />
      )}

      <Modal
        withoutPadding
        backgroundColor={Colors.secondary}
        withoutHeader
        isVisible={modalIsVisible}
        render={
          <StyledCarousel
            isMobile={isMobile}
            onSkip={() => setModalIsVisible(false)}>
            <Card
              background={letsStart}
              title="Let's start"
              description="To learn about HumanSpace create a project. All you need is a project name, the number of people using the space, and the square footage."
            />
            <Card
              background={anythingElse}
              title="Anything else?"
              description="You will need to know the number of employees in each of four job categories – executives, managers, professionals and administrative."
            />
          </StyledCarousel>
        }></Modal>
    </React.Fragment>
  );
};

ProjectDetails.propTypes = {
  createProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProjectDetails;
