import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';
import { useMediaQueryContext } from '../MediaQueryProvider';

const MIN_HEIGHT = 582;
const MAX_WIDTH = 320;

const Wrapper = styled.div`
  background-color: ${Colors.secondary};

  ${props =>
    !props.isResponsive
      ? `
  height: ${MIN_HEIGHT}px
  `
      : ` min-height: ${MIN_HEIGHT - 20}px`}

  width: ${MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  width: ${props => (props.withElement ? '100%' : '75%')};
  margin-top: ${Spacing.xs};

  ${props =>
    props.withoutDescription &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: ${MIN_HEIGHT - 64}px;
    `};
`;

const Description = styled.div`
  flex: 1 0 0;
  margin-bottom: ${Spacing.m};
`;

const Block = styled.div`
  background: ${props => props.background && `url(${props.background})`};

  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;

  margin-bottom: ${Spacing.m};
  flex: 2 0 0;
`;

const Card = ({ title, element, description, background, ...props }) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <Wrapper isResponsive={isResponsive} {...props}>
      <Block background={background}>
        <Header
          withElement={element}
          withoutDescription={!description}
          linkElement={Link}>
          {element ? (
            element
          ) : (
            <Text bold size="xl">
              {title}
            </Text>
          )}
        </Header>
      </Block>

      {description && (
        <Description>
          <Text lineHeight={1.7}>{description}</Text>
        </Description>
      )}
    </Wrapper>
  );
};

Card.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  element: PropTypes.object,
};

Card.defaultProps = {
  description: undefined,
  image: undefined,
  element: undefined,
};

export default Card;
