import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../foundations';

const getGridLayout = ({ isResponsive, twoColumns }) => {
  if (isResponsive || (isResponsive && twoColumns))
    return `
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`;

  if (twoColumns)
    return `
grid-auto-flow: dense;
grid-template-columns: repeat(2, minmax(165px, 0fr));
justify-content: center;
`;

  return `
max-height: 400px;
overflow: auto;
-webkit-transform: translateZ(0);
-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
border-radius: 20px;
-webkit-clip-path: inset(0 0 0 0 round 20px);
clip-path: inset(0 0 0 0 round 20px);
padding: ${Spacing.m};

&::-webkit-scrollbar {
display: none;
}

`;
};

const Container = styled.section`
  display: grid;
  grid-column-gap: ${Spacing.s};
  width: 100%;
  ${props => getGridLayout(props)}
`;

const Element = styled.article`
  margin-bottom: ${Spacing.s};
  width: 100%;
`;

const Grid = ({ children, isResponsive, twoColumns, ...props }) => (
  <Container {...props} isResponsive={isResponsive} twoColumns={twoColumns}>
    {React.Children.map(children, (child, index) => (
      <Element>{child}</Element>
    ))}
  </Container>
);

export default Grid;
