import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';

import {
  Layout,
  DetailsList,
  useMediaQueryContext,
  Loader,
} from '@humanspace/components';

import { Spacing } from '@humanspace/foundations';

const StyledDetailsList = styled(DetailsList)`
  margin-bottom: ${Spacing.xxxl};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${Spacing.xxxl};
  grid-auto-rows: 1fr;

  ${props =>
    props.isResponsive &&
    `
  grid-template-columns: repeat(1, 1fr);
  `}
`;

const LoaderWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const ModelDetails = ({
  modelDetails,
  project,
  getProject,
  getModelDetailsData,
  isLoading,
}) => {
  const { id } = useParams();
  const { isMobile, isTablet } = useMediaQueryContext();

  const isResponsive = isMobile || isTablet;

  useEffect(() => {
    getProject(id);
  }, [id, getProject]);

  useEffect(() => {
    getModelDetailsData(id, project);
  }, [project, getModelDetailsData, id]);

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader inverse />
      </LoaderWrapper>
    );

  return (
    <Layout
      fullWidth
      hasNavigation
      inverse
      linkElement={Link}
      render={
        <React.Fragment>
          <StyledDetailsList
            details={modelDetails.general}
            title="Model details"
          />
          <Grid isResponsive={isResponsive}>
            <StyledDetailsList
              sameHeight
              details={modelDetails.accuracy}
              title="Accuracy"
            />
            <StyledDetailsList
              sameHeight
              details={modelDetails.time}
              title="Time"
            />
            <StyledDetailsList
              sameHeight
              details={modelDetails.retention}
              title="Retention"
            />
          </Grid>
        </React.Fragment>
      }
    />
  );
};

export default ModelDetails;
