import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '../Text';
import { useMediaQueryContext } from '../MediaQueryProvider';
import { Spacing, Colors, Sizes } from '../../foundations';

const getProperSize = (size, isResponsive) => {
  if (isResponsive && size === 'xxs') return Sizes[size];
  if (isResponsive) {
    const currentPosition = Object.keys(Sizes).indexOf(size);
    const previousSize = Object.keys(Sizes)[currentPosition - 1];
    return Sizes[previousSize];
  }
  return Sizes[size];
};

const StyledInput = styled.input`
  font-family: 'Montserrat', serif;
  color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
  font-size: ${({ isResponsive }) => getProperSize('s', isResponsive)};
  line-height: 1.4;
  font-weight: 400;
  box-shadow: none;
  border: 0;
  width: 100%;
  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  padding: ${Spacing.s} ${Spacing.m};
  border-bottom: 2px solid ${Colors.black200};
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  flex: 1;
  margin: 0;
  ${props =>
    props.secondary &&
    `text-align: right;
     align-self: stretch;
    `};

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0;
  `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
  `};

  ${({ secondary }) =>
    secondary &&
    `
    border-bottom-left-radius:0px;
    border-top-left-radius: 0px;
  `};

  &::placeholder {
    color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
    opacity: 1;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: none;
    border-bottom: 2px solid ${Colors.black200};
    -webkit-text-fill-color: ${props =>
      props.dark ? Colors.anakiwa : Colors.white};
    transition: background-color 5000s ease-in-out 0s;
    background-color: ${props =>
      props.dark ? Colors.secondary : Colors.white200};
  }
`;

const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const Placeholder = styled(Text)`
  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  padding: ${Spacing.s} ${Spacing.m};

  border-bottom: 2px solid ${Colors.black200};
  outline: 0;
  white-space: nowrap;
  align-self: stretch;
  box-sizing: border-box;
  height: 100%;

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom: 0;
  `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
  `};
`;

const Child = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-height: 56px;
`;

const Label = styled.label`
  height: 56px;
`;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Input = ({
  type,
  isLast,
  isFirst,
  disabled,
  placeholder,
  input,
  secondary,
  dark,
  meta: { touched, error },
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <ConditionalWrapper
      condition={secondary}
      wrapper={children => (
        <SecondaryWrapper>
          <Child>
            <Label htmlFor={input.name}>
              <Placeholder
                color={dark ? Colors.anakiwa : Colors.white}
                dark={dark}
                isFirst={isFirst}
                isLast={isLast}>
                {placeholder}
              </Placeholder>
            </Label>
          </Child>

          <Child>{children}</Child>
        </SecondaryWrapper>
      )}>
      <StyledInput
        isResponsive={isResponsive}
        id={input.name}
        dark={dark}
        secondary={secondary}
        {...input}
        disabled={disabled}
        type={type}
        isFirst={isFirst}
        isLast={isLast}
        placeholder={!secondary ? placeholder : ''}
        error={error && touched}
      />
    </ConditionalWrapper>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  secondary: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  isLast: false,
  isFirst: false,
  meta: {
    error: undefined,
    touched: false,
  },
  secondary: false,
};

export default Input;
