const projectAction = action => `PROJECT/${action}`;

const CREATE_PROJECT = projectAction('CREATE_PROJECT');
const CREATE_PROJECT_SUCCESS = projectAction('CREATE_PROJECT_SUCCESS');
const CREATE_PROJECT_FAILURE = projectAction('CREATE_PROJECT_FAILURE');

const UPDATE_PROJECT = projectAction('UPDATE_PROJECT');
const UPDATE_PROJECT_SUCCESS = projectAction('UPDATE_PROJECT_SUCCESS');
const UPDATE_PROJECT_FAILURE = projectAction('UPDATE_PROJECT_FAILURE');

const REMOVE_PROJECT = projectAction('REMOVE_PROJECT');
const REMOVE_PROJECT_SUCCESS = projectAction('REMOVE_PROJECT_SUCCESS');
const REMOVE_PROJECT_FAILURE = projectAction('REMOVE_PROJECT_FAILURE');

const GET_PROJECTS = projectAction('GET_PROJECTS');
const GET_PROJECTS_SUCCESS = projectAction('GET_PROJECTS_SUCCESS');
const GET_PROJECTS_FAILURE = projectAction('GET_PROJECTS_FAILURE');

const GET_PROJECT = projectAction('GET_PROJECT');
const GET_PROJECT_SUCCESS = projectAction('GET_PROJECT_SUCCESS');
const GET_PROJECT_FAILURE = projectAction('GET_PROJECT_FAILURE');

const GET_PROJECT_ROLE_CHARACTERISTICS = projectAction(
  'GET_PROJECT_ROLE_CHARACTERISTICS',
);
const GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS = projectAction(
  'GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS',
);
const GET_PROJECT_ROLE_CHARACTERISTICS_FAILURE = projectAction(
  'GET_PROJECT_ROLE_CHARACTERISTICS_FAILURE',
);

const UPDATE_PROJECT_ROLE_CHARACTERISTICS = projectAction(
  'UPDATE_PROJECT_ROLE_CHARACTERISTICS',
);
const UPDATE_PROJECT_ROLE_CHARACTERISTICS_SUCCESS = projectAction(
  'UPDATE_PROJECT_ROLE_CHARACTERISTICS_SUCCESS',
);
const UPDATE_PROJECT_ROLE_CHARACTERISTICS_FAILURE = projectAction(
  'UPDATE_PROJECT_ROLE_CHARACTERISTICS_FAILURE',
);

const GET_BENCHMARK_DATA = projectAction('GET_BENCHMARK_DATA');
const GET_BENCHMARK_DATA_SUCCESS = projectAction('GET_BENCHMARK_DATA_SUCCESS');
const GET_BENCHMARK_DATA_FAILURE = projectAction('GET_BENCHMARK_DATA_FAILURE');

const GET_CAPABILITIES = projectAction('GET_CAPABILITIES');
const GET_CAPABILITIES_SUCCESS = projectAction('GET_CAPABILITIES_SUCCESS');
const GET_CAPABILITIES_FAILURE = projectAction('GET_CAPABILITIES_FAILURE');

const CREATE_MODEL = projectAction('CREATE_MODEL');
const CREATE_MODEL_SUCCESS = projectAction('CREATE_MODEL_SUCCESS');
const CREATE_MODEL_FAILURE = projectAction('CREATE_MODEL_FAILURE');

const CONTACT = projectAction('CONTACT');
const CONTACT_SUCCESS = projectAction('CONTACT_SUCCESS');
const CONTACT_FAILURE = projectAction('CONTACT_FAILURE');

const GET_BENCHMARK_MODEL = projectAction('GET_BENCHMARK_MODEL');
const GET_BENCHMARK_MODEL_SUCCESS = projectAction(
  'GET_BENCHMARK_MODEL_SUCCESS',
);
const GET_BENCHMARK_MODEL_FAILURE = projectAction(
  'GET_BENCHMARK_MODEL_FAILURE',
);

const GET_MODEL_DETAILS_DATA = projectAction('GET_MODEL_DETAILS_DATA');
const GET_MODEL_DETAILS_DATA_SUCCESS = projectAction(
  'GET_MODEL_DETAILS_DATA_SUCCESS',
);
const GET_MODEL_DETAILS_DATA_FAILURE = projectAction(
  'GET_MODEL_DETAILS_DATA_FAILURE',
);

const GET_SURVEYS = projectAction('GET_SURVEYS');
const GET_SURVEYS_SUCCESS = projectAction('GET_SURVEYS_SUCCESS');
const GET_SURVEYS_FAILURE = projectAction('GET_SURVEYS_FAILURE');

const CREATE_SURVEY = projectAction('CREATE_SURVEY');
const CREATE_SURVEY_SUCCESS = projectAction('CREATE_SURVEY_SUCCESS');
const CREATE_SURVEY_FAILURE = projectAction('CREATE_SURVEY_FAILURE');

const REMOVE_SURVEY = projectAction('REMOVE_SURVEY');
const REMOVE_SURVEY_SUCCESS = projectAction('REMOVE_SURVEY_SUCCESS');
const REMOVE_SURVEY_FAILURE = projectAction('REMOVE_SURVEY_FAILURE');

const ANSWER_SURVEY = projectAction('ANSWER_SURVEY');
const ANSWER_SURVEY_SUCCESS = projectAction('ANSWER_SURVEY_SUCCESS');
const ANSWER_SURVEY_FAILURE = projectAction('ANSWER_SURVEY_FAILURE');

const GET_SURVEY_DETAILS = projectAction('GET_SURVEY_DETAILS');
const GET_SURVEY_DETAILS_SUCCESS = projectAction('GET_SURVEY_DETAILS_SUCCESS');
const GET_SURVEY_DETAILS_FAILURE = projectAction('GET_SURVEY_DETAILS_FAILURE');

const RESET_SURVEY_DETAILS = projectAction('RESET_SURVEY_DETAILS');

export default {
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  REMOVE_PROJECT,
  REMOVE_PROJECT_SUCCESS,
  REMOVE_PROJECT_FAILURE,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  GET_PROJECT_ROLE_CHARACTERISTICS,
  GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS,
  GET_PROJECT_ROLE_CHARACTERISTICS_FAILURE,
  UPDATE_PROJECT_ROLE_CHARACTERISTICS,
  UPDATE_PROJECT_ROLE_CHARACTERISTICS_SUCCESS,
  UPDATE_PROJECT_ROLE_CHARACTERISTICS_FAILURE,
  GET_CAPABILITIES,
  GET_CAPABILITIES_SUCCESS,
  GET_CAPABILITIES_FAILURE,
  CREATE_MODEL,
  CREATE_MODEL_SUCCESS,
  CREATE_MODEL_FAILURE,
  CONTACT,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
  GET_BENCHMARK_DATA,
  GET_BENCHMARK_DATA_SUCCESS,
  GET_BENCHMARK_DATA_FAILURE,
  GET_BENCHMARK_MODEL,
  GET_BENCHMARK_MODEL_SUCCESS,
  GET_BENCHMARK_MODEL_FAILURE,
  GET_MODEL_DETAILS_DATA,
  GET_MODEL_DETAILS_DATA_SUCCESS,
  GET_MODEL_DETAILS_DATA_FAILURE,
  GET_SURVEYS,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_FAILURE,
  CREATE_SURVEY,
  CREATE_SURVEY_SUCCESS,
  CREATE_SURVEY_FAILURE,
  REMOVE_SURVEY,
  REMOVE_SURVEY_SUCCESS,
  REMOVE_SURVEY_FAILURE,
  ANSWER_SURVEY,
  ANSWER_SURVEY_SUCCESS,
  ANSWER_SURVEY_FAILURE,
  GET_SURVEY_DETAILS,
  GET_SURVEY_DETAILS_SUCCESS,
  GET_SURVEY_DETAILS_FAILURE,
  RESET_SURVEY_DETAILS,
};
