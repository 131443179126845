import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, Link as RRLink } from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';

import {
  Text,
  Dashboard,
  Breadcrumb,
  Button,
  Module,
  Info,
  HorizontalLayout,
  Link,
  CapabilityGroup,
  Feature,
  Layout,
  useMediaQueryContext,
  Loader,
} from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

import {
  ContactForm,
  ModelDetailsSection,
  ResultsPDF,
  BenchmarkSection,
} from '../../components';

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.div`
  margin-top: ${Spacing.xl};
`;

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.xl};
`;

const ButtonSeparator = styled.div`
  margin-top: ${Spacing.xxxxl};
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledLink = styled(Link)`
  display: block;
`;

const StyledCapabilityGroup = styled(CapabilityGroup)`
  margin-bottom: ${Spacing.m};
`;

const StyledModelDetailsSection = styled(ModelDetailsSection)`
  margin-top: ${Spacing.xxxl};
`;

const DownloadButton = styled(Button)`
  margin-top: ${Spacing.xxxl};
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
`;

const DownloadLink = styled(Link)`
  margin-top: ${Spacing.xxxl};
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
`;

const getInfoContent = (
  type,
  openContactModal,
  contact,
  projectId,
  setIsInfoOpen,
) => {
  switch (type) {
    case 'premiumUpgrade':
      return (
        <React.Fragment>
          <Separator>
            <Text size="xl" bold color={Colors.black}>
              Upgrade to Premium
            </Text>
          </Separator>

          <Separator>
            <Text color={Colors.black}>
              To experience the full power and accuracy of HumanSpace, upgrade
              to the premium version. This version allows you to enter workforce
              information specific to your project, access industry benchmark
              data, and use a survey to gather better quality inputs to the
              Model you create. The models and estimates you create will be more
              accurate and reliable. The premium package also offers an analysis
              that identifies the most important workplace features that impact
              workforce performance.
            </Text>
          </Separator>

          <ButtonSeparator>
            <Button onClick={() => openContactModal('premiumUpgrade')}>
              Tell me more!
            </Button>
          </ButtonSeparator>
        </React.Fragment>
      );
    case 'videoCheckup':
      return (
        <React.Fragment>
          <Separator>
            <Text size="xl" bold color={Colors.black}>
              Video Checkup
            </Text>
          </Separator>

          <Separator>
            <Text color={Colors.black}>
              Inspired by telemedicine, we can connect with you by video for a
              virtual walkthrough to understand your workforce performance and
              well-being issues.
            </Text>
          </Separator>
          <Separator>
            <Text color={Colors.black}>
              We can help you interpret the results from your HumanSpace model
              and give you quick tips on improvements to your workplace.
            </Text>
          </Separator>

          <ButtonSeparator>
            <Button onClick={() => openContactModal('videoCheckup')}>
              Tell me more!
            </Button>
          </ButtonSeparator>
        </React.Fragment>
      );
    case 'contact':
      return (
        <React.Fragment>
          <Separator>
            <Text size="xl" bold color={Colors.black}>
              Get in touch
            </Text>
          </Separator>
          <Separator>
            <ContactForm
              onSubmit={value => {
                contact(projectId, value);
                setIsInfoOpen(false);
              }}
            />
          </Separator>
        </React.Fragment>
      );
    case 'about-results':
      return (
        <React.Fragment>
          <Separator>
            <Text size="xl" bold color={Colors.black}>
              About your results
            </Text>
            <Text size="m" bold color={Colors.black}>
              These results are for demonstration purposes only.
            </Text>
            <StyledLink
              size="m"
              target="_blank"
              href="https://humanspacesolutions.com/privacy-policy/"
              align="left"
              bold
              color={Colors.primary}>
              Read More
            </StyledLink>
          </Separator>

          <Separator>
            <Text size="m" bold color={Colors.black}>
              Annual Benefit in Value of Human Capital
            </Text>
          </Separator>

          <Separator>
            <Text color={Colors.black}>
              The Annual Benefit in Value of Human Capital as a % of
              compensation is a summary of the impact of the workplace on three
              workforce performance metrics: Retention, Business Process Time,
              and Accuracy of Work. Both the Annual benefit, and these three
              metrics, are expressed as a percentage of the annual spend on
              compensation for the workforce being modeled. Annual compensation
              is itself an indicator of the financial value of the workforce
              human capital. Keep in mind that these percentages can be negative
              as well as positive. For instance, it is possible to have a
              workspace in which people work very fast, but that engagement is
              low and replacement costs are high.
            </Text>
          </Separator>

          <Separator>
            <Text size="m" bold color={Colors.black}>
              Impact on Stress
            </Text>
          </Separator>

          <Separator>
            <Text color={Colors.black}>
              The stress impact measure is based on an overall average frequency
              of physical stress symptoms experienced at work, taken from a
              global sample of office workers. These stress symptoms include
              hands shaking, trouble sleeping night before, stomach ache,
              problems focusing, and others. The HumanSpace stress indicator
              shows a prediction/estimate of the percentage higher or lower than
              that average that the modeled set of workplace capabilities could
              produce. A lower percentage than average is the most desirable
              outcome.
            </Text>
          </Separator>

          <Separator>
            <StyledLink
              size="m"
              onClick={() => openContactModal('premiumUpgrade')}
              align="left"
              bold
              color={Colors.primary}>
              Upgrade to Premium
            </StyledLink>
            <Text color={Colors.black}>
              To experience the full power and accuracy of HumanSpace, upgrade
              to the premium version.
            </Text>
          </Separator>
        </React.Fragment>
      );
    default:
      return null;
  }
};

const SurveyResults = ({
  results,
  capabilities,
  squareFeet,
  totalEmployees,
  setInterestedValue,
  contact,
  initialValues,
  isLoading,
}) => {
  const { id, surveyId } = useParams();
  const history = useHistory();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [infoType, setInfoType] = useState(null);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  useEffect(() => {
    if (Object.keys(results).length === 0 && results.constructor === Object) {
      history.push(`/projects/${id}/surveys/${surveyId}/details`);
      return;
    }
  }, [history, results, surveyId, id]);

  const openContactModal = (openFrom = undefined) => {
    setIsInfoOpen(false);
    setTimeout(() => {
      setInfoType('contact');
      setIsInfoOpen(true);
      if (openFrom) setInterestedValue(openFrom);
    }, 800);
  };

  const infoContent = getInfoContent(
    infoType,
    openContactModal,
    contact,
    id,
    setIsInfoOpen,
  );

  const isNegative =
    results && results.humanCapitalBenefitAsPercentOfCompensation < 0;
  const isStressNegative = results && results.stressPercentageChange < 0;

  const dashboardResults = {
    ...results,
    ...initialValues,
    ...{ squareFeet },
    ...{ totalEmployees },
  };

  const normalizedProjectName =
    dashboardResults.name &&
    dashboardResults.name
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .replace(/\s/g, '')
      .toLowerCase();

  const totalEmployeesIsValid = totalEmployees && !Number.isNaN(totalEmployees);

  const [instance] = usePDF({
    document: (
      <ResultsPDF
        features={capabilities}
        isStressNegative={isStressNegative}
        isNegative={isNegative}
        results={dashboardResults}
      />
    ),
  });

  return (
    <React.Fragment>
      {isLoading || !totalEmployeesIsValid ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          align="top"
          breadcrumb={[
            {
              text: 'Workspace ratings',
              onClick: () =>
                history.push(`/projects/${id}/surveys/${surveyId}/details`),
              active: false,
            },
            {
              text: 'Workspace evaluation',
              onClick: () =>
                history.push(`/projects/${id}/surveys/${surveyId}/results`),
              active: true,
            },
          ]}
          hasNavigation
          linkElement={RRLink}
          multiple
          leftColumn={
            <React.Fragment>
              <Text size={isResponsive ? 'xl' : 'xxl'} bold lineHeight={1.2}>
                Your results
              </Text>
              <Separator />
              <StyledText size="m">
                We use a statistical technique, predictive modeling, to reveal
                what key “Top 3” features of the design, furnishings,
                technology, and ambient environment, affect the value of human
                capital – based on the model you built.
              </StyledText>
              <StyledText size="m">
                To best leverage your investment in this workplace, we suggest
                optimizing the quality and performance of each of the following
                three workspace capabilities.
              </StyledText>
              {capabilities && capabilities.length > 0 && (
                <React.Fragment>
                  <StyledCapabilityGroup title="3 Key Features">
                    {capabilities.map(capability => (
                      <Feature
                        key={capability.feature.capability}
                        title={capability.feature.name}
                        description={capability.feature.text}
                        icon="calendar"
                        href={capability.feature.url}
                      />
                    ))}
                  </StyledCapabilityGroup>
                </React.Fragment>
              )}

              {isResponsive && (
                <React.Fragment>
                  <Dashboard
                    onInfoClick={() => {
                      setInfoType('about-results');
                      setIsInfoOpen(true);
                    }}
                    isStressNegative={isStressNegative}
                    isNegative={isNegative}
                    results={dashboardResults}
                  />
                  {instance.loading ? (
                    <DownloadButton>Loading document...</DownloadButton>
                  ) : (
                    <DownloadLink
                      type="button"
                      href={instance.url}
                      download={`${normalizedProjectName}_project.pdf`}>
                      Download report
                    </DownloadLink>
                  )}
                  <Separator />
                </React.Fragment>
              )}

              {id && surveyId && (
                <React.Fragment>
                  <StyledModelDetailsSection
                    isResponsive={isResponsive}
                    id={id}
                  />
                  <StyledBreadcrumb
                    elements={[
                      {
                        text: 'Workspace ratings',
                        onClick: () =>
                          history.push(
                            `/projects/${id}/surveys/${surveyId}/details`,
                          ),
                        active: false,
                      },
                      {
                        text: 'Workspace evaluation',
                        onClick: () =>
                          history.push(
                            `/projects/${id}/surveys/${surveyId}/results`,
                          ),
                        active: true,
                      },
                    ]}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          }
          rightColumn={
            <React.Fragment>
              {!isResponsive && (
                <React.Fragment>
                  <Dashboard
                    onInfoClick={() => {
                      setInfoType('about-results');
                      setIsInfoOpen(true);
                    }}
                    isStressNegative={isStressNegative}
                    isNegative={isNegative}
                    results={dashboardResults}
                  />{' '}
                  {instance.loading ? (
                    <DownloadButton>Loading document...</DownloadButton>
                  ) : (
                    <DownloadLink
                      type="button"
                      href={instance.url}
                      download={`${normalizedProjectName}_project.pdf`}>
                      Download report
                    </DownloadLink>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          }
          bottom={
            <React.Fragment>
              <BenchmarkSection id={id} isResponsive={isResponsive} />
              <Text
                align={isResponsive ? 'left' : 'center'}
                size={isResponsive ? 'xl' : 'xxl'}
                bold
                lineHeight={1.2}>
                Additional Resources
              </Text>
              <Separator />
              <HorizontalLayout>
                <Module
                  className="gtm-module-contact"
                  onClick={() => openContactModal('bookDemo')}
                  title="Book a demo"
                />
                <Module
                  className="gtm-module-contact"
                  secondary
                  onClick={() =>
                    window.open(
                      'https://www.humanspacesolutions.com/',
                      '_blank',
                    )
                  }
                  title="Learn more"
                />
              </HorizontalLayout>
              <Info visible={isInfoOpen} onClose={() => setIsInfoOpen(false)}>
                {infoContent}
              </Info>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

export default SurveyResults;
