import { connect } from 'react-redux';

import actions from '../../duck/actions';
import modelsActions from '../../../models/duck/actions';

import Projects from './Projects';

const mapStateToProps = state => ({
  projects: state.projects.projects,
  isLoading: state.shared.isLoading,
  stressModels: state.models.stressModels,
});

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(actions.getProjects()),
  removeProject: projectId => dispatch(actions.removeProject(projectId)),
  removeStressModel: stressModelId =>
    dispatch(modelsActions.removeStressModel(stressModelId)),
  getStressModels: () => dispatch(modelsActions.getStressModels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
