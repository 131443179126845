import React from 'react';
import styled from 'styled-components';

import { Spacing, Colors } from '../../foundations';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${props => (props.active ? Colors.anakiwa : Colors.white)};
  ${Colors.white};
  border-radius: 50%;
  margin-right: ${props => (props.isLast ? '0' : Spacing.xs)};
`;

const Dots = ({ total, current }) => {
  const items = [];

  for (var i = 0; i < total; i++) {
    items.push(<Dot key={i} isLast={i + 1 === total} active={i === current} />);
  }

  return <Wrapper>{items}</Wrapper>;
};

export default Dots;
