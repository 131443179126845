import { connect } from 'react-redux';

import actions from '../../duck/actions';

import Login from './Login';

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(actions.login(email, password)),
});

export default connect(null, mapDispatchToProps)(Login);
