import React from 'react';
import styled from 'styled-components';

import { Spacing, Colors } from '../../foundations';

const Element = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-top: 50%;

  &:after {
    content: ' ';
    display: block;
    width: 44px;
    height: 44px;
    margin: ${Spacing.xs};
    border-radius: 50%;
    border: 5px solid
      ${props => (props.inverse ? Colors.primary : Colors.white)};
    border-color: ${props => (props.inverse ? Colors.primary : Colors.white)}
      transparent ${props => (props.inverse ? Colors.primary : Colors.white)}
      transparent;
    animation: dualRing 1.2s linear infinite;
  }

  @keyframes dualRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ElipsisElement = styled.div`
  display: inline-block;
  position: relative;
  width: 120px;
  height: 60px;

  & div {
    position: absolute;
    top: 33px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${Colors.white};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: ${Spacing.m};
    animation: lds-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: ${Spacing.m};
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: ${Spacing.xxxl};
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: ${Spacing.xxxxl};
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(32px, 0);
    }
  }
`;

const Loader = ({ inverse, type }) => {
  if (!type) {
    return <Element inverse={inverse} />;
  }

  if (type === 'elipsis')
    return (
      <ElipsisElement>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </ElipsisElement>
    );
};

export default Loader;
