import React from 'react';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';

import styles from './styles';

import humanSpaceLogo from './images/humanspace_logo.png';
import humanSpaceLogoInverse from './images/humanspace_logo_inverse.png';
import background from './images/background.png';
import linkedin from './images/linkedin.png';

import taskChair from './images/task_chair.png';
import taskLighting from './images/task_lighting.png';
import workSurface from './images/work_surface.png';
import guestSeating from './images/guest_seating.png';
import heightAdjustableTable from './images/height_adjustable_table.png';
import locker from './images/locker.png';
import monitor from './images/monitor.png';
import collaborativeTechnology from './images/collaborative_technology.png';
import receptionServices from './images/reception_services.png';
import personalTechnology from './images/personal_technology.png';
import spaceLegibility from './images/space_legibility.png';
import socialSpaces from './images/social_spaces.png';
import wayfinding from './images/wayfinding.png';
import visualAccess from './images/visual_access.png';
import workstationErgonomics from './images/workstation_ergonomics.png';
import changeManagement from './images/change_management.png';
import acoustics from './images/acoustics.png';
import workspaceControls from './images/workspace_controls.png';
import artwork from './images/artwork.png';
import meetingRooms from './images/meeting_rooms.png';
import meetingRoomsTech from './images/meeting_room_tech.png';
import meetingRoomsFurnishings from './images/meeting_room_furnishings.png';
import aestheticAppeal from './images/aesthetic_appeal.png';
import indoorAirQuality from './images/indoor-air-quality.png';

import impact from './images/impact.png';
import impactDisabled from './images/impact_disabled.png';
import impactNegative from './images/impact_negative.png';
import union from './images/union.png';
import unionDisabled from './images/union_disabled.png';
import unionNegative from './images/union_negative.png';
import time from './images/time.png';
import timeDisabled from './images/time_disabled.png';
import timeNegative from './images/time_negative.png';
import retention from './images/retention.png';
import retentionDisabled from './images/retention_disabled.png';
import retentionNegative from './images/retention_negative.png';

import MontserratBold from './fonts/Montserrat-Bold.ttf';
import MontserratMedium from './fonts/Montserrat-Medium.ttf';
import MontserratRegular from './fonts/Montserrat-Regular.ttf';
import MontserratLight from './fonts/Montserrat-Light.ttf';

import {
  percentageFormat,
  priceFormat,
  numberFormat,
  moneyFormat,
} from '@humanspace/utils/formatters';

Font.register({
  family: 'Montserrat',
  format: 'truetype',
  fonts: [
    { src: MontserratBold, fontWeight: 700 },
    { src: MontserratMedium, fontWeight: 600 },
    { src: MontserratRegular, fontWeight: 400 },
    { src: MontserratLight, fontWeight: 300 },
  ],
});

const calculateAnnual = (cost = 0, capitalBenefit = 0) => {
  return {
    annualROI: (capitalBenefit * 100) / cost / 100,
  };
};

const currentYear = new Date().getFullYear();

const getFeatures = featureName => {
  switch (featureName) {
    case 'Task chair':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={taskChair} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Task chair: </Text> Ergonomic
            standards and guidelines recommend employees to use several working
            postures – sitting while reclining, upright, or in forward tilt;
            standing or alternating between sitting and standing – recognizing
            that not one posture should be used for an extended period. An
            adequate task chair will allow users to sit in different postures
            and provide adjustable features to enhance the comfort,
            productivity, and overall well-being of the employee.
          </Text>
        </View>
      );

    case 'Task lighting':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={taskLighting} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Task lighting: </Text> Provide
            adjustable task lighting for working area, with an illuminance of
            300 to 500 lux approximate, color temperature between 2,500K-
            4,000K. Adequate ambient light and glare-free, low-reflection
            workplace, can be supported by adding task lighting, allowing users
            to determine their own lighting needs, according to their
            activities.
          </Text>
        </View>
      );

    case 'Work surface/fixed table':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={workSurface} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Work surface/fixed table: </Text>{' '}
            Adequate work surface area according to users' needs. Recommended
            surface dimensions 18" -24" depth, 48"- 60" width. Allow users to
            work comfortably, sufficient space for more frequently used work
            tools and less commonly used. Provide clearance under the work
            surface giving room for legs without contacting the surface above.
            If storage is placed under the work surface, a 60" width surface is
            recommended.
          </Text>
        </View>
      );

    case 'Guest or side seating':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={guestSeating} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Guest or side seating: </Text>{' '}
            Provide guest seating for quick informal meetings. Using mobile
            storage with cushions, puffs and other side seating that are
            comfortable for a short period of time. Allowing employees to
            spontaneous interactions (without booking meeting spaces) fosters
            collaboration and innovation.
          </Text>
        </View>
      );

    case 'Height adjustable table':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={heightAdjustableTable} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Height adjustable table: </Text>{' '}
            A height-adjustable table allows everyone to customize the height to
            what is most ergonomically comfortable, regardless if seated,
            standing, or somewhere in-between. Coupled with technology
            (built-in, or a phone app), workers can vary between positions
            throughout the day, improving circulation and possibly reducing the
            risk of musculoskeletal injury.
          </Text>
        </View>
      );

    case 'Storage in workspace or locker':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={locker} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>
              Storage in workspace or locker:{' '}
            </Text>
            Provide employees with adequate storage in their workspaces or
            another specific area for these purposes (locker area). Storage
            should support different user needs, allowing easy access to work
            materials and personal items.
          </Text>
        </View>
      );

    case 'Adjustable monitor arm':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={monitor} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Adjustable monitor arm: </Text>{' '}
            Users should sit/stand centered at their task/computer monitor, the
            use of adjustable monitor arm facilitates users adjustability for
            ergonomic purposes. Adjust the computer monitor to be approximately
            an arm’s length away from the front of the body and at a height that
            keeps the head in an upright (neutral) posture.
          </Text>
        </View>
      );

    case 'Collaboration Technology':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={collaborativeTechnology} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Collaboration Technology: </Text>{' '}
            Collaborative work typically involves the casual exchange of ideas
            between a small number of individuals, in addition to larger, more
            formal meetings. Information is shared visually through screens of
            different sizes (smartphones, tablets, laptops, flat screens),
            whiteboards and flip charts, or other visual means.
          </Text>
        </View>
      );

    case 'Concierge & reception services':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={receptionServices} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>
              Concierge & reception services{' '}
            </Text>
            Provide concierge or reception services to facilitate and
            accommodate employees needs and their visitors. Having a face to
            face interaction when users have questions or requests regarding the
            space functionality, office navigation, tools needed, etc. can
            enhance the workplace experience for employees and visitors.
          </Text>
        </View>
      );

    case 'Personal technology':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={personalTechnology} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Personal technology: </Text>
            Ensure that employees have the right technology in their individual
            workspaces. Wireless access and use of mobile technology will
            support user control over work location. Promoting the use of
            personal devices and platforms might increase employee productivity
            and technology satisfaction.
          </Text>
        </View>
      );

    case 'Floor plan easy to understand':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={spaceLegibility} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>
              Floor plan easy to understand:{' '}
            </Text>
            The layout of legible office space is organized. Regarding design,
            the layout of the office, furnishings, and graphics should allow for
            people to learn, or guess, where the desired space type might be
            found and understand the primary intended use of the space.
          </Text>
        </View>
      );

    case 'Social casual spaces':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={socialSpaces} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Social casual spaces: </Text>
            Lounge furniture can support safe ergonomic postures for people
            using devices such as laptops and tablets. Select furniture with
            firm seat and back cushions, preferably with soft, wide armrests.
            Offer support pillows so users can adjust for seat depth and lumbar
            support, and for arm support when seated in the middle of a couch,
            and when holding a tablet or other device.
          </Text>
        </View>
      );

    case 'Design understandable floorplan':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={wayfinding} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>
              Design understandable floorplan:{' '}
            </Text>
            Legibility is related to how quickly people can understand, and
            navigate, the interior layout of a building, and effectively
            understand the intended use of all the workspaces within.
            Legibility, a people-centered approach to design, use an easily
            recognized pattern for the overall configuration of the workplace
            with visual access to landmarks, signage, and architectural
            elements.
          </Text>
        </View>
      );

    case 'Visual access':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={visualAccess} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Visual access: </Text>
            Visual access allows people to see ahead to the interior or exterior
            landmarks for navigation. Access to daylight is also part of the
            ambient environment, experienced through the large-scale space plan,
            since daylight can be experienced at any location within the
            building. Open views support exposure to natural light which
            improves users' alertness, it also allows to understand the layout
            better and helps people to see and find each other easily.
          </Text>
        </View>
      );

    case 'Ergonomics Assessment':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={workstationErgonomics} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Ergonomics Assessment: </Text>
            Provide ergonomic training that outlines basic concepts of
            ergonomics, and information on how to adjust "user adjustable"
            elements (task chair, task lighting, height-adjustable table,
            environment controls, etc.) within the workstation. Offer an
            ergonomic guide printed/digital with the main ergonomic tips to
            consider will reinforce training.
          </Text>
        </View>
      );

    case 'Workplace communication program':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={changeManagement} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>
              Workplace communication program:{' '}
            </Text>
            Successful workplace change involves a purposeful set of
            communication strategies, participatory methods, and tactics that
            help guide a team through a particular transition. Effective change
            management starts with engaged leaders who focus on communicating
            why the work environment is essential to the overall mission,
            vision, culture, and evolution of the business.
          </Text>
        </View>
      );

    case 'Acoustics, noise level':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={acoustics} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Acoustics, noise level: </Text>
            When construction elements work together to control levels of speech
            intelligibility and honor privacy accurately, people hear what they
            need to hear, when they need to hear it. Investing in the right
            acoustical workplace designs for each area, based on function and
            use, creates the necessary environment for focus, productivity, and
            innovation.
          </Text>
        </View>
      );

    case 'Workspace controls':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={workspaceControls} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Workspace controls: </Text>
            Choice and control are essential predictors of employee' well-being,
            happiness and focus work, enabling users to adapt their environments
            based on their actual needs will have higher benefits. Let people
            control how to organize and personalize their workspaces, when to
            interact socially with co-workers, and manage their own lighting,
            ambient temperature, and work processes.
          </Text>
        </View>
      );

    case 'Artwork in facility':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={artwork} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Artwork in facility: </Text>
            Meaningful integration of artwork to interior space in larger areas
            with soft seating or benches provides employees recharging areas for
            short breaks. The addition of design elements (visual communication
            and graphics) and artwork to the workplace can create a calming
            environment able to improve occupant mood. Artwork and graphics
            could also work as landmarks to facilitate space navigation.
          </Text>
        </View>
      );

    case 'Meeting rooms':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={meetingRooms} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Meeting rooms: </Text>
            Provide meeting room sizes that fit the needs, support social
            interactions, offer adjustable furnishings and flexible use.
            High-quality meeting spaces increase performance, engagement, and
            wellbeing. Ensure that the workplace provides informal spaces for
            casual work or social interaction, furnishings within meeting spaces
            that meet users’ work needs and meeting spaces sized to accommodate
            typical meeting requirements.
          </Text>
        </View>
      );

    case 'Meeting room technology':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={meetingRoomsTech} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Meeting room technology: </Text>
            Provide adequate WIFI access to employees and visitors, enable quick
            set up for meetings. Power modules should be located to accommodate
            the typically short cord lengths of devices, so people do not assume
            unsafe postures (e.g., bending, stooping) when plugging in. The use
            of projection and video conference technology should work adequately
            when needed.
          </Text>
        </View>
      );

    case 'Meeting room furnishings':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={meetingRoomsFurnishings} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Meeting room furnishings: </Text>
            Whiteboards and vertical surfaces are extremely useful for team
            interaction to facilitate visualization and sharing of ideas.
            Moveable walls and partitions support meeting room adjustability and
            user control. Provide furnishings that support meeting room
            flexibility and technology features.
          </Text>
        </View>
      );

    case 'Aesthetic appeal':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={aestheticAppeal} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Aesthetic appeal: </Text>A
            workplace design which conveys design principles aligned with the
            organization's values and narrative. Using the right materials,
            colors, visual communications, branding, etc., which highlights the
            corporate identity, empowering employees to experience the
            workplace, as an outcome of their organizational culture.
          </Text>
        </View>
      );

    case 'Indoor air quality':
      return (
        <View style={styles.feature}>
          <Image style={styles.featureImage} src={indoorAirQuality} />
          <Text
            style={[
              styles.paragraph,
              styles.colorInverse,
              styles.mb20,
              styles.featureDescription,
            ]}>
            <Text style={styles.strongAnakiwa}>Indoor air quality: </Text>We
            live in a time when there is a heightened awareness of the perils of
            poor air quality and the potential for airborne infection at the
            office. In addition, there is a large body of research showing that
            poor IAQ negatively affects work and school performance and health.
          </Text>
        </View>
      );

    default:
      return null;
  }
};

const DashboardPageOne = ({ results, isStressNegative, isNegative }) => {
  const { annualROI } = calculateAnnual(
    results.cost,
    results.humanCapitalBenefit,
  );

  return (
    <View style={styles.dashboard}>
      <View
        style={[
          styles.radiusTop,
          ...(isNegative
            ? [styles.dashboardSectionNegative]
            : [styles.dashboardSection]),
        ]}>
        <Text
          style={[
            styles.small,
            styles.center,
            styles.colorInverse,
            styles.mb5,
          ]}>
          Your project information
        </Text>
        <View style={styles.dashboardFlex}>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text
              style={[styles.paragraph, styles.center, styles.colorInverse]}>
              {numberFormat(results.totalEmployees)}
            </Text>
            <Text
              style={[
                styles.small,
                styles.center,
                ...(isNegative
                  ? [styles.colorLightPink]
                  : [styles.colorAnakiwa]),
              ]}>
              Total employees
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text
              style={[styles.paragraph, styles.center, styles.colorInverse]}>
              {priceFormat(results.totalAnnualCompensation)}
            </Text>
            <Text
              style={[
                styles.small,
                styles.center,
                ...(isNegative
                  ? [styles.colorLightPink]
                  : [styles.colorAnakiwa]),
              ]}>
              Annual compensation
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text
              style={[styles.paragraph, styles.center, styles.colorInverse]}>
              {numberFormat(results.squareFeet)}
            </Text>
            <Text
              style={[
                styles.small,
                styles.center,
                ...(isNegative
                  ? [styles.colorLightPink]
                  : [styles.colorAnakiwa]),
              ]}>
              Space sq. ft
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text
              style={[styles.paragraph, styles.center, styles.colorInverse]}>
              {numberFormat(results.cost)}
            </Text>
            <Text
              style={[
                styles.small,
                styles.center,
                ...(isNegative
                  ? [styles.colorLightPink]
                  : [styles.colorAnakiwa]),
              ]}>
              Cost
            </Text>
          </View>
        </View>

        <Text
          style={[
            styles.small,
            styles.center,
            styles.colorInverse,
            styles.mb5,
          ]}>
          Your model results
        </Text>
        <Text
          style={[styles.xxxl, styles.center, styles.colorInverse, styles.mb5]}>
          {percentageFormat(results.humanCapitalBenefitAsPercentOfCompensation)}
        </Text>
        <Text
          style={[styles.xxl, styles.center, styles.colorInverse, styles.mb5]}>
          {moneyFormat(results.humanCapitalBenefit)}
        </Text>
        <Text
          style={[
            styles.small,
            styles.w75,
            styles.center,
            ...(isNegative ? [styles.colorLightPink] : [styles.colorAnakiwa]),
            styles.mb5,
          ]}>
          Annual impact of space on value of human capital – as a percentage of
          annual compensation and dollar value
        </Text>
      </View>

      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Text
          style={[
            styles.main,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {!isStressNegative && '+'}
          {percentageFormat(results.stressPercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Difference in stress from average
        </Text>
      </View>
      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Text
          style={[
            styles.main,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {results.yearsToRecoup}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Annual payback on project / facility cost
        </Text>
      </View>
      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Text
          style={[
            styles.main,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(annualROI)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Annual ROI on project / facility cost
        </Text>
      </View>
      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Image src={impactDisabled} style={styles.dashboardImage} />
        <Text
          style={[
            styles.paragraph,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {priceFormat(results.humanCapitalImpactPerSquareFoot)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          HC impact per sq. ft.
        </Text>
      </View>
      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Image src={unionDisabled} style={styles.dashboardImage} />
        <Text
          style={[
            styles.paragraph,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.percentageOfReworkPercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Work accuracy
        </Text>
      </View>
      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Image src={timeDisabled} style={styles.dashboardImage} />
        <Text
          style={[
            styles.paragraph,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.processTimePercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Work process time
        </Text>
      </View>
      <View
        style={[
          styles.dashboardFeature,
          styles.backgroundGrey,
          styles.radiusBottom,
        ]}>
        <Image src={retentionDisabled} style={styles.dashboardImage} />
        <Text
          style={[
            styles.paragraph,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.retentionRatePercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Retention rate
        </Text>
      </View>
    </View>
  );
};

const DashboardPageTwo = ({ results, isNegative, isStressNegative }) => {
  const { annualROI } = calculateAnnual(
    results.cost,
    results.humanCapitalBenefit,
  );

  return (
    <View style={styles.dashboard}>
      <View
        style={[
          ...(isNegative
            ? [styles.dashboardSectionNegative]
            : [styles.dashboardSection]),
          styles.backgroundGrey,
          styles.radiusTop,
        ]}>
        <Text
          style={[styles.small, styles.center, styles.colorGrey, styles.mb5]}>
          Your project information
        </Text>
        <View style={styles.dashboardFlex}>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text style={[styles.paragraph, styles.center, styles.colorGrey]}>
              {numberFormat(results.totalEmployees)}
            </Text>
            <Text style={[styles.small, styles.center, styles.colorGrey]}>
              Total employees
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text style={[styles.paragraph, styles.center, styles.colorGrey]}>
              {priceFormat(results.totalAnnualCompensation)}
            </Text>
            <Text style={[styles.small, styles.center, styles.colorGrey]}>
              Annual compensation
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text style={[styles.paragraph, styles.center, styles.colorGrey]}>
              {numberFormat(results.squareFeet)}
            </Text>
            <Text style={[styles.small, styles.center, styles.colorGrey]}>
              Space sq. ft
            </Text>
          </View>
          <View style={[styles.w33, styles.dashboardElement]}>
            <Text style={[styles.paragraph, styles.center, styles.colorGrey]}>
              {numberFormat(results.cost)}
            </Text>
            <Text style={[styles.small, styles.center, styles.colorGrey]}>
              Cost
            </Text>
          </View>
        </View>

        <Text
          style={[styles.small, styles.center, styles.colorGrey, styles.mb5]}>
          Your model results
        </Text>
        <Text
          style={[styles.xxxl, styles.center, styles.colorGrey, styles.mb5]}>
          {percentageFormat(results.humanCapitalBenefitAsPercentOfCompensation)}
        </Text>
        <Text style={[styles.xxl, styles.center, styles.colorGrey, styles.mb5]}>
          {moneyFormat(results.humanCapitalBenefit)}
        </Text>
        <Text
          style={[
            styles.small,
            styles.w75,
            styles.center,
            styles.colorGrey,
            styles.mb5,
          ]}>
          Annual impact of space on value of human capital – as a percentage of
          annual compensation and dollar value
        </Text>
      </View>

      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Text
          style={[
            styles.main,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {!isStressNegative && '+'}
          {percentageFormat(results.stressPercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Difference in stress from average
        </Text>
      </View>
      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Text
          style={[
            styles.main,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {results.yearsToRecoup}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Annual payback on project / facility cost
        </Text>
      </View>
      <View style={[styles.dashboardFeature, styles.backgroundGrey]}>
        <Text
          style={[
            styles.main,
            styles.colorGrey,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(annualROI)}
        </Text>
        <Text style={[styles.paragraph, styles.colorGrey, styles.w75]}>
          Annual ROI on project / facility cost
        </Text>
      </View>
      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Image
          src={isNegative ? impactNegative : impact}
          style={styles.dashboardImage}
        />
        <Text
          style={[
            styles.paragraph,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {priceFormat(results.humanCapitalImpactPerSquareFoot)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          HC impact per sq. ft.
        </Text>
      </View>
      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Image
          src={isNegative ? unionNegative : union}
          style={styles.dashboardImage}
        />
        <Text
          style={[
            styles.paragraph,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.percentageOfReworkPercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Work accuracy
        </Text>
      </View>
      <View
        style={
          isNegative ? styles.dashboardFeatureNegative : styles.dashboardFeature
        }>
        <Image
          src={isNegative ? timeNegative : time}
          style={styles.dashboardImage}
        />
        <Text
          style={[
            styles.paragraph,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.processTimePercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Work process time
        </Text>
      </View>
      <View
        style={[
          ...(isNegative
            ? [styles.dashboardFeatureNegative]
            : [styles.dashboardFeature]),
          styles.radiusBottom,
        ]}>
        <Image
          src={isNegative ? retentionNegative : retention}
          style={styles.dashboardImage}
        />
        <Text
          style={[
            styles.paragraph,
            styles.colorInverse,
            styles.bold,
            styles.dashboardValue,
          ]}>
          {percentageFormat(results.retentionRatePercentageChange)}
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.w75]}>
          Retention rate
        </Text>
      </View>
    </View>
  );
};

const Page01 = ({ results, projectName, isStressNegative, isNegative }) => (
  <Page size="A4" style={[styles.page, styles.pageDefault]}>
    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.bold, styles.colorDefault]}>
        Your workspace results from{' '}
        <Text style={styles.link}>www.humanspace.ai</Text>
      </Text>
      <Text style={[styles.small, styles.bold, styles.colorDefault]}>
        Page 01
      </Text>
    </View>

    <View>
      <View style={styles.mainDescription}>
        <Text style={[styles.main, styles.colorDefault]}>
          We used the HUMANSPACE modeler to estimate the impact of the{' '}
          <Text style={styles.strong}>{projectName}</Text> workspace on the
          financial value of workforce human capital, operational costs, and
          stress.
        </Text>
      </View>

      <View style={styles.wrapper}>
        <View style={styles.column}>
          <Text style={[styles.paragraph, styles.mb5, styles.strong]}>
            Your project Information
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            These numbers indicate: the number of people using the space in this
            project, their estimated annual compensation (an indication of the
            value of their human capital), the square feet of this project, and
            the project cost or annual facility cost.
          </Text>
          <Text style={[styles.paragraph, styles.mb5, styles.strong]}>
            Your model results
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            Annual impact of the space on the financial value of human capital
            expressed both as a percentage of annual compensation cost, and the
            dollar value of the incremental or decremental value against a
            global average benchmark.
          </Text>
          <Text style={[styles.paragraph, styles.mb5, styles.strong]}>
            Employee stress
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            Our model estimates your workforce stress level (frequency of
            physical stress symptoms at work in the previous 3 weeks) against a
            benchmark average stress level. The percentage shown indicates the
            percentage above or below the benchmark stress symptom frequency.
            Stress is a leading indicator of future workforce performance,
            engagement, and health.
          </Text>
          <Text style={[styles.paragraph, styles.mb5]}>
            Return on investment
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            These metrics indicate the incremental or decremental ROI compared
            to a global average benchmark. The “year payback” field indicates
            how many years it would take to pay back the capital or facility
            cost based on the incremental value of workforce labor or cost
            avoidance.
          </Text>
        </View>
        <View style={styles.column}>
          <DashboardPageOne
            isNegative={isNegative}
            isStressNegative={isStressNegative}
            results={results}
          />
        </View>
      </View>
    </View>

    <View style={styles.spaceBetween}>
      <Text style={styles.small}>
        © HumanSpace Solutions. All rights reserved. {currentYear}
      </Text>
      <Image style={styles.footerImage} src={humanSpaceLogo}></Image>
    </View>
  </Page>
);

const Page02 = ({ results, isStressNegative, isNegative }) => (
  <Page size="A4" style={[styles.page, styles.pageDefault]}>
    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.bold, styles.colorDefault]}>
        Your workspace results from{' '}
        <Text style={styles.link}>www.humanspace.ai</Text>
      </Text>
      <Text style={[styles.small, styles.bold, styles.colorDefault]}>
        Page 02
      </Text>
    </View>

    <View>
      <View style={styles.wrapper}>
        <View style={styles.column}>
          <Text style={[styles.paragraph, styles.mb30]}>
            If this number is a negative, there is a negative impact of your
            space on the value of workforce human capital. This percentage
            indicates the annual ROI of either annual facility spend, or project
            cost, based on the incremental change in value of workforce human
            capital.
          </Text>
          <Text style={[styles.paragraph, styles.mb5, styles.strong]}>
            Human capital impact per square foot
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            This metric divides the annual dollar value of the incremental
            workforce human capital gain by the number of square feet specified
            in the model.
          </Text>
          <Text style={[styles.paragraph, styles.mb5, styles.strong]}>
            Work process and retention metrics
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            These are individual metrics that together, roll up to the single
            “headline” percentage and dollar value at the top of the screen.
            Work accuracy is an estimate of the change in annual percentage
            change of time spent revising work products, fixing errors,
            reworking ideas. It is a metric of work effectiveness. Work process
            time is a metric estimating the percentage change in the number of
            work processes completed annually – the amount of work completed.
            These two metrics interact with each other to provide a picture of
            work quality and volume completed, related to workforce
            productivity.
          </Text>
          <Text style={[styles.paragraph, styles.mb30]}>
            Retention rate is a metric related to operational costs. An increase
            in the retention rate percentage suggests that operational workforce
            costs will be avoided or reduced. The percentage shown is translated
            into a dollar value based on estimated employee replacement costs.
            It is possible to have negative retention rates which indicate the
            workplace is triggering incremental replacement costs due to higher
            employee turnover.
          </Text>
        </View>
        <View style={styles.column}>
          <DashboardPageTwo
            isNegative={isNegative}
            isStressNegative={isStressNegative}
            results={results}
          />
        </View>
      </View>
    </View>

    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.colorDefault]}>
        © HumanSpace Solutions. All rights reserved. {currentYear}
      </Text>
      <Image style={styles.footerImage} src={humanSpaceLogo}></Image>
    </View>
  </Page>
);

const Page03 = ({ features }) => (
  <Page size="A4" style={[styles.page, styles.pageInverse]}>
    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.bold, styles.colorInverse]}>
        Your workspace results from{' '}
        <Text style={styles.link}>www.humanspace.ai</Text>
      </Text>
      <Text style={[styles.small, styles.bold, styles.colorInverse]}>
        Page 03
      </Text>
    </View>

    <View>
      <View style={styles.mainDescription}>
        <Text style={[styles.main, styles.colorInverse, styles.mb30]}>
          We use a statistical technique, predictive modeling, to reveal what
          key "Top 3" features of the design, furnishings, technology, and
          ambient environment, affect the value of human capital.
        </Text>
        <Text style={[styles.paragraph, styles.colorInverse, styles.mb30]}>
          To best leverage investment in your workspace, we suggest optimizing
          the quality of each of the following three workspace capabilities.
        </Text>
      </View>
      {features && features.map(feature => getFeatures(feature.feature.name))}
    </View>

    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.colorInverse]}>
        © HumanSpace Solutions. All rights reserved. {currentYear}
      </Text>
      <Image style={styles.footerImage} src={humanSpaceLogoInverse}></Image>
    </View>
  </Page>
);

const Page04 = () => (
  <Page size="A4" style={[styles.page, styles.pageGradient]}>
    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.bold, styles.colorInverse]}>
        Your workspace results from{' '}
        <Text style={styles.link}>www.humanspace.ai</Text>
      </Text>
      <Text style={[styles.small, styles.colorInverse]}></Text>
    </View>

    <View>
      <View style={[styles.backgroundWrapper, styles.mb30]}>
        <Image src={background} style={styles.backgroundImage} />
      </View>
      <Image src={linkedin} style={[styles.linkedinImage, styles.mb30]} />
      <View style={styles.rightsWrapper}>
        <Text style={[styles.small, styles.mb15, styles.colorInverse]}>
          © EB Research. All rights reserved The information provided in this
          report is intended to provide directional guidance, and cannot
          substitute for the advice of a workplace professional or a complete
          analysis of your workplace and workforce.
        </Text>
        <Text style={[styles.small, styles.colorInverse]}>
          None of the individual contributors, system operators, developers,
          partners of EB Research LLC and HumanSpace nor anyone else connected
          to EB Research LLC can take any responsibility for the results or
          consequences of any attempt to apply or adopt any of the information
          presented or generated from the HumanSpace web site
        </Text>
      </View>
    </View>

    <View style={styles.spaceBetween}>
      <Text style={[styles.small, styles.colorInverse]}>
        © HumanSpace Solutions. All rights reserved. {currentYear}
      </Text>
      <Image style={styles.footerImage} src={humanSpaceLogoInverse}></Image>
    </View>
  </Page>
);

const ResultsPDF = ({ results, isStressNegative, features, isNegative }) => {
  const hasFeatures = features && features.length > 0;

  return (
    <Document>
      <Page01
        projectName={results.name}
        isStressNegative={isStressNegative}
        isNegative={isNegative}
        results={results}
      />
      <Page02
        isStressNegative={isStressNegative}
        isNegative={isNegative}
        results={results}
      />
      {hasFeatures && <Page03 features={features} />}
      <Page04 />
    </Document>
  );
};

export default ResultsPDF;
