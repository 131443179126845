import { connect } from 'react-redux';

import actions from '../../duck/actions';

import RequestPassword from './RequestPassword';

const mapDispatchToProps = dispatch => ({
  passwordRequest: ({ email }) => dispatch(actions.passwordRequest({ email })),
});

export default connect(null, mapDispatchToProps)(RequestPassword);
