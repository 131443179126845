import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired } from '@humanspace/utils/validations';
import { normalizeVariableName } from '@humanspace/utils/transformers';

import RoleCharacteristicsForm from './RoleCharacteristicsForm';

const form = 'RoleCharacteristicsForm';

const selector = formValueSelector(form);

const generateCharacteristicsVariableForEveryRole = roleCharacteristics => {
  const finalObject = {};

  roleCharacteristics.forEach(role => {
    const currentRole = role.role;

    finalObject[
      `${currentRole}${normalizeVariableName(
        'averageCostReplaceEmployeePercentCompensation',
      )}`
    ] = role.characteristics.averageCostReplaceEmployeePercentCompensation;

    finalObject[
      `${currentRole}${normalizeVariableName('averageCostPerHourOfRework')}`
    ] = role.characteristics.averageCostPerHourOfRework;

    finalObject[
      `${currentRole}${normalizeVariableName('averageAnnualHoursOfWork')}`
    ] = role.characteristics.averageAnnualHoursOfWork;

    finalObject[
      `${currentRole}${normalizeVariableName('averageRetentionRate')}`
    ] = role.characteristics.averageRetentionRate;

    finalObject[
      `${currentRole}${normalizeVariableName('averagePercentOfProcessRework')}`
    ] = role.characteristics.averagePercentOfProcessRework;

    finalObject[
      `${currentRole}${normalizeVariableName(
        'averagePercentageOfTimeSpentInProcessWork',
      )}`
    ] = role.characteristics.averagePercentageOfTimeSpentInProcessWork;

    finalObject[
      `${currentRole}${normalizeVariableName('averageProcessTimePerEmployee')}`
    ] = role.characteristics.averageProcessTimePerEmployee;
  });

  return finalObject;
};

const generateSelectorValuesFromCharacteristics = (
  roleCharacteristics,
  state,
) => {
  const finalObject = {};

  roleCharacteristics.forEach(role => {
    const currentRole = role.role;

    finalObject[
      `${currentRole}${normalizeVariableName(
        'averageCostReplaceEmployeePercentCompensation',
      )}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName(
        'averageCostReplaceEmployeePercentCompensation',
      )}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName('averageCostPerHourOfRework')}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName('averageCostPerHourOfRework')}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName('averageAnnualHoursOfWork')}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName('averageAnnualHoursOfWork')}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName('averageRetentionRate')}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName('averageRetentionRate')}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName('averagePercentOfProcessRework')}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName('averagePercentOfProcessRework')}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName(
        'averagePercentageOfTimeSpentInProcessWork',
      )}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName(
        'averagePercentageOfTimeSpentInProcessWork',
      )}`,
    );

    finalObject[
      `${currentRole}${normalizeVariableName('averageProcessTimePerEmployee')}`
    ] = selector(
      state,
      `${currentRole}${normalizeVariableName('averageProcessTimePerEmployee')}`,
    );
  });

  return finalObject;
};

const mapStateToProps = state => ({
  initialValues: generateCharacteristicsVariableForEveryRole(
    state.projects.roleCharacteristics,
  ),
  roleCharacteristicsFormValues: generateSelectorValuesFromCharacteristics(
    state.projects.roleCharacteristics,
    state,
  ),
});

const validate = ({
  averageCostReplaceEmployeePercentCompensation,
  averageCostPerHourOfRework,
  averageAnnualHoursOfWork,
  averageRetentionRate,
  averagePercentOfProcessRework,
  averagePercentageOfTimeSpentInProcessWork,
  averageProcessTimePerEmployee,
}) => ({
  averageCostReplaceEmployeePercentCompensation: isRequired(
    averageCostReplaceEmployeePercentCompensation,
  ),
  averageCostPerHourOfRework: isRequired(averageCostPerHourOfRework),
  averageAnnualHoursOfWork: isRequired(averageAnnualHoursOfWork),
  averageRetentionRate: isRequired(averageRetentionRate),
  averagePercentOfProcessRework: isRequired(averagePercentOfProcessRework),
  averagePercentageOfTimeSpentInProcessWork: isRequired(
    averagePercentageOfTimeSpentInProcessWork,
  ),
  averageProcessTimePerEmployee: isRequired(averageProcessTimePerEmployee),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RoleCharacteristicsForm);

export default connect(mapStateToProps, null)(DecoratedForm);
