import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const Container = styled.section`
  margin-bottom: ${Spacing.l};
`;

const Title = styled(Text)`
  margin-bottom: ${Spacing.s};
`;

const ViewModelDetails = styled(Button)`
  margin-left: 0;
  margin-top: ${Spacing.l};
`;

const ModelDetailsSection = ({ id, isResponsive, ...props }) => {
  const history = useHistory();

  return (
    <Container {...props}>
      <Title size="xl" bold lineHeight={1.2}>
        Do you want to know more?
      </Title>
      <Text size="m">
        Click the button below to learn in more detail about how the main
        outcomes of the model were calculated.
      </Text>
      <ViewModelDetails
        full={isResponsive}
        secondary
        onClick={() => history.push(`/projects/${id}/model-details`)}>
        View model details
      </ViewModelDetails>
    </Container>
  );
};

export default ModelDetailsSection;
