import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
  change,
} from 'redux-form';
import { connect } from 'react-redux';

import { isRequired } from '@humanspace/utils/validations';

import RatingForm from './RatingForm';

const form = 'RatingForm';

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  modelConfig: {
    workPlacePercentageChange: selector(state, 'workPlacePercentageChange'),
    groupSpacePercentageChange: selector(state, 'groupSpacePercentageChange'),
    brandPercentageChange: selector(state, 'brandPercentageChange'),
    interiorDesignPercentageChange: selector(
      state,
      'interiorDesignPercentageChange',
    ),
    activityPercentageChange: selector(state, 'activityPercentageChange'),
    changeManagementPercentageChange: selector(
      state,
      'changeManagementPercentageChange',
    ),
  },
  initialValues: state.projects.project,
  isLoading: state.shared.isLoading,
  formErrors: getFormSyncErrors(form)(state),
});

const mapDispatchToProps = dispatch => ({
  changeValue: (property, value) => dispatch(change(form, property, value)),
});

const validate = ({
  workPlacePercentageChange,
  groupSpacePercentageChange,
  brandPercentageChange,
  interiorDesignPercentageChange,
  activityPercentageChange,
  changeManagementPercentageChange,
}) => ({
  workPlacePercentageChange: isRequired(
    workPlacePercentageChange,
    'workPlacePercentageChange',
  ),
  groupSpacePercentageChange: isRequired(
    groupSpacePercentageChange,
    'groupSpacePercentageChange',
  ),
  brandPercentageChange: isRequired(
    brandPercentageChange,
    'brandPercentageChange',
  ),
  interiorDesignPercentageChange: isRequired(
    interiorDesignPercentageChange,
    'interiorDesignPercentageChange',
  ),
  activityPercentageChange: isRequired(
    activityPercentageChange,
    'activityPercentageChange',
  ),
  changeManagementPercentageChange: isRequired(
    changeManagementPercentageChange,
    'changeManagementPercentageChange',
  ),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RatingForm);

export default connect(mapStateToProps, mapDispatchToProps)(DecoratedForm);
