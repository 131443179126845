import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  Icon,
  Header,
  useMediaQueryContext,
  TopBreadcrumb,
} from '../../components';
import { Spacing, Colors } from '../../foundations';

const getLayoutAlignment = ({ align }) => {
  switch (align) {
    case 'top':
      return 'flex-start';
    default:
      return 'center';
  }
};

const getContainerWidth = ({ isResponsive, isMultiple, fullWidth }) => {
  if (fullWidth) return '1280px';
  if (isResponsive || !isMultiple) return '480px';
  return '1280px';
};

const getMarginBottom = ({ withoutHeader, isResponsive }) => {
  if (withoutHeader) return '0';
  if (isResponsive) return Spacing.xl;
  return Spacing.xl;
};

const MainLayoutBackgroundColor = props => {
  if (props.inverse) {
    return `background-color: ${Colors.white}`;
  }

  if (props.backgroundColor) {
    return `background-color: ${props.backgroundColor}`;
  }

  return `background: ${Colors.verticalGradient}`;
};

const Main = styled.main`
  padding-left: ${props => (props.isResponsive ? Spacing.s : Spacing.xl)};
  padding-right: ${props => (props.isResponsive ? Spacing.s : Spacing.xl)};
  height: 100vh;
  overflow-y: auto;
  ${MainLayoutBackgroundColor};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const NavigationHeader = styled(Header)`
  margin-bottom: ${props => (props.isResponsive ? Spacing.xl : Spacing.xxxl)};
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: ${Spacing.s};
  min-height: ${props => (props.withoutHeader ? '0' : '136px')};

  margin-bottom: ${getMarginBottom};
`;

const BottomWrapper = styled.div`
  margin-top: ${Spacing.xxxxxl};
`;

const Container = styled.div`
  max-width: ${getContainerWidth};
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: ${props =>
    props.isResponsive || !props.isMultiple ? 'column' : 'row'};
  align-items: ${getLayoutAlignment};
  justify-content: center;
  position: relative;
`;

const Column = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  position: relative;
  flex-flow: column;
  flex-basis: 100%;
  min-width: 0;

  &:first-child {
    padding-right: ${props => (props.isResponsive ? '0' : Spacing.m)};
  }

  &:last-child {
    padding-left: ${props => (props.isResponsive ? '0' : Spacing.m)};
  }

  ${props =>
    props.scroll === 'sticky' &&
    ` position: sticky;
      top: ${Spacing.xs};
    `}
`;

const Layout = ({
  inverse,
  backgroundColor,
  align,
  fullWidth,
  multiple,
  leftColumn,
  rightColumn,
  render,
  hasNavigation,
  withoutHeader,
  breadcrumb,
  bottom,
  scroll,
  back,
  onBack,
  ...props
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const history = useHistory();

  const isResponsive = isMobile || isTablet;

  return (
    <Main
      {...props}
      backgroundColor={backgroundColor}
      inverse={inverse}
      isResponsive={isResponsive}>
      {hasNavigation ? (
        <NavigationHeader
          onBack={onBack}
          back={back}
          inverse={inverse}
          isResponsive={isResponsive}
        />
      ) : (
        <StyledHeader withoutHeader={withoutHeader} isResponsive={isResponsive}>
          {!withoutHeader && (
            <StyledIcon onClick={() => history.push('/')} icon="logo" />
          )}
        </StyledHeader>
      )}

      {multiple ? (
        <React.Fragment>
          <Container
            fullWidth={fullWidth}
            isMultiple={multiple}
            isResponsive={isResponsive}>
            {breadcrumb && !isResponsive && (
              <TopBreadcrumb elements={breadcrumb} />
            )}
            <Wrapper
              align={align}
              isMultiple={multiple}
              isResponsive={isResponsive}>
              <Column isResponsive={isResponsive}>{leftColumn}</Column>
              <Column scroll={scroll} isResponsive={isResponsive}>
                {rightColumn}
              </Column>
            </Wrapper>
          </Container>
        </React.Fragment>
      ) : (
        <Wrapper
          align={align}
          isMultiple={multiple}
          isResponsive={isResponsive}>
          <Container fullWidth={fullWidth}>{render}</Container>
        </Wrapper>
      )}

      {bottom && multiple && (
        <BottomWrapper>
          <Container fullWidth={fullWidth} isMultiple={multiple}>
            {bottom}
          </Container>
        </BottomWrapper>
      )}
    </Main>
  );
};

export default Layout;
