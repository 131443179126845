export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const searchParams = (params, prefix) => {
  const finalString = [];
  let p = null;

  for (p in params) {
    if (params.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = params[p];
      finalString.push(
        v !== null && typeof v === 'object'
          ? searchParams(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }
  return finalString.join('&');
};
