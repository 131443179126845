const modelAction = action => `MODEL/${action}`;

const CREATE_PREDICTION_MODEL = modelAction('CREATE_PREDICTION_MODEL');
const CREATE_PREDICTION_MODEL_SUCCESS = modelAction(
  'CREATE_PREDICTION_MODEL_SUCCESS',
);
const CREATE_PREDICTION_MODEL_FAILURE = modelAction(
  'CREATE_PREDICTION_MODEL_FAILURE',
);

const GET_STRESS_MODELS = modelAction('GET_STRESS_MODELS');
const GET_STRESS_MODELS_SUCCESS = modelAction('GET_STRESS_MODELS_SUCCESS');
const GET_STRESS_MODELS_FAILURE = modelAction('GET_STRESS_MODELS_FAILURE');

const GET_STRESS_MODEL = modelAction('GET_STRESS_MODEL');
const GET_STRESS_MODEL_SUCCESS = modelAction('GET_STRESS_MODEL_SUCCESS');
const GET_STRESS_MODEL_FAILURE = modelAction('GET_STRESS_MODEL_FAILURE');

const REMOVE_STRESS_MODEL = modelAction('REMOVE_STRESS_MODEL');
const REMOVE_STRESS_MODEL_SUCCESS = modelAction('REMOVE_STRESS_MODEL_SUCCESS');
const REMOVE_STRESS_MODEL_FAILURE = modelAction('GET_STRESS_MODEL_FAILURE');

export default {
  CREATE_PREDICTION_MODEL,
  CREATE_PREDICTION_MODEL_FAILURE,
  CREATE_PREDICTION_MODEL_SUCCESS,
  GET_STRESS_MODELS,
  GET_STRESS_MODELS_SUCCESS,
  GET_STRESS_MODELS_FAILURE,
  GET_STRESS_MODEL,
  GET_STRESS_MODEL_SUCCESS,
  GET_STRESS_MODEL_FAILURE,
  REMOVE_STRESS_MODEL,
  REMOVE_STRESS_MODEL_SUCCESS,
  REMOVE_STRESS_MODEL_FAILURE,
};
