import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';

import {
  Text,
  Layout,
  ReadMore,
  Input,
  InputGroup,
  Breadcrumb,
  Button,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { copyToClipboard } from '../../utils/helpers';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${props => (!props.isResponsive ? '0' : Spacing.xxxxxl)};
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const CreatedSurvey = () => {
  const { id, surveyId } = useParams();
  const history = useHistory();
  const surveyLink = `${process.env.REACT_APP_URL}/projects/${id}/surveys/${surveyId}`;

  const inputConfig = { name: 'surveyId', value: surveyLink };

  return (
    <React.Fragment>
      <Layout
        breadcrumb={[
          {
            text: 'Back to survey management page',
            onClick: () => history.push(`/projects/${id}`),
            active: false,
          },
        ]}
        multiple
        linkElement={Link}
        leftColumn={
          <React.Fragment>
            <Text size="xl" bold lineHeight={1.1}>
              Here is your new survey collector link!
            </Text>
            <StyledText size="m" lineHeight={1.7}>
              <ReadMore>
                Use the “Copy link” button on the right to share this survey
                collector link.
              </ReadMore>
            </StyledText>
            {id && surveyId && (
              <React.Fragment>
                <StyledBreadcrumb
                  elements={[
                    {
                      text: 'Back to survey management page',
                      onClick: () => history.push(`/projects/${id}`),
                      active: false,
                    },
                  ]}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
        rightColumn={
          <React.Fragment>
            <InputGroup>
              <Input input={inputConfig} disabled />
            </InputGroup>
            <StyledButton
              onClick={() =>
                copyToClipboard(
                  surveyLink,
                  'The link to the survey has been copied correctly!',
                )
              }
              secondary>
              Copy link
            </StyledButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

CreatedSurvey.propTypes = {
  isLoading: PropTypes.bool,
};

CreatedSurvey.defaultProps = {
  isLoading: false,
};

export default CreatedSurvey;
