import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Spacing, Colors } from '../../../foundations';
import { Touchable, Icon } from '../../../components';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${Spacing.m};
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: transform 0.6s cubic-bezier(0, 0.52, 0, 1);
  transform: ${props =>
    props.isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)'};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  max-width: 480px;
  margin: 0 auto;
  z-index: 5;
  max-height: 100vh;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  display: flex;
  margin-bottom: ${Spacing.l};
  justify-content: flex-end;
  padding: ${Spacing.s} 0;
`;

const Content = styled.div`
  margin-bottom: ${Spacing.l};
`;

const StyledIcons = styled(Icon)`
  max-width: 15px;
`;

const MobileInfo = ({ isVisible, onClose, children }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isVisible]);

  return (
    <Wrapper ref={wrapperRef} isVisible={isVisible}>
      <Header>
        <Touchable
          onClick={() => {
            onClose();
          }}>
          <StyledIcons fill={Colors.black} icon="close" />
        </Touchable>
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default MobileInfo;
