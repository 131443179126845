import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMediaQueryContext } from '../../components';
import { Colors } from '../../foundations';

import ResponsiveModal from './ResponsiveModal';
import DesktopModal from './DesktopModal';

const getModalBackground = ({ isNegative, isPositive, backgroundColor }) => {
  if (backgroundColor) return backgroundColor;
  if (isNegative) return Colors.maroon;
  if (isPositive) return Colors.primary;
  return Colors.grey700;
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  z-index: 4;
  height: 100vh;

  background: ${props => getModalBackground(props)};
`;

const Modal = props => {
  const {
    isVisible,
    currentValue,
    backgroundColor,
    render,
    leftColumn,
    rightColumn,
    withoutHeader,
    onBack,
  } = props;

  const { isMobile, isTablet } = useMediaQueryContext();
  const isPositive = currentValue && currentValue > 0;
  const isNegative = currentValue && currentValue < 0;

  const isResponsive = isMobile || isTablet;

  return (
    isVisible && (
      <Wrapper
        isPositive={isPositive}
        isNegative={isNegative}
        backgroundColor={backgroundColor}>
        {isResponsive ? (
          <ResponsiveModal
            withoutHeader={withoutHeader}
            render={render}
            {...props}
          />
        ) : (
          <DesktopModal
            onBack={onBack}
            backgroundColor={getModalBackground({
              isNegative,
              isPositive,
              backgroundColor,
            })}
            withoutHeader={withoutHeader}
            render={render}
            leftColumn={leftColumn}
            rightColumn={rightColumn}
            {...props}
          />
        )}
      </Wrapper>
    )
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onBack: PropTypes.func,
  back: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  withoutPadding: PropTypes.bool,
};

Modal.defaultProps = {
  title: undefined,
  isVisible: false,
  currentValue: 0,
  onBack: undefined,
  back: true,
  withoutHeader: false,
  withoutPadding: false,
};

export default Modal;
