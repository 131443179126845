import { connect } from 'react-redux';

import actions from '../../duck/actions';

import SurveyRating from './SurveyRating';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
});

const mapDispatchToProps = dispatch => ({
  answerSurvey: (surveyId, projectId, capabilitiesValues) =>
    dispatch(actions.answerSurvey(surveyId, projectId, capabilitiesValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRating);
