import React from 'react';
import styled from 'styled-components';
import { Layout } from '../../../components';
import { Spacing } from '../../../foundations';

const ModalContainer = styled.div`
  width: 100%;
  margin-bottom: ${Spacing.xl};
`;

const DesktopModal = ({
  leftColumn,
  rightColumn,
  back,
  onBack,
  withoutHeader,
  render,
}) => {
  return (
    <ModalContainer>
      <Layout
        backgroundColor="transparent"
        multiple={!render}
        render={render}
        leftColumn={leftColumn}
        rightColumn={rightColumn}
        hasNavigation={!withoutHeader}
        withoutHeader={withoutHeader}
        back={back}
        onBack={onBack}
      />
    </ModalContainer>
  );
};

export default DesktopModal;
