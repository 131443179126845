import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import {
  InputGroup,
  Input,
  Button,
  Select,
  Textarea,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
`;

const StyledInputGroup = styled(InputGroup)`
  margin-bottom: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xxl};
`;

const interestedOptions = [
  {
    label: 'Premium Upgrade',
    value: 'premiumUpgrade',
  },
  {
    label: 'Video Checkup',
    value: 'videoCheckup',
  },
  {
    label: 'Book a Demo',
    value: 'bookDemo',
  },
  {
    label: 'More information',
    value: 'moreInformation',
  },
];

const ContactForm = ({ handleSubmit, submitting, invalid, isLoading }) => (
  <StyledForm onSubmit={handleSubmit}>
    <StyledInputGroup>
      <Field dark component={Input} name="name" placeholder="Name" />
      <Field
        dark
        component={Input}
        name="email"
        placeholder="Email"
        type="email"
      />
      <Field dark component={Input} name="company" placeholder="Company" />
      <Field
        dark
        component={Select}
        options={interestedOptions}
        name="interested"
        placeholder="Interested in..."
      />
      <Field dark component={Textarea} name="message" placeholder="Message" />
    </StyledInputGroup>

    <StyledButton
      type="submit"
      isLoading={isLoading}
      disabled={submitting || invalid}>
      Send
    </StyledButton>
  </StyledForm>
);

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

ContactForm.defaultProps = {
  isLoading: false,
};

export default ContactForm;
