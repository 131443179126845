import React from 'react';
import styled from 'styled-components';

import { Text, Icon } from '..';
import { Spacing, Colors } from '../../foundations';

const Wrapper = styled.a`
  display: flex;
  justify-content; space-between;
  align-items: center;
  padding: ${Spacing.s} ${Spacing.m};
  border-bottom: 2px solid ${Colors.black200};
  background-color: ${Colors.white200};
  cursor: pointer;

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0;
  `};

  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
  `};
`;

const InfoWrapper = styled.div`
  margin-right: ${Spacing.l};
  width: 100%;
`;

const Feature = ({ isLast, isFirst, icon, title, description, ...props }) => {
  return (
    <Wrapper
      {...props}
      target="_blank"
      rel="noopener noreferrer"
      isLast={isLast}
      isFirst={isFirst}>
      <InfoWrapper>
        <Text size="m">{title}</Text>
        <Text size="xxs">{description}</Text>
      </InfoWrapper>
      <Icon icon="vector" />
    </Wrapper>
  );
};

export default Feature;
