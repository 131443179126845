import React from 'react';
import styled from 'styled-components';

import { Text } from '../../components';
import { Colors, Spacing } from '../../foundations';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  z-index: 3;
  margin-bottom: ${Spacing.xxxxl};
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

const Separator = styled.span`
  margin: 0 ${Spacing.xs};
  color: ${Colors.white};
  font-weight: normal;
`;

const TopBreadcrumb = ({ elements, ...props }) => (
  <Container {...props}>
    {elements &&
      elements.map((element, index) => {
        const isLastElement = elements.length - 1 === index;

        return (
          <StyledText
            size="xxs"
            bold={element.active}
            color={element.active ? Colors.anakiwa : Colors.white}
            key={index}
            onClick={element.onClick}>
            {element.text}
            {!isLastElement && elements.length > 1 && (
              <Separator> &#62; </Separator>
            )}
          </StyledText>
        );
      })}
  </Container>
);

export default TopBreadcrumb;
