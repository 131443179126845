import camelCase from 'lodash/camelCase';

export const filterObjectByRole = (object, role) =>
  Object.keys(object)
    .filter(key => key.includes(role))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});

export const removeRoleFromKey = (object, role) => {
  Object.keys(object).forEach(key => {
    object[camelCase(key.replace(role, ``))] = object[key];
    delete object[key];
  });

  return object;
};

export const mergeProjectsAndModels = (projects, stressModels) => {
  const projectsWithType = projects.map(object => {
    return { ...object, type: 'project' };
  });

  const stressModelsWithType = stressModels.map(object => {
    return { ...object, type: 'model' };
  });

  return [...projectsWithType, ...stressModelsWithType].sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
  );
};
