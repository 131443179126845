import styled from 'styled-components';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';

const SLIDER_WIDTH = '100%';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
`;

export const Value = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Slider = styled.div`
  position: relative;
  width: ${SLIDER_WIDTH};
`;

export const SliderTrack = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
  background: ${Colors.white200};
  border-radius: 20px;
  z-index: 1;
`;

const ThumbStyles = `
  -webkit-appearance: none;
  background: transparent;
  outline: 0;
  pointer-events: none;
  position: absolute;
  height: 0;
  width: ${SLIDER_WIDTH};

&::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

&::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

&::-webkit-slider-thumb {
  border-radius: 50px;
  background-color: ${Colors.white};
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}

&::-moz-range-thumb {
  border-radius: 50px;
  background-color: ${Colors.white};
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}`;

export const ThumbLeft = styled.input`
  ${ThumbStyles};
  z-index: 3;
`;

export const ThumbRight = styled.input`
  ${ThumbStyles};
  z-index: 4;

  &::-webkit-slider-thumb {
    background-color: transparent;
    border: 1px solid ${Colors.white};
  }

  &::-moz-range-thumb {
    background-color: transparent;
    border: 1px solid ${Colors.white};
  }
`;
