import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const Container = styled.section`
  margin-top: ${props => (props.isResponsive ? '0' : Spacing.xxxxl)};
  margin-bottom: ${Spacing.xxxxxl};
`;

const Title = styled(Text)`
  margin-bottom: ${Spacing.s};
`;

const ViewModelDetails = styled(Button)`
  margin-left: 0;
  margin-top: ${Spacing.l};
`;

const SurveySection = ({ id, isResponsive, withoutDescription, ...props }) => {
  const history = useHistory();

  return (
    <Container isResponsive={isResponsive} {...props}>
      {!withoutDescription && (
        <>
          <Title size="xl" bold lineHeight={1.2}>
            Use the survey to collect workspace ratings
          </Title>
          <Text size="m" lineHeight={1.7}>
            Use this survey tool to gather workplace ratings that can be used to
            develop a model.
          </Text>
        </>
      )}

      <ViewModelDetails
        full={isResponsive}
        secondary
        onClick={() => history.push(`/projects/${id}/surveys`)}>
        Go to Survey Management
      </ViewModelDetails>
    </Container>
  );
};

export default SurveySection;
