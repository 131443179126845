import React from 'react';
import styled from 'styled-components';

import { Text } from '..';
import { Spacing, Colors } from '../../foundations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  flex: 1;
`;

const Title = styled.title`
  display: flex;
  justify-content; space-between;
  align-items: center;
  padding: ${Spacing.s} ${Spacing.m};
  border-bottom: 2px solid ${Colors.black200};
  background-color: ${Colors.anakiwaBackground};
  cursor: pointer;

  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
  `};
`;

const CapabilityGroup = ({ children, title, ...props }) => {
  const arrayLength = React.Children.toArray(children).length;

  return (
    <React.Fragment>
      <Wrapper {...props}>
        {title && (
          <Title isFirst>
            <Text size="m">{title}</Text>
          </Title>
        )}
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            isLast: arrayLength === index + 1,
            isFirst: title ? false : index === 0,
          }),
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default CapabilityGroup;
