import React from 'react';

import styled from 'styled-components';

import Text from '../Text';
import { Colors, Spacing } from '../../foundations';

const Container = styled.ul`
  width: 100%;
  border-radius: 10px;
  background-color: ${Colors.grey300};
  padding: 0;
  margin: 0;
  min-height: ${props => (props.sameHeight ? '764px' : 'auto')};
`;

const Element = styled.li`
  border-bottom: 2px solid ${Colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.s};

  &:last-child {
    border-bottom: none;
  }
`;

const Column = styled(Text)`
  width: ${props => (props.width ? props.width : '50%')};
`;

const Title = styled(Text)`
  margin-bottom: ${Spacing.s};
`;

const DetailsList = ({ details, title, sameHeight, ...props }) => (
  <div {...props}>
    {title && (
      <Title size="l" bold color={Colors.primary}>
        {title}
      </Title>
    )}
    <Container sameHeight={sameHeight}>
      {details &&
        details.map(detail => (
          <Element>
            <Column width="75%" color={Colors.black}>
              {detail.name}
            </Column>
            <Column align="right" color={Colors.black}>
              {detail.value}
            </Column>
          </Element>
        ))}
    </Container>
  </div>
);

export default DetailsList;
