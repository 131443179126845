import React from 'react';
import styled from 'styled-components';

import { Text, Layout } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const RequestPasswordConfirmation = () => (
  <Layout
    render={
      <>
        <StyledText align="center" size="xl" bold>
          Please check your inbox
        </StyledText>
        <StyledText align="center" size="l">
          We have sent you an email to reset your password
        </StyledText>
      </>
    }
  />
);

export default RequestPasswordConfirmation;
