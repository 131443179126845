import * as tf from '@tensorflow/tfjs';

export const generateDataset = async file => {
  const filePath = URL.createObjectURL(file);
  const csvDataset = tf.data.csv(filePath, {
    hasHeader: true,
  });

  const datasetArray = await csvDataset.toArray();
  const finalDataset = datasetArray.map(function(obj) {
    return Object.keys(obj)
      .sort()
      .map(function(key) {
        return obj[key];
      });
  });

  return finalDataset;
};

export const formatPredictionForDB = prediction => {
  if (!prediction) return;

  const predictionArray = JSON.stringify(prediction.map(item => item[0]));
  return predictionArray.replace('[', '{').replace(']', '}');
};

export const formatPredictionFromDB = prediction => {
  if (!prediction) return;

  const fromJSONToArray = prediction.replace('{', '[').replace('}', ']');
  return JSON.parse(fromJSONToArray);
};
