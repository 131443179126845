import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Input, Button, InputGroup } from '@humanspace/components';

import { Spacing } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.xl};
  margin-bottom: ${Spacing.xl};
  margin-left: auto;
  margin-right: auto;
`;

const ResetPasswordForm = ({
  handleSubmit,
  submitting,
  invalid,
  isLoading,
  formMeta,
  formErrors,
}) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputGroup meta={formMeta} errors={formErrors}>
        <Field
          type="password"
          component={Input}
          name="newPassword"
          placeholder="New password"
        />
        <Field
          type="password"
          component={Input}
          name="repeatNewPassword"
          placeholder="Confirm password"
        />
      </InputGroup>
      <StyledButton
        type="submit"
        isLoading={isLoading}
        disabled={submitting || invalid}>
        Save password
      </StyledButton>
    </StyledForm>
  );
};

export default ResetPasswordForm;
