import { connect } from 'react-redux';

import actions from '../../duck/actions';

import NewModel from './NewModel';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
});

const mapDispatchToProps = dispatch => ({
  createModel: modelConfig =>
    dispatch(actions.createPredictionModel(modelConfig)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewModel);
