import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../foundations';

const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background-color: ${Colors.white};
    cursor: pointer;
    margin-top: -10px;
  }

  &::-moz-range-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background-color: ${Colors.white};
    cursor: pointer;
  }

  &::-ms-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background-color: ${Colors.white};
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
    background: ${Colors.white200};
    border-radius: 20px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${Colors.white200};
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
    background: ${Colors.white200};
    border-radius: 20px;
  }

  &::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    border-radius: 20px;
    background: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: ${Colors.white200};
    border-radius: 20px;
    box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
  }
  &:focus::-ms-fill-lower {
    background: ${Colors.white200};
  }
  &::-ms-fill-upper {
    background: ${Colors.white200};
    border-radius: 20px;
    box-shadow: 0px 20px 40px 0px ${Colors.colorShadow};
  }
  &:focus::-ms-fill-upper {
    background: ${Colors.white200};
  }
`;

const Slider = ({ input, ...props }) => (
  <StyledInput
    {...input}
    {...props}
    type="range"
    name="points"
    min="-100"
    max="100"
  />
);

export default Slider;
