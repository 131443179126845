import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../foundations';

import Icon from '../Icon';
import Text from '../Text';

const Wrapper = styled.div`
  position: relative;
`;

const MenuList = styled.ul`
  background-color: ${Colors.white};
  box-shadow: 0px 20px 40px ${Colors.colorShadow};
  border-radius: 20px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: ${Spacing.xl};
  right: 0;
  z-index: 10;

  ${props =>
    props.isMenuOpen
      ? `
      transform: scale(1);
      transition: all 0.25s cubic-bezier(.5,1.8,.9,.8);
 `
      : `
      transition: all 0.25s ease-out;
      transform: scale(0);
      transform-origin: 100% 0;`}
`;

const List = styled.li`
  padding: ${Spacing.xs} ${Spacing.s};
  border-bottom: 2px solid ${Colors.grey300};

  &:last-child {
    border-bottom: 0;
  }
`;

const PopoverMenu = ({ options, isOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
  let ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <Wrapper ref={ref}>
      <Icon onClick={() => setIsMenuOpen(!isMenuOpen)} icon="kebad-menu"></Icon>
      <MenuList isMenuOpen={isMenuOpen}>
        {options &&
          options.map((option, index) => (
            <List key={index} onClick={option.action}>
              <Text
                align="left"
                color={option.secondary ? Colors.grey700 : Colors.secondary}>
                {option.name}
              </Text>
            </List>
          ))}
      </MenuList>
    </Wrapper>
  );
};

export default PopoverMenu;
