import { searchParams } from '@humanspace/utils/api';

const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';

const makeRequest = async ({ path, method, body }) => {
  const userId = window.localStorage.getItem('userId');
  const userToken = window.localStorage.getItem('userToken');

  const url = isLocal
    ? `https://sleepy-garden-12272.herokuapp.com/${process.env.REACT_APP_API_URL}${path}`
    : `${process.env.REACT_APP_API_URL}${path}`;

  const response = await fetch(url, {
    method: method || 'GET',
    body: body && searchParams({ ...body, ...(userId && { userId }) }),
    headers: {
      ...(userToken && { Authorization: `Bearer ${userToken}` }),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.json();
};

export default makeRequest;
