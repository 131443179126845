import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';
import Dots from '../Dots';

import styled from 'styled-components';
import ReactCarousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const MAX_WIDTH = 320;

const CarouselWrapper = styled.div`
  margin-bottom: ${Spacing.xxxxxl};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  background-color: ${Colors.secondary};
`;

const ActionLink = styled(Text)`
  cursor: pointer;
`;

const Carousel = ({ children, onSwipe, onSkip, ...props }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const isLastItem = children.length - 1 === currentPosition;

  return (
    <CarouselWrapper>
      <ReactCarousel
        onChange={position => setCurrentPosition(position)}
        value={currentPosition}
        {...props}>
        {children}
      </ReactCarousel>
      <Footer>
        <ActionLink color={Colors.grey700} onClick={onSkip}>
          Skip
        </ActionLink>
        <Dots total={children.length} current={currentPosition} />
        {isLastItem ? (
          <ActionLink color={Colors.anakiwa} onClick={onSkip}>
            Start
          </ActionLink>
        ) : (
          <ActionLink
            color={Colors.anakiwa}
            onClick={() => setCurrentPosition(currentPosition + 1)}>
            Next
          </ActionLink>
        )}
      </Footer>
    </CarouselWrapper>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
};

Carousel.defaultProps = {
  children: undefined,
};

export default Carousel;
