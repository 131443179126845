import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import actions from '../../duck/actions';

import Benchmark from './Benchmark';

const form = 'Benchmark';

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  industry: selector(state, 'industry'),
  results: state.projects.results,
  benchmarkData: state.projects.benchmarkData,
  isLoading: state.shared.isLoading,
  project: state.projects.project,
  benchmarkModel: state.projects.benchmarkModel,
});

const mapDispatchToProps = dispatch => ({
  getBenchmarkData: () => dispatch(actions.getBenchmarkData()),
  getProject: projectId => dispatch(actions.getProject(projectId)),
  getBenchmarkModel: (projectId, config) =>
    dispatch(actions.getBenchmarkModel(projectId, config)),
});

const DecoratedForm = reduxForm({
  form,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Benchmark);

export default connect(mapStateToProps, mapDispatchToProps)(DecoratedForm);
