import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Info } from '@humanspace/components';
import { Colors, Spacing } from '@humanspace/foundations';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.xs};
`;

const PodModal = ({ isOpen, onClose, type }) => {
  switch (type) {
    case 'annualBenefit':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Annual Benefit in Value of Human Capital
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            The Annual Benefit in Value of Human Capital as a % of compensation
            is a summary of the impact of the workplace on three workforce
            performance metrics: Retention, Amount of work completed, and
            Accuracy of Work.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Both the Annual benefit, and these three metrics, are calculated as
            a percentage (and a dollar amount) of the annual spend on
            compensation for the workforce being modeled. Annual compensation is
            itself an indicator of the financial value of the workforce human
            capital. Keep in mind that these percentages can be negative as well
            as positive.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            For instance, it is possible to have a workspace in which people
            work very fast, but that engagement is low and replacement costs are
            high.
          </StyledText>
        </Info>
      );
    case 'stress':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Employee stress
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Stress is a leading indicator of future workforce performance,
            engagement, and health.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Our model estimates your workforce stress level (frequency of
            physical stress symptoms) against a benchmark average stress level.
            This is the estimated percentage above or below the benchmark stress
            symptom frequency.
          </StyledText>
        </Info>
      );
    case 'retention':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Employee retention
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Employee retention is a metric related to workforce operational
            costs.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            The dollar figure shown is the incremental financial impact (either
            positive or negative) of the space on employee retention, and
            ultimately, replacement costs.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A positive value represents cost savings (cost avoidance). In this
            case, the workplace is reducing employee replacement costs due to
            lower turnover.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A negative retention impact will show a negative dollar value, which
            is the incremental additional cost of the workplace on employee
            replacement costs due to higher turnover.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            How do we get the dollar amount? The model first calculates the
            impact of the space on turnover cost (or cost avoidance) as a
            percentage of the annual spend on compensation. Remember, workforce
            compensation is the financial value of the human capital of your
            workforce.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            The model displays the dollar value of that percentage of annual
            compensation.
          </StyledText>
        </Info>
      );
    case 'workAccuracy':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Work errors/accuracy
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Work accuracy is a work efficiency metric. It is an estimate of the
            impact of the workplace on time spent revising work products, fixing
            errors, reworking ideas.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            The dollar figure shown is the incremental financial impact (either
            positive or negative) of the space on the costs (or cost savings)
            from time spent correcting work errors or revising work.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A positive dollar value shows that the workspace design is helping
            the workforce reduce annual time spent correcting errors (compared
            to average benchmark). This time reduction has a financial value
            based on compensation costs per hour.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A negative dollar value shows that the workspace design is causing a
            higher rate of errors and revisions (compared to average benchmark)
            and thus more time and cost. This time increase is calculated based
            on compensation costs per hour.
          </StyledText>
        </Info>
      );
    case 'workQuantity':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Work quantity
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            Work quantity is a metric estimating the percentage difference
            (compared to an average benchmark) in the number of work processes
            completed annually - the amount of completed work processes.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            This is a workforce effectiveness or productivity metric. It is an
            estimate of the financial impact of differences in the number of
            completed work processes each year compared to a benchmark.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A positive dollar value shows that the workspace design is helping
            the workforce complete more work processes annually (compared to
            average benchmark). This productivity improvement has a financial
            value based on compensation costs per hour.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            A negative dollar value shows that the workspace design is reducing
            the number of work processes completed annually (compared to average
            benchmark). This productivity decrement has a financial value based
            on compensation costs per hour.
          </StyledText>
        </Info>
      );
    case 'paybackAmount':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Annual Payback
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            These metrics indicate the incremental or decremental ROI compared
            to a benchmark. This metric is the percentage of annual payback,
            against a project or annual facilities costs.
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            This percentage is the combined total financial impact of retention
            cost or cost avoidance, work errors, and amount of work completed.
            This number is shown at the top of the dashboard under the heading
            “Your model results.”
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            If this number is a negative, there is a negative impact of your
            space on the value of workforce human capital (which is measured as
            the amount of compensation paid).
          </StyledText>
        </Info>
      );

    case 'paybackTime':
      return (
        <Info visible={isOpen} onClose={onClose}>
          <StyledText bold color={Colors.primary} size="m" lineHeight={1.7}>
            Payback Time in Years
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            These metrics indicate the number of years required to achieve a
            total return on investment (in this case either for annual facility
            cost or project cost).
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            This number used to calculate this metric is the combined total
            financial impact of retention cost or cost avoidance, work errors,
            and amount of work completed. This number is shown at the top of the
            dashboard under the heading “Your model results.”
          </StyledText>
          <StyledText color={Colors.black} size="s" lineHeight={1.7}>
            If this number is a negative, there is a negative impact of your
            space on the value of workforce human capital (which is measured as
            the amount of compensation paid).
          </StyledText>
        </Info>
      );
    default:
      return null;
  }
};

PodModal.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
};

PodModal.defaultProps = {
  isOpen: false,
  type: undefined,
};

export default PodModal;
