import React from 'react';

import styled from 'styled-components';

import { Text, Link, Layout } from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

import { RegisterForm } from '../../components';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const Register = ({ register }) => {
  return (
    <Layout
      render={
        <>
          <StyledText size="l" bold align="center">
            Create new account
          </StyledText>
          <RegisterForm
            onSubmit={({ name, email, company, plainPassword }) =>
              register({ name, email, company, plainPassword })
            }
          />
          <Text align="center" color={Colors.anakiwa}>
            Already have an account?{' '}
            <Link href="/login" color={Colors.anakiwa}>
              Log in
            </Link>
          </Text>
        </>
      }
    />
  );
};

export default Register;
