import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import {
  Modal,
  Carousel,
  Card,
  Text,
  Link,
  Video,
  Layout,
} from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

import whoWeAre from '@humanspace/assets/images/who_we_are.svg';
import whatWeDo from '@humanspace/assets/images/what_we_do.svg';
import secretSauce from '@humanspace/assets/images/secret_sauce.svg';

import { LoginForm } from '../../components';
import useCopyToClipboard from '../../../shared/hooks/useCopyToClipboard';
import { toast } from 'react-toastify';

const HaveAccount = styled(Text)`
  margin-top: ${Spacing.xxxxxl};
`;

const ActionsLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing.xxs};
`;

const Separator = styled(Text)`
  margin: 0 ${Spacing.xs};
`;

const LinkText = styled.span`
  margin-left: ${Spacing.xxs};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Login = ({ login }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  useEffect(() => {
    if (!localStorage.getItem('userHasClickOnLogin')) setModalIsVisible(true);
  }, []);

  useEffect(() => {
    if (isCopied) toast.success('Email copied');
  }, [isCopied]);

  return (
    <>
      <Layout
        render={
          <>
            <LoginForm
              onSubmit={({ email, password }) => login(email, password)}
            />
            <Link href="/request-password" color={Colors.anakiwa}>
              Forgot your password?
            </Link>
            <HaveAccount align="center" color={Colors.anakiwa}>
              Request an account by emailing mike.oneill@humanspacesolutions.com
            </HaveAccount>
            <ActionsLayout>
              <StyledLink
                href="mailto:mike.oneill@humanspacesolutions.com?subject=Request%20an%20accoun"
                target="_blank"
                color={Colors.anakiwa}>
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke={Colors.anakiwa}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                  <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                </svg>
                <LinkText>Compose</LinkText>
              </StyledLink>
              <Separator>•</Separator>
              <StyledLink
                onClick={() => {
                  copyToClipboard('mike.oneill@humanspacesolutions.com');
                }}
                color={Colors.anakiwa}>
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke={Colors.anakiwa}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
                <LinkText>Copy</LinkText>
              </StyledLink>
            </ActionsLayout>
            <Modal
              backgroundColor={Colors.secondary}
              withoutHeader
              isVisible={modalIsVisible}
              render={
                <Carousel
                  onSkip={() => {
                    setModalIsVisible(false);
                    localStorage.setItem('userHasClickOnLogin', true);
                  }}>
                  <Card
                    element={
                      <Video
                        loop
                        autoplay
                        playsinline
                        muted
                        layerColor={Colors.secondary}
                        controls={false}
                        source="/videos/intro.mp4"
                      />
                    }
                  />
                  <Card
                    background={whoWeAre}
                    title="Who we are"
                    description="HumanSpace™ uses AI technology to help you create a workplace strategy that improves the user experience and workforce performance."
                  />
                  <Card
                    background={whatWeDo}
                    title="What we do"
                    description="We help you understand which workplace capabilities are most important to workforce performance, so you make the most of your spend on workplace."
                  />
                  <Card
                    background={secretSauce}
                    title="Our secret sauce..."
                    description="We use predictive analytics on a growing database of:- 1 million+ surveys- 12 industries- 120 countries- 50+ quantitative studies"
                  />
                </Carousel>
              }></Modal>
          </>
        }
      />
    </>
  );
};

export default Login;
