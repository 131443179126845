import React from 'react';
import styled from 'styled-components';

import {
  percentageFormat,
  priceFormat,
  numberFormat,
  moneyFormat,
} from '../../utils/formatters';

import Text from '../Text';
import Icon from '../Icon';
import { Spacing } from '../../foundations';

import Pod from './Pod';
import PodModal from './PodModal';

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: ${Spacing.s};
  max-width: 480px;
  margin: 0 auto;
`;

const Description = styled.div`
  margin-top: ${Spacing.l};
`;

const Block = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
`;

const Result = styled.div`
  margin-right: ${Spacing.xs};
  max-width: 33.3%;

  &:last-child {
    margin-right: 0;
  }
`;

const ElementNumber = styled.div`
  margin-bottom: ${Spacing.s};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StressNumber = styled.div`
  display: flex;
  align-items: center;
`;

const PodContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing.s};
`;

const PodDescription = styled(Text)`
  width: 85%;
`;

const calculateAnnual = (cost = 0, capitalBenefit = 0) => ({
  annualROI: (capitalBenefit * 100) / cost / 100,
});

const Dashboard = ({
  results,
  isNegative,
  isStressNegative,
  onInfoClick,
  simplified,
  ...props
}) => {
  const { annualROI } = calculateAnnual(
    results.cost,
    results.humanCapitalBenefit,
  );
  const [isPodModalOpen, setIsPodModalOpen] = React.useState(false);
  const [typeOfModal, setTypeOfModal] = React.useState(null);

  return (
    <>
      <Container {...props}>
        {!simplified && (
          <Pod
            title="Your project information"
            content={
              <Block>
                <Result>
                  <Text align="center" size="m" bold>
                    {numberFormat(results.totalEmployees)}
                  </Text>
                  <Text align="center" size="xxs">
                    Total Employees
                  </Text>
                </Result>
                <Result>
                  <Text align="center" size="m" bold>
                    {priceFormat(results.totalAnnualCompensation)}
                  </Text>
                  <Text align="center" size="xxs">
                    Annual compensation
                  </Text>
                </Result>
                <Result>
                  <Text align="center" size="m" bold>
                    {numberFormat(results.squareFeet)}
                  </Text>
                  <Text align="center" size="xxs">
                    Space sq. ft
                  </Text>
                </Result>
                <Result>
                  <Text align="center" size="m" bold>
                    {numberFormat(results.cost)}
                  </Text>
                  <Text align="center" size="xxs">
                    Cost
                  </Text>
                </Result>
              </Block>
            }
          />
        )}

        <Pod
          onClick={() => {
            setTypeOfModal('annualBenefit');
            setIsPodModalOpen(true);
          }}
          title="Your model results"
          content={
            <React.Fragment>
              <Text size="xxxl" align="center" bold>
                {percentageFormat(
                  results.humanCapitalBenefitAsPercentOfCompensation,
                )}
              </Text>

              {!simplified && (
                <Text size="l" align="center">
                  {moneyFormat(results.humanCapitalBenefit, 0)}
                </Text>
              )}
              <Description>
                <Footer>
                  <PodDescription size="xxs" align="left">
                    Annual impact of space on value of human capital – as a
                    percentage of annual compensation and dollar value
                  </PodDescription>
                  <Icon icon="vector" />
                </Footer>
              </Description>
            </React.Fragment>
          }
        />

        <PodContainer>
          <Pod
            onClick={() => {
              setTypeOfModal('stress');
              setIsPodModalOpen(true);
            }}
            icon="retention"
            title="Stress"
            content={
              <React.Fragment>
                <ElementNumber>
                  <StressNumber>
                    {!isStressNegative && (
                      <Text size="l" bold>
                        +
                      </Text>
                    )}
                    <Text size="l" bold>
                      {percentageFormat(results.stressPercentageChange)}
                    </Text>
                  </StressNumber>
                </ElementNumber>
                <Footer>
                  <PodDescription size="xxs">
                    Difference in employee stress from average
                  </PodDescription>
                  <Icon icon="vector" />
                </Footer>
              </React.Fragment>
            }
          />

          <Pod
            onClick={() => {
              setTypeOfModal('retention');
              setIsPodModalOpen(true);
            }}
            icon="retention"
            title="Retention"
            content={
              <React.Fragment>
                <ElementNumber>
                  <Text size="l" bold>
                    {moneyFormat(results.retentionRateAnnualSavings, 0)}
                  </Text>
                </ElementNumber>
                <Footer>
                  <PodDescription size="xxs">
                    Financial value of employee replacement costs
                  </PodDescription>
                  <Icon icon="vector" />
                </Footer>
              </React.Fragment>
            }
          />

          <Pod
            onClick={() => {
              setTypeOfModal('workAccuracy');
              setIsPodModalOpen(true);
            }}
            icon="accuracy"
            title="Work accuracy"
            content={
              <React.Fragment>
                <ElementNumber>
                  <Text size="l" bold>
                    {moneyFormat(results.percentageOfReworkAnnualSavings, 0)}
                  </Text>
                </ElementNumber>
                <Footer>
                  <PodDescription size="xxs">
                    Financial impact of work errors
                  </PodDescription>
                  <Icon icon="vector" />
                </Footer>
              </React.Fragment>
            }
          />

          <Pod
            onClick={() => {
              setTypeOfModal('workQuantity');
              setIsPodModalOpen(true);
            }}
            icon="retention"
            title="Work quantity"
            content={
              <React.Fragment>
                <ElementNumber>
                  <Text size="l" bold>
                    {moneyFormat(results.processTimeAnnualSavings, 0)}
                  </Text>
                </ElementNumber>

                <Footer>
                  <PodDescription size="xxs">Work process time</PodDescription>
                  <Icon icon="vector" />
                </Footer>
              </React.Fragment>
            }
          />
          {!simplified && (
            <React.Fragment>
              <Pod
                onClick={() => {
                  setTypeOfModal('paybackAmount');
                  setIsPodModalOpen(true);
                }}
                icon="accuracy"
                title="Payback amount"
                content={
                  <React.Fragment>
                    <ElementNumber>
                      <Text size="l" bold>
                        {percentageFormat(annualROI)}
                      </Text>
                    </ElementNumber>

                    <Footer>
                      <PodDescription size="xxs">
                        Annual ROI on project / facility cost
                      </PodDescription>
                      <Icon icon="vector" />
                    </Footer>
                  </React.Fragment>
                }
              />

              <Pod
                onClick={() => {
                  setTypeOfModal('paybackTime');
                  setIsPodModalOpen(true);
                }}
                icon="process-time"
                title="Payback time"
                content={
                  <React.Fragment>
                    <ElementNumber>
                      <Text size="l" bold>
                        {results.yearsToRecoup}
                      </Text>
                    </ElementNumber>

                    <Footer>
                      <PodDescription size="xxs">
                        Years to recoup costs
                      </PodDescription>
                      <Icon icon="vector" />
                    </Footer>
                  </React.Fragment>
                }
              />
            </React.Fragment>
          )}
        </PodContainer>
      </Container>
      <PodModal
        type={typeOfModal}
        isOpen={isPodModalOpen}
        onClose={() => setIsPodModalOpen(false)}
      />
    </>
  );
};

export default Dashboard;
