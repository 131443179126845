import React from 'react';
import styled from 'styled-components';

import { useMediaQueryContext } from '../MediaQueryProvider';
import Text from '../Text';
import { Spacing, Colors } from '../../foundations';

const getBackground = ({ active }) =>
  active ? Colors.primary : Colors.secondary;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  z-index: 3;
`;

const Element = styled.button`
  background: ${getBackground};
  padding: ${Spacing.s};
  flex: 1;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid ${Colors.colorShadow};
  outline: 0;
  margin: 0;
`;

const Breadcrumb = ({ elements, ...props }) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    isResponsive && (
      <Container {...props}>
        {elements &&
          elements.map((element, index) => (
            <Element
              key={index}
              active={element.active}
              onClick={event => {
                event.preventDefault();
                element.onClick();
              }}>
              <Text align="center" size="xxs">
                {element.text}
              </Text>
            </Element>
          ))}
      </Container>
    )
  );
};

export default Breadcrumb;
