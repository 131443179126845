import React from 'react';
import styled, { css } from 'styled-components';

import { Colors, Spacing } from '../../foundations';
import { Text } from '../../components';
import { filterObject } from '../../utils/transformers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  flex: 1;

  ${props =>
    props.hasErrors &&
    css`
      border: 2px solid ${Colors.lightPink};
    `}
`;

const ErrorWrapper = styled.div`
  margin-top: ${Spacing.s};
`;

const errorGenerator = errors => {
  const arrayOfErrors = [].concat.apply([], Object.values(errors));
  const errorString = arrayOfErrors
    .join(' and ')
    .toLowerCase()
    .replace(/^\w/, firstLetter => firstLetter.toUpperCase());

  return (
    arrayOfErrors.length > 0 && (
      <Text size="xs" color={Colors.lightPink}>
        Error: {errorString}
      </Text>
    )
  );
};

const InputGroup = ({ children, meta, errors, ...props }) => {
  const arrayLength = React.Children.toArray(children).length;
  const filteredErrors = filterObject(errors, (error, key) => {
    return error !== undefined && meta[key] && meta[key].touched;
  });

  const errorsKeys = Object.keys(filteredErrors);
  const hasErrors = errorsKeys && errorsKeys.length > 0;

  return (
    <React.Fragment>
      <Wrapper hasErrors={hasErrors} {...props}>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            isLast: arrayLength === index + 1,
            isFirst: index === 0,
          }),
        )}
      </Wrapper>
      <ErrorWrapper>{errorGenerator(filteredErrors)}</ErrorWrapper>
    </React.Fragment>
  );
};

export default InputGroup;
