import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';
import Icon from '../Icon';

const Overlay = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.4s;

  ${props =>
    !props.visible &&
    `
    opacity: 0;
    pointer-events: none;

  `}
`;

const Wrapper = styled.ul`
  margin: 0;
  padding: ${Spacing.m};
  background: ${Colors.verticalGradient};
  list-style: none;
  width: 80%;
  max-width: 350px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
  transform: ${props =>
    props.visible ? 'translate3d(0px, 0, 0)' : 'translate3d(-100%, 0, 0)'};
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
`;

const MenuItem = styled.li`
  margin: ${Spacing.l} 0;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  mix-blend-mode: overlay;
`;

const ConditionalWrapper = ({ linkElement, children, to, ...props }) =>
  linkElement ? (
    React.createElement(linkElement, { children, to, ...props })
  ) : (
    <a {...props} href={to}>
      {children}
    </a>
  );

const SideMenu = ({ linkElement, onClose, visible }) => {
  const location = useLocation();
  const isProjectLocation = location.pathname === '/projects';

  return (
    <React.Fragment>
      <Overlay onClick={onClose} visible={visible} />
      <Wrapper visible={visible}>
        <Header>
          <CloseButton onClick={onClose}>
            <Icon icon="close" />
          </CloseButton>
        </Header>
        {!isProjectLocation && (
          <MenuItem>
            <ConditionalWrapper linkElement={linkElement} to="/projects">
              <StyledText size="xl">Projects</StyledText>
            </ConditionalWrapper>
          </MenuItem>
        )}

        <MenuItem>
          <ConditionalWrapper linkElement={linkElement} to="/logout">
            <StyledText size="xl">Log out</StyledText>
          </ConditionalWrapper>
        </MenuItem>
      </Wrapper>
    </React.Fragment>
  );
};

SideMenu.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  linkElement: PropTypes.object,
};

SideMenu.defaultProps = {
  onClose: () => {},
  visible: false,
  linkElement: undefined,
};

export default SideMenu;
