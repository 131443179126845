import React, { useState } from 'react';
import styled from 'styled-components';

import { Spacing, Colors } from '../../foundations';
import Text from '../Text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.s} ${Spacing.m};
  background-color: ${Colors.white};
  box-shadow: 0px 20px 40px rgba(34, 6, 59, 0.4);
`;

const ViewLink = styled(Text)`
  cursor: pointer;
`;

const ViewAccordion = ({ children }) => {
  const [isOpen, setOpen] = useState(false);

  return isOpen ? (
    children
  ) : (
    <Wrapper>
      <Text color={Colors.grey700}>
        Edit the data associated with this role
      </Text>
      <ViewLink
        size="xs"
        color={Colors.primary}
        bold
        onClick={() => setOpen(!isOpen)}>
        View
      </ViewLink>
    </Wrapper>
  );
};

export default ViewAccordion;
