import { useMediaQueryContext } from '../MediaQueryProvider';

// const ReadMoreDeprecatedFunctionality = () => (
//   <div>
//     {isReadMoreClicked
//       ? children
//       : truncate(children, {
//           length: 108,
//         })}
//     <StyledLink
//       onClick={() => setReadMoreAsClicked(!isReadMoreClicked)}
//       color={Colors.anakiwa}>
//       {isReadMoreClicked ? 'Read less' : 'Read more'}
//     </StyledLink>
//   </div>
// );

const ReadMore = ({ children }) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  // const [isReadMoreClicked, setReadMoreAsClicked] = React.useState(false);

  return isResponsive ? children : children;
};

export default ReadMore;
