import { connect } from 'react-redux';

import actions from '../../duck/actions';

import ProjectRating from './ProjectRating';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  project: state.projects.project,
});

const mapDispatchToProps = dispatch => ({
  getCapabilities: (projectId, ratingConfig) =>
    dispatch(actions.getCapabilities(projectId, ratingConfig, null, false)),
  getModel: (projectId, ratingConfig) =>
    dispatch(actions.getModel(projectId, ratingConfig)),
  getProject: projectId => dispatch(actions.getProject(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRating);
