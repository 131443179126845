import { reduxForm, getFormSyncErrors, getFormMeta } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired, isEmail, minLength } from '@humanspace/utils/validations';

import LoginForm from './LoginForm';

const form = 'LoginForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  formErrors: getFormSyncErrors(form)(state),
  formMeta: getFormMeta(form)(state),
});

const validate = ({ email, password }) => ({
  email: isRequired(email, 'email') || isEmail(email),
  password:
    isRequired(password, 'password') || minLength(8)(password, 'password'),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(LoginForm);

export default connect(mapStateToProps, null)(DecoratedForm);
