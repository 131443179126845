import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Input, Button, InputGroup, Text, Link } from '@humanspace/components';

import { Spacing, Colors } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-bottom: ${Spacing.xl};
  margin-left: auto;
  margin-right: auto;
`;

const Terms = styled(Text)`
  text-align: center;
  margin-top: ${Spacing.xl};
  margin-bottom: ${Spacing.xl};
  max-width: 238px;
  margin-left: auto;
  margin-right: auto;
`;

const RegisterForm = ({
  handleSubmit,
  submitting,
  invalid,
  isLoading,
  formErrors,
  formMeta,
}) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputGroup meta={formMeta} errors={formErrors}>
        <Field component={Input} name="name" placeholder="Name" />
        <Field
          component={Input}
          type="email"
          name="email"
          placeholder="Email"
        />
        <Field component={Input} name="company" placeholder="Company" />
        <Field
          component={Input}
          type="password"
          name="plainPassword"
          placeholder="Password"
        />
      </InputGroup>
      <Terms size="xs" color={Colors.anakiwa}>
        By signing up you agree to our{' '}
        <Link
          size="xs"
          color={Colors.anakiwa}
          href="https://humanspacesolutions.com/privacy-policy/"
          target="_blank">
          Terms of use and Privacy Policy
        </Link>
      </Terms>

      <StyledButton
        type="submit"
        isLoading={isLoading}
        disabled={submitting || invalid}>
        Sign up
      </StyledButton>
    </StyledForm>
  );
};

export default RegisterForm;
