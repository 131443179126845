import { reduxForm, getFormSyncErrors, getFormMeta } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired, isEmail } from '@humanspace/utils/validations';

import RequestPasswordForm from './RequestPasswordForm';

const form = 'RequestPasswordForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  formErrors: getFormSyncErrors(form)(state),
  formMeta: getFormMeta(form)(state),
});

const validate = ({ email }) => ({
  email: isRequired(email, 'email') || isEmail(email),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RequestPasswordForm);

export default connect(mapStateToProps, null)(DecoratedForm);
