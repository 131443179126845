import React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../../foundations';
import { Text, Icon } from '../..';

const Wrapper = styled.button`
  background: ${Colors.background};
  border-radius: 10px;
  padding: ${Spacing.s};
  border: 0;
  box-shadow: none;

  ${props => props.onClick && `cursor: pointer;`}
`;

const Header = styled.header`
  margin-bottom: ${Spacing.s};
  opacity: 0.5;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${Spacing.xxs};
  width: 20px;
  height: 20px;
`;

const Pod = ({ title, icon, content, ...props }) => (
  <Wrapper {...props}>
    <Header>
      {icon && <StyledIcon icon={icon} />}
      <Text uppercase size="xxs">
        {title}
      </Text>
    </Header>

    {content}
  </Wrapper>
);

export default Pod;
