import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing } from '../../foundations';

import Icon from '../Icon';
import SideMenu from '../SideMenu';
import Touchable from '../Touchable';
import Text from '../Text';
import { useMediaQueryContext } from '../MediaQueryProvider';

const getBlockPadding = ({ isFirstItem, isLastItem }) => {
  if (isLastItem) return `padding 0 0 0 ${Spacing.xs}`;
  if (isFirstItem) return `padding 0 ${Spacing.xs} 0 0`;
  return `padding: 0 ${Spacing.xs};`;
};

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${Spacing.s} 0;
  min-height: 136px;
`;

const StyledIcon = styled(Icon)`
  width: ${props => (props.isResponsive ? '120px' : '190px')};
`;

const Block = styled.div`
  ${getBlockPadding}
`;

const StyledDiv = styled.div`
  width: 30px;
`;

const Header = ({ title, back, onBack, linkElement, inverse, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <>
      <Wrapper {...props}>
        <Block isFirstItem>
          {back ? (
            <Touchable onClick={onBack}>
              <Icon icon="back" />
            </Touchable>
          ) : (
            <Touchable onClick={() => setIsVisible(true)}>
              <Icon icon={inverse ? 'menu-inverse' : 'menu'} />
            </Touchable>
          )}
        </Block>

        <Block>
          {title ? (
            <Text bold align="center" size="l">
              {title}
            </Text>
          ) : (
            <StyledIcon
              isResponsive={isResponsive}
              icon={inverse ? 'full-logo-inverse' : 'full-logo'}
            />
          )}
        </Block>

        <Block isLastItem>
          <StyledDiv />
        </Block>
      </Wrapper>

      <SideMenu
        linkElement={linkElement}
        visible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  back: PropTypes.bool,
  onBack: PropTypes.func,
  linkElement: PropTypes.object,
};

Header.defaultProps = {
  title: undefined,
  back: false,
  onBack: undefined,
  linkElement: undefined,
};

export default Header;
