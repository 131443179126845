const authAction = action => `AUTH/${action}`;

const LOGIN = authAction('LOGIN');
const LOGIN_SUCCESS = authAction('LOGIN_SUCCESS');
const LOGIN_FAILURE = authAction('LOGIN_FAILURE');

const REGISTER = authAction('REGISTER');
const REGISTER_SUCCESS = authAction('REGISTER_SUCCESS');
const REGISTER_FAILURE = authAction('REGISTER_FAILURE');

const PASSWORD_REQUEST = authAction('PASSWORD_REQUEST');
const PASSWORD_REQUEST_SUCCESS = authAction('PASSWORD_REQUEST_SUCCESS');
const PASSWORD_REQUEST_FAILURE = authAction('PASSWORD_REQUEST_FAILURE');

const PASSWORD_RESET = authAction('PASSWORD_RESET');
const PASSWORD_RESET_SUCCESS = authAction('PASSWORD_RESET_SUCCESS');
const PASSWORD_RESET_FAILURE = authAction('PASSWORD_RESET_FAILURE');

export default {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  PASSWORD_REQUEST,
  PASSWORD_REQUEST_SUCCESS,
  PASSWORD_REQUEST_FAILURE,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
};
