import { reduxForm, getFormSyncErrors, getFormMeta } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired, isEmail, minLength } from '@humanspace/utils/validations';

import RegisterForm from './RegisterForm';

const form = 'RegisterForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  formErrors: getFormSyncErrors(form)(state),
  formMeta: getFormMeta(form)(state),
});

const validate = ({ name, email, plainPassword }) => ({
  name: isRequired(name, 'name'),
  email: isRequired(email, 'email') || isEmail(email),
  plainPassword:
    isRequired(plainPassword, 'password') ||
    minLength(8)(plainPassword, 'password'),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RegisterForm);

export default connect(mapStateToProps, null)(DecoratedForm);
