import { combineReducers } from 'redux';
import types from './types';

const projects = (state = [], action) => {
  switch (action.type) {
    case types.GET_PROJECTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const project = (
  state = {
    cost: undefined,
    workPlacePercentageChange: undefined,
    groupSpacePercentageChange: undefined,
    brandPercentageChange: undefined,
    interiorDesignPercentageChange: undefined,
    activityPercentageChange: undefined,
    changeManagementPercentageChange: undefined,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_SURVEY_DETAILS_SUCCESS:
      return action.payload.answersCapabilitiesAverages;
    case types.GET_PROJECT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const roleCharacteristics = (
  state = [
    {
      role: 'executive',
      characteristics: {
        averageCostReplaceEmployeePercentCompensation: 2.13,
        averageCostPerHourOfRework: 386,
        averageAnnualHoursOfWork: 1900,
        averageRetentionRate: 0.92,
        averagePercentOfProcessRework: 0.05,
        averagePercentageOfTimeSpentInProcessWork: 0.5,
        averageProcessTimePerEmployee: 1.0,
      },
    },
    {
      role: 'manager',
      characteristics: {
        averageCostReplaceEmployeePercentCompensation: 1.2,
        averageCostPerHourOfRework: 70,
        averageAnnualHoursOfWork: 1900,
        averageRetentionRate: 0.9,
        averagePercentOfProcessRework: 0.05,
        averagePercentageOfTimeSpentInProcessWork: 0.3,
        averageProcessTimePerEmployee: 1.25,
      },
    },
    {
      role: 'technical',
      characteristics: {
        averageCostReplaceEmployeePercentCompensation: 1.4,
        averageCostPerHourOfRework: 60,
        averageAnnualHoursOfWork: 1800,
        averageRetentionRate: 0.9,
        averagePercentOfProcessRework: 0.15,
        averagePercentageOfTimeSpentInProcessWork: 0.5,
        averageProcessTimePerEmployee: 2.25,
      },
    },
    {
      role: 'administrative',
      characteristics: {
        averageCostReplaceEmployeePercentCompensation: 1.0,
        averageCostPerHourOfRework: 30,
        averageAnnualHoursOfWork: 1900,
        averageRetentionRate: 0.9,
        averagePercentOfProcessRework: 0.15,
        averagePercentageOfTimeSpentInProcessWork: 0.5,
        averageProcessTimePerEmployee: 1.0,
      },
    },
  ],
  action,
) => {
  switch (action.type) {
    case types.GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const results = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_MODEL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const capabilities = (state = [], action) => {
  switch (action.type) {
    case types.GET_CAPABILITIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const benchmarkData = (state = {}, action) => {
  switch (action.type) {
    case types.GET_BENCHMARK_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const benchmarkModel = (state = {}, action) => {
  switch (action.type) {
    case types.GET_BENCHMARK_MODEL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const modelDetails = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MODEL_DETAILS_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const surveys = (state = [], action) => {
  switch (action.type) {
    case types.GET_SURVEYS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialSurveyDetailsState = null;

const surveyDetails = (state = initialSurveyDetailsState, action) => {
  switch (action.type) {
    case types.GET_SURVEY_DETAILS_SUCCESS:
      return action.payload;
    case types.RESET_SURVEY_DETAILS:
      return initialSurveyDetailsState;
    default:
      return state;
  }
};

const reducer = combineReducers({
  projects,
  project,
  results,
  capabilities,
  roleCharacteristics,
  benchmarkData,
  benchmarkModel,
  modelDetails,
  surveys,
  surveyDetails,
});

export default reducer;
