import { connect } from 'react-redux';

import actions from '../../duck/actions';

import Register from './Register';

const mapDispatchToProps = dispatch => ({
  register: ({ name, plainPassword, email, company }) =>
    dispatch(actions.register({ name, plainPassword, email, company })),
});

export default connect(null, mapDispatchToProps)(Register);
