import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Input, Button, InputGroup } from '@humanspace/components';

import { Spacing } from '@humanspace/foundations';

const StyledForm = styled.form`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.xl};
  margin-bottom: ${Spacing.xl};
  margin-left: auto;
  margin-right: auto;
`;

const LoginForm = ({
  handleSubmit,
  submitting,
  invalid,
  formErrors,
  formMeta,
  isLoading,
}) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputGroup meta={formMeta} errors={formErrors}>
        <Field component={Input} name="email" placeholder="Email" />
        <Field
          component={Input}
          type="password"
          name="password"
          placeholder="Password"
        />
      </InputGroup>
      <StyledButton
        type="submit"
        isLoading={isLoading}
        disabled={submitting || invalid}>
        Log in
      </StyledButton>
    </StyledForm>
  );
};

export default LoginForm;
