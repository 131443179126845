export const percentageFormat = (value = 0) =>
  `${Number(value * 100).toFixed(2)}%`;

export const priceFormat = (value = 0) =>
  ((Math.log10(value) / 3) | 0) === 0
    ? `$ ${Number(value).toFixed(3)}`
    : `$ ${Number(
        (value / Math.pow(10, ((Math.log10(value) / 3) | 0) * 3)).toFixed(1),
      )} ${['', 'K', 'M', 'B', 'T'][(Math.log10(value) / 3) | 0]}`;

export const moneyFormat = (amount, decimalCount = 2) => {
  try {
    return (
      amount &&
      amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimalCount,
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const numberFormat = (value = 0) => Math.floor(value);

export const hoursFormat = (value = 0) => `${value} hrs`;
