import { connect } from 'react-redux';

import actions from '../../duck/actions';

import ResetPassword from './ResetPassword';

const mapDispatchToProps = dispatch => ({
  passwordReset: ({ newPassword, token }) =>
    dispatch(actions.passwordReset({ newPassword, token })),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
