import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';

import {
  Text,
  Breadcrumb,
  Loader,
  Layout,
  ReadMore,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import CreateSurveyForm from '../../components/CreateSurveyForm';

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const CreateSurvey = ({ isLoading, createSurvey }) => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <React.Fragment>
      {id && isLoading ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          breadcrumb={[
            {
              text: 'Surveys',
              onClick: () => history.push(`/projects/${id}/surveys`),
              active: true,
            },
          ]}
          multiple
          hasNavigation
          linkElement={Link}
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Create a web link for your survey.
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  The Web Link collector lets you share a survey link and
                  collect responses. You can share your survey link
                  anywhere—like on social media, in an email, using a QR code,
                  or as a link within a .pdf newsletter.
                  <br />
                  <br />
                  Use the “Insert name” field on the right to name your
                  collector.
                </ReadMore>
              </StyledText>
            </React.Fragment>
          }
          rightColumn={
            <React.Fragment>
              <CreateSurveyForm
                onSubmit={values => {
                  const { surveyName } = values;
                  createSurvey(id, surveyName);
                }}
              />
              {id && (
                <StyledBreadcrumb
                  elements={[
                    {
                      text: 'Surveys',
                      onClick: () => history.push(`/projects/${id}/surveys`),
                      active: true,
                    },
                  ]}
                />
              )}
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

CreateSurvey.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  createSurvey: PropTypes.func.isRequired,
};

export default CreateSurvey;
