import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Text,
  Button,
  Capability,
  CapabilityInput,
  Grid,
  Modal,
  useMediaQueryContext,
} from '@humanspace/components';
import { Colors, Spacing } from '@humanspace/foundations';

import CapabilityInfoModal from '../CapabilityInfoModal';

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`;

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

const Title = styled(Text)`
  margin-bottom: ${Spacing.xxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};

  margin-bottom: ${props => (!props.isResponsive ? '0' : Spacing.xxxxxl)};
  margin-left: auto;
  margin-right: auto;

  margin-bottom: ${Spacing.xxxxxl};
`;

const modalTypes = [
  'workPlacePercentageChange',
  'groupSpacePercentageChange',
  'brandPercentageChange',
  'interiorDesignPercentageChange',
  'changeManagementPercentageChange',
  'activityPercentageChange',
];

const getModalConfig = (type, modelConfig, isInfoOpen, setIsInfoOpen) => {
  switch (type) {
    case 'workPlacePercentageChange':
      return {
        title: 'Individual Workspace',
        description:
          'How much better or worse than the average office, do your individual workspaces and private offices perform?',
        value: modelConfig.workPlacePercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Individual Workspace
            </Title>
            <CapabilityInput
              icon="workspace"
              title={modelConfig.workPlacePercentageChange}
              description="How much better or worse than the average office, do your
              individual workspaces and private offices perform?"
              name="workPlacePercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };
    case 'groupSpacePercentageChange':
      return {
        title: 'Social & group spaces',
        description:
          'How much better or worse than the average office space, do your social and meeting spaces perform?',
        value: modelConfig.groupSpacePercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Social & group spaces
            </Title>
            <CapabilityInput
              icon="social"
              title={modelConfig.groupSpacePercentageChange}
              description="How much better or worse than the average office space, do your
          social and meeting spaces perform?"
              name="groupSpacePercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };
    case 'brandPercentageChange':
      return {
        title: 'Brand & aesthetics',
        description:
          'How much better or worse than the average office does your office space reflect your brand and aesthetics?',
        value: modelConfig.brandPercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Brand & aesthetics
            </Title>
            <CapabilityInput
              icon="brand"
              title={modelConfig.brandPercentageChange}
              description="How much better or worse than the average office does your office
              space reflect your brand and aesthetics?"
              name="brandPercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };
    case 'interiorDesignPercentageChange':
      return {
        title: 'Space planning',
        description:
          'How much better or worse than average is your office space easy to navigate, and offer views within and outside of the building?',
        value: modelConfig.interiorDesignPercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Space planning
            </Title>
            <CapabilityInput
              icon="space"
              title={modelConfig.interiorDesignPercentageChange}
              description="How much better or worse than average is your office space easy to
              navigate, and offer views within and outside of the building?"
              name="interiorDesignPercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };
    case 'changeManagementPercentageChange':
      return {
        title: 'Communication & training',
        description:
          'How much better or worse than the average does your workplace provide programs communicating change, training for use of space and ergonomics?',
        value: modelConfig.changeManagementPercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Communication & training
            </Title>
            <CapabilityInput
              icon="communication"
              title={modelConfig.changeManagementPercentageChange}
              description="How much better or worse than the average does your workplace
              provide programs communicating change, training for use of space
              and ergonomics?"
              name="changeManagementPercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };

    case 'activityPercentageChange':
      return {
        title: 'Ambient Environment',
        description:
          'Rate how much better or worse than the average workplace, your office is at providing a good air quality, noise levels, and lighting?',
        value: modelConfig.activityPercentageChange,
        content: (
          <React.Fragment>
            <Title align="center" size="l" bold>
              Ambient Environment
            </Title>
            <CapabilityInput
              icon="user"
              title={modelConfig.activityPercentageChange}
              description="Rate how much better or worse than the average workplace, your office is at providing a good air quality, noise levels, and lighting?"
              name="activityPercentageChange"
              onInfo={() => setIsInfoOpen(true)}
            />
            <CapabilityInfoModal
              type={type}
              isOpen={isInfoOpen}
              onClose={() => setIsInfoOpen(false)}
            />
          </React.Fragment>
        ),
      };
    default:
      return null;
  }
};

const isUndefined = (object, property) =>
  typeof object[property] === 'undefined';

const RatingForm = props => {
  const {
    modelConfig,
    handleSubmit,
    submitting,
    invalid,
    isLoading,
    showContinueButton,
    formErrors,
    changeValue,
    isEdit,
    disabled,
  } = props;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  const modalConfig = getModalConfig(
    modalType,
    modelConfig,
    isInfoOpen,
    setIsInfoOpen,
  );

  const currentPosition = modalTypes.indexOf(modalType);
  const isLastPosition = currentPosition === 5;
  const isShowingContinueButton = isLastPosition || !showContinueButton;
  const allValuesAreZero = Object.values(modelConfig).every(item => item === 0);

  const isDisabled =
    submitting ||
    invalid ||
    !Object.values(formErrors).every(x => typeof x === 'undefined') ||
    allValuesAreZero;

  const handleOnClick = property => {
    if (disabled) return;

    if (isUndefined(modelConfig, property)) changeValue(property, 0);
    setModalType(property);
    setModalIsVisible(true);
  };

  const hasValue = modalType =>
    modelConfig[modalType] !== undefined && !allValuesAreZero;

  return (
    <StyledForm onSubmit={handleSubmit}>
      {!isEdit && isResponsive && (
        <StyledText>
          Start rating in any order you like, but make sure to provide a rating
          for each category.
        </StyledText>
      )}

      <Grid isResponsive={isResponsive} twoColumns>
        <Capability
          onClick={() => handleOnClick('workPlacePercentageChange')}
          hasValue={hasValue('workPlacePercentageChange')}
          value={modelConfig.workPlacePercentageChange}
          icon="workspace"
          title="Individual workspace"
        />
        <Capability
          onClick={() => handleOnClick('groupSpacePercentageChange')}
          hasValue={hasValue('groupSpacePercentageChange')}
          value={modelConfig.groupSpacePercentageChange}
          icon="social"
          title="Social & group spaces"
        />
        <Capability
          onClick={() => handleOnClick('brandPercentageChange')}
          hasValue={hasValue('brandPercentageChange')}
          value={modelConfig.brandPercentageChange}
          icon="brand"
          title="Brand & aesthetics"
        />
        <Capability
          onClick={() => handleOnClick('interiorDesignPercentageChange')}
          hasValue={hasValue('interiorDesignPercentageChange')}
          value={modelConfig.interiorDesignPercentageChange}
          icon="space"
          title="Space planning"
        />
        <Capability
          onClick={() => handleOnClick('changeManagementPercentageChange')}
          hasValue={hasValue('changeManagementPercentageChange')}
          value={modelConfig.changeManagementPercentageChange}
          icon="communication"
          title="Communication & training"
        />
        <Capability
          onClick={() => handleOnClick('activityPercentageChange')}
          hasValue={hasValue('activityPercentageChange')}
          value={modelConfig.activityPercentageChange}
          icon="user"
          title="Ambient environment"
        />
      </Grid>
      <StyledButton
        isResponsive={isResponsive}
        type="submit"
        isLoading={isLoading}
        disabled={isDisabled}>
        Next
      </StyledButton>

      <Modal
        currentValue={modalConfig && modalConfig.value}
        onBack={() => {
          setModalIsVisible(false);
          setIsInfoOpen(false);
        }}
        isVisible={modalIsVisible}
        leftColumn={
          <div>
            <Text size="xxl">{modalConfig && modalConfig.title}</Text>
            <Text>{modalConfig && modalConfig.description}</Text>
          </div>
        }
        rightColumn={
          <div>
            {modalConfig && modalConfig.content}
            <StyledButton
              backgroundColor={Colors.secondary}
              onClick={event => {
                event.preventDefault();
                const currentPosition = modalTypes.indexOf(modalType);
                !isShowingContinueButton
                  ? handleOnClick(modalTypes[currentPosition + 1])
                  : setModalIsVisible(false);
              }}>
              {isShowingContinueButton ? 'Save' : 'Next'}
            </StyledButton>
          </div>
        }
        render={
          <div>
            {modalConfig && modalConfig.content}
            <StyledButton
              backgroundColor={Colors.secondary}
              onClick={event => {
                event.preventDefault();
                const currentPosition = modalTypes.indexOf(modalType);
                !isShowingContinueButton
                  ? handleOnClick(modalTypes[currentPosition + 1])
                  : setModalIsVisible(false);
              }}>
              {isShowingContinueButton ? 'Save' : 'Next'}
            </StyledButton>
          </div>
        }
      />
    </StyledForm>
  );
};

RatingForm.propTypes = {
  modelConfig: PropTypes.shape({
    workPlacePercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    groupSpacePercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    brandPercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    interiorDesignPercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    activityPercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    changeManagementPercentageChange: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
};

RatingForm.defaultProps = {
  isLoading: false,
  isEdit: false,
};

export default React.memo(RatingForm);
