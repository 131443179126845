import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Text,
  Loader,
  Layout,
  ReadMore,
  Breadcrumb,
  useMediaQueryContext,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { RatingForm, SurveySection } from '../../components';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const SurveyRating = ({
  isLoading,
  getSurveyDetails,
  resetSurveyDetails,
  surveyDetails,
  getCapabilities,
  getProject,
}) => {
  const { id, surveyId } = useParams();
  const history = useHistory();

  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  useEffect(() => {
    getSurveyDetails(surveyId);
    return () => resetSurveyDetails();
  }, [history, surveyId, getSurveyDetails, resetSurveyDetails]);

  useEffect(() => {
    if (surveyDetails && surveyDetails.answersCount === 0) {
      toast.error('There are still no answers in this survey');
      history.push(`/projects/${id}/surveys`);
    }
  }, [surveyDetails, id, history]);

  return (
    <React.Fragment>
      {isLoading || !surveyDetails ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          breadcrumb={[
            {
              text: 'Workspace ratings',
              onClick: () =>
                history.push(`/projects/${id}/surveys/${surveyId}/details`),
              active: true,
            },
          ]}
          hasNavigation
          linkElement={Link}
          multiple
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Survey name: {surveyDetails.name}
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  This survey data collector has {surveyDetails.answersCount}{' '}
                  responses. The six buttons show the average rating for each
                  factor, from the data currently collected.
                  <br />
                  <br />
                  Use the "Next" button below the ratings buttons to generate
                  your model. You can always use the existing survey collector
                  link to gather more ratings.
                </ReadMore>
              </StyledText>
              {}
              {!isResponsive && (
                <SurveySection
                  withoutDescription
                  isResponsive={isResponsive}
                  id={id}
                />
              )}

              {id && surveyId && (
                <React.Fragment>
                  <StyledBreadcrumb
                    elements={[
                      {
                        text: 'Workspace ratings',
                        onClick: () =>
                          history.push(
                            `/projects/${id}/surveys/${surveyId}/details`,
                          ),
                        active: true,
                      },
                    ]}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          }
          rightColumn={
            <>
              <RatingForm
                disabled
                isEdit
                showContinueButton
                onSubmit={values => {
                  getProject(id);
                  getCapabilities(id, values, surveyId, true);
                }}
              />
              {isResponsive && (
                <SurveySection
                  withoutDescription
                  isResponsive={isResponsive}
                  id={id}
                />
              )}
            </>
          }
        />
      )}
    </React.Fragment>
  );
};

SurveyRating.propTypes = {
  getModel: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SurveyRating;
