import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Container,
  Slider,
  SliderTrack,
  ThumbRight,
  ThumbLeft,
  Value,
} from './styles';

const valueConfig = {
  min: -100,
  max: 100,
};

const MultiRangeSlider = ({ showValue, currentValue, benchmarkValue }) => {
  const { min, max } = valueConfig;

  return (
    <Wrapper>
      {showValue && (
        <Value bold size="m" align="center">
          {currentValue}
        </Value>
      )}
      <Container>
        <ThumbLeft
          disabled
          type="range"
          min={min}
          max={max}
          value={currentValue}
          className="thumb--left"
        />
        <ThumbRight
          disabled
          type="range"
          min={min}
          max={max}
          value={benchmarkValue}
          className="thumb--right"
        />

        <Slider>
          <SliderTrack />
          <SliderTrack />
        </Slider>
      </Container>
    </Wrapper>
  );
};

MultiRangeSlider.propTypes = {
  currentValue: PropTypes.number,
  benchmarkValue: PropTypes.number,
  showValue: PropTypes.bool,
};

MultiRangeSlider.defaultProps = {
  currentValue: -100,
  benchmarkValue: 100,
  showValue: false,
};

export default MultiRangeSlider;
