import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import {
  InputGroup,
  Input,
  Button,
  Accordion,
  ViewAccordion,
} from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';
import { AccordionInput } from '../../../../lib/components';
import RoleCharacteristicsForm from '../RoleCharacteristicsForm';
import {
  filterObjectByRole,
  removeRoleFromKey,
} from '../../utils/transformers';

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`;

const StyledInputGroup = styled(InputGroup)``;

const Wrapper = styled.div``;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${props => (!props.isResponsive ? '0' : Spacing.xxxxxl)};
  margin-left: auto;
  margin-right: auto;

  ${props =>
    !props.isResponsive &&
    `
position: absolute;
left: 50%;
transform: translate(-50%, 0);

&:after {
  position: absolute;
  content: '';
  bottom: -${Spacing.m};
  height: ${Spacing.m};
  width: 1px;
}

`}
`;

const projectRole = {
  ROLE_EXECUTIVE: 'executive',
  ROLE_MANAGER: 'manager',
  ROLE_TECHNICAL: 'technical',
  ROLE_ADMINISTRATIVE: 'administrative',
};

const normalizeRolesValues = (values, detailsFormValues) => {
  return [
    {
      role: projectRole.ROLE_EXECUTIVE,
      number: detailsFormValues.numberOfExecutives,
      characteristics: {
        ...removeRoleFromKey(
          filterObjectByRole(values, projectRole.ROLE_EXECUTIVE),
          projectRole.ROLE_EXECUTIVE,
        ),
      },
    },
    {
      role: projectRole.ROLE_MANAGER,
      number: detailsFormValues.numberOfManagers,
      characteristics: {
        ...removeRoleFromKey(
          filterObjectByRole(values, projectRole.ROLE_MANAGER),
          projectRole.ROLE_MANAGER,
        ),
      },
    },
    {
      role: projectRole.ROLE_TECHNICAL,
      number: detailsFormValues.numberOfProfesionalOrTechnical,
      characteristics: {
        ...removeRoleFromKey(
          filterObjectByRole(values, projectRole.ROLE_TECHNICAL),
          projectRole.ROLE_TECHNICAL,
        ),
      },
    },
    {
      role: projectRole.ROLE_ADMINISTRATIVE,
      number: detailsFormValues.numberOfAdministrative,
      characteristics: {
        ...removeRoleFromKey(
          filterObjectByRole(values, projectRole.ROLE_ADMINISTRATIVE),
          projectRole.ROLE_ADMINISTRATIVE,
        ),
      },
    },
  ];
};

const StepOne = ({ isResponsive, onNextStep }) => (
  <React.Fragment>
    <StyledInputGroup>
      <Field secondary component={Input} name="name" placeholder="Project" />
      <Field
        secondary
        component={Input}
        type="number"
        name="squareFeet"
        placeholder="Square Feet"
      />
      <Field
        secondary
        component={Input}
        type="number"
        name="cost"
        placeholder="Project or Annual Cost"
      />
    </StyledInputGroup>
    <StyledButton isResponsive={isResponsive} onClick={() => onNextStep(2)}>
      {'Next'}
    </StyledButton>
  </React.Fragment>
);

const StepTwo = ({
  isResponsive,
  submitting,
  invalid,
  isLoading,
  isEdit,
  detailsFormValues,
  setRoleCharacteristicsValues,
}) => {
  const {
    numberOfExecutives,
    numberOfManagers,
    numberOfProfesionalOrTechnical,
    numberOfAdministrative,
  } = detailsFormValues;

  const isDisabled = submitting || invalid;
  const isHidden = !isEdit && (submitting || invalid);

  return (
    <React.Fragment>
      <Wrapper>
        <Accordion>
          <div label="Executives" value={numberOfExecutives}>
            <Field
              secondary
              component={AccordionInput}
              type="number"
              name="numberOfExecutives"
              placeholder="Enter number of employees"
            />
            <ViewAccordion>
              <RoleCharacteristicsForm
                onEditCharacteristics={values =>
                  setRoleCharacteristicsValues(values)
                }
                role={projectRole.ROLE_EXECUTIVE}
              />
            </ViewAccordion>
          </div>
          <div label="Managers" value={numberOfManagers}>
            <Field
              secondary
              component={AccordionInput}
              type="number"
              name="numberOfManagers"
              placeholder="Enter number of employees"
            />
            <ViewAccordion>
              <RoleCharacteristicsForm
                onEditCharacteristics={values =>
                  setRoleCharacteristicsValues(values)
                }
                role={projectRole.ROLE_MANAGER}
              />
            </ViewAccordion>
          </div>
          <div
            label="Professional or Technical"
            value={numberOfProfesionalOrTechnical}>
            <Field
              secondary
              component={AccordionInput}
              type="number"
              name="numberOfProfesionalOrTechnical"
              placeholder="Enter number of employees"
            />
            <ViewAccordion>
              <RoleCharacteristicsForm
                onEditCharacteristics={values =>
                  setRoleCharacteristicsValues(values)
                }
                role={projectRole.ROLE_TECHNICAL}
              />
            </ViewAccordion>
          </div>
          <div label="Administrative" value={numberOfAdministrative}>
            <Field
              secondary
              component={AccordionInput}
              type="number"
              name="numberOfAdministrative"
              placeholder="Enter number of employees"
            />
            <ViewAccordion>
              <RoleCharacteristicsForm
                onEditCharacteristics={values =>
                  setRoleCharacteristicsValues(values)
                }
                role={projectRole.ROLE_ADMINISTRATIVE}
              />
            </ViewAccordion>
          </div>
        </Accordion>
      </Wrapper>

      {!isHidden && (
        <StyledButton
          type="submit"
          isResponsive={isResponsive}
          isLoading={isLoading}
          disabled={isDisabled}>
          {isEdit ? 'Next' : 'Create'}
        </StyledButton>
      )}
    </React.Fragment>
  );
};

const DetailsForm = ({
  currentStep,
  onNextStep,
  isResponsive,
  handleSubmit,
  submitting,
  invalid,
  isLoading,
  isEdit,
  synchronousError,
  detailsFormValues,
  setRoles,
}) => {
  const isValid =
    Object.values(synchronousError).every(el => el === undefined) && !invalid;

  const [roleCharacteristicsValues, setRoleCharacteristicsValues] = useState(
    {},
  );

  useEffect(() => {
    setRoles(
      normalizeRolesValues(roleCharacteristicsValues, detailsFormValues),
    );
  }, [roleCharacteristicsValues, setRoles, detailsFormValues]);

  return (
    <StyledForm onSubmit={handleSubmit}>
      {currentStep === 1 && (
        <StepOne
          isEdit={isEdit}
          isResponsive={isResponsive}
          onNextStep={onNextStep}
        />
      )}

      {currentStep === 2 && (
        <StepTwo
          setRoleCharacteristicsValues={setRoleCharacteristicsValues}
          detailsFormValues={detailsFormValues}
          submitting={submitting}
          invalid={!isValid}
          isLoading={isLoading}
          isEdit={isEdit}
          isResponsive={isResponsive}
        />
      )}
    </StyledForm>
  );
};

DetailsForm.propTypes = {
  currentStep: PropTypes.number,
  onNextStep: PropTypes.func,
  isResponsive: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

DetailsForm.defaultProps = {
  currentStep: 1,
  onNextStep: () => {},
  isLoading: false,
};

export default DetailsForm;
