import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { normalizeVariableName } from '@humanspace/utils/transformers';

import { AccordionInput } from '../../../../lib/components';

const percentage = value => {
  if (value) return Number(value * 100).toFixed(2);
};

const formatPercentage = value => {
  if (!value) return '';

  return Number(value / 100);
};

const RoleCharacteristicsForm = ({
  role,
  roleCharacteristicsFormValues,
  onEditCharacteristics,
}) => {
  return (
    <React.Fragment>
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="%"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName(
          'averageCostReplaceEmployeePercentCompensation',
        )}`}
        placeholder="Avg. employee replacement cost (as a % of base salary)"
        format={percentage}
        parse={formatPercentage}
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="$"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName('averageCostPerHourOfRework')}`}
        placeholder="Avg. full hourly employee cost (salary and benefits)"
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="hrs"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName('averageAnnualHoursOfWork')}`}
        placeholder="Avg. annual hours of work time available (excluding holidays, PTO, etc)"
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="%"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName('averageRetentionRate')}`}
        placeholder="Annual retention rate (for this role)"
        format={percentage}
        parse={formatPercentage}
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="%"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName(
          'averagePercentOfProcessRework',
        )}`}
        placeholder="Avg. % of overall time spent revising work products (revisions, fixing errors, reworking ideas)"
        format={percentage}
        parse={formatPercentage}
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="%"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName(
          'averagePercentageOfTimeSpentInProcessWork',
        )}`}
        placeholder="Avg. % of time spent on key work activities (areas of prime responsability)"
        format={percentage}
        parse={formatPercentage}
      />
      <Field
        onSave={() => onEditCharacteristics(roleCharacteristicsFormValues)}
        valueFormat="hrs"
        editable
        secondary
        component={AccordionInput}
        type="number"
        name={`${role}${normalizeVariableName(
          'averageProcessTimePerEmployee',
        )}`}
        placeholder="Avg. duration of a typical key work activity (hours)"
      />
    </React.Fragment>
  );
};

RoleCharacteristicsForm.propTypes = {
  currentStep: PropTypes.number,
  onNextStep: PropTypes.func,
  isResponsive: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

RoleCharacteristicsForm.defaultProps = {
  currentStep: 1,
  onNextStep: () => {},
  isLoading: false,
};

export default RoleCharacteristicsForm;
