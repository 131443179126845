import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import truncate from 'lodash/truncate';

import Text from '../Text';
import Icon from '../Icon';

import { useMediaQueryContext } from '../MediaQueryProvider';
import { Spacing, Colors, Sizes } from '../../foundations';

const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  width: 100%;
`;

const InputWrapper = styled.div`
  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  padding: ${Spacing.m} ${Spacing.m};
  border-bottom: 2px solid ${Colors.black200};
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  flex: 1;
  margin: 0;
  box-shadow: none;
  border: 0;
  width: 100%;
  flex-basis: 100%;

  ${({ isLast }) =>
    isLast &&
    `
  border-bottom-left-radius:8px;
  border-bottom-right-radius: 8px;
  border-bottom: 0;
`};
  ${({ isFirst }) =>
    isFirst &&
    `
  border-top-left-radius:8px;
  border-top-right-radius: 8px;
`};

  border-bottom-left-radius: 0px;
  align-items: center;
  display: flex;

  justify-content: flex-end;
`;

const StyledInput = styled.input`
  display: none;
`;

const Child = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-height: 56px;
`;

const Label = styled.label`
  min-height: 56px;
  height: 100%;
`;

const Placeholder = styled(Text)`
  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  padding: ${Spacing.m} ${Spacing.m};

  border-bottom: 2px solid ${Colors.black200};
  outline: 0;
  white-space: nowrap;
  align-self: stretch;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  display: flex;

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom: 0;
  `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
  `};
`;

const ButtonLabel = styled.label`
  font-family: 'Montserrat', serif;
  font-size: ${Sizes.xs};
  color: ${Colors.white};
  font-weight: bold;
  padding: ${Spacing.xs};
  background-color: ${Colors.primary};
  border-radius: 20px;
  cursor: pointer;
`;

const InputFile = ({
  isLast,
  isFirst,
  disabled,
  placeholder,
  input,
  secondary,
  dark,
  meta: { touched, error },
}) => {
  const [fileName, setFileName] = React.useState(undefined);

  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  const changeHandler = event => {
    const filesToUpload = event.target.files;

    if (filesToUpload.length > 0) {
      let filename = filesToUpload[0].name;
      setFileName(filename);
      input.onChange(filesToUpload[0]);
    }
  };

  return (
    <SecondaryWrapper>
      <Child>
        <Label htmlFor={input.name}>
          <Placeholder
            color={dark || fileName ? Colors.anakiwa : Colors.white}
            dark={dark}
            isFirst={isFirst}
            isLast={isLast}>
            {fileName
              ? truncate(fileName, {
                  length: 45,
                  omission: '...',
                })
              : placeholder}
          </Placeholder>
        </Label>
      </Child>

      <Child>
        <InputWrapper isFirst={isFirst} isLast={isLast}>
          <StyledInput
            isResponsive={isResponsive}
            id={input.name}
            dark={dark}
            secondary={secondary}
            {...input}
            disabled={disabled}
            type="file"
            placeholder={placeholder}
            error={error && touched}
            onChange={changeHandler}
            value=""
          />
          {fileName ? (
            <div
              onClick={() => {
                setFileName(undefined);
                input.onChange(undefined);
              }}>
              <Icon icon="trash" />
            </div>
          ) : (
            <ButtonLabel for={input.name}>Choose file</ButtonLabel>
          )}
        </InputWrapper>
      </Child>
    </SecondaryWrapper>
  );
};

InputFile.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  secondary: PropTypes.bool,
};

InputFile.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  isLast: false,
  isFirst: false,
  meta: {
    error: undefined,
    touched: false,
  },
  secondary: false,
};

export default InputFile;
