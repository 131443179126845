import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import {
  Text,
  Loader,
  Layout,
  useMediaQueryContext,
} from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

import modelResultPositive from '@humanspace/assets/images/model_result_positive.gif';
import modelResultNegative from '@humanspace/assets/images/model_result_negative.gif';
import modelResultNeutral from '@humanspace/assets/images/model_result_neutral.gif';

import { NotStressed, Stressed, Neutral } from './Results';

const TIMER = 5000;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const CalculateLoader = styled.div`
  margin-top: 50%;
`;

const StyledLoaderText = styled(Text)`
  margin-top: ${Spacing.m};
`;

const StyledImage = styled.img`
  max-width: 500px;
  padding: ${props => (props.isResponsive ? `${Spacing.m} 0` : '0px')};
`;

const CircleLabel = styled.label`
  display: block;
  background: ${props => props.backgroundColor};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;

  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const InputCircle = styled.input`
  display: none;
  visibility: hidden;
  position: absolute;

  &:checked + label {
    width: 300%;
    height: 300%;
    top: -100%;
    margin-top: 0;
    left: -100%;
    margin-left: 0;
    border-radius: 100%;
    background: ${props => props.backgroundColor};
    transition: all 0.5s;
  }
`;

const StyledLayout = styled(Layout)`
  background-image: linear-gradient(
    180deg,
    ${Colors.primary},
    ${Colors.secondary}
  );
  animation: gradient-rotate 10s infinite;

  @keyframes gradient-rotate {
    0% {
      background: linear-gradient(0deg, ${Colors.primary}, ${Colors.secondary});
    }
    1% {
      background: linear-gradient(
        3.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    2% {
      background: linear-gradient(
        7.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    3% {
      background: linear-gradient(
        10.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    4% {
      background: linear-gradient(
        14.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    5% {
      background: linear-gradient(
        18deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    6% {
      background: linear-gradient(
        21.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    7% {
      background: linear-gradient(
        25.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    8% {
      background: linear-gradient(
        28.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    9% {
      background: linear-gradient(
        32.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    10% {
      background: linear-gradient(
        36deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    11% {
      background: linear-gradient(
        39.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    12% {
      background: linear-gradient(
        43.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    13% {
      background: linear-gradient(
        46.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    14% {
      background: linear-gradient(
        50.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    15% {
      background: linear-gradient(
        54deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    16% {
      background: linear-gradient(
        57.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    17% {
      background: linear-gradient(
        61.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    18% {
      background: linear-gradient(
        64.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    19% {
      background: linear-gradient(
        68.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    20% {
      background: linear-gradient(
        72deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    21% {
      background: linear-gradient(
        75.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    22% {
      background: linear-gradient(
        79.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    23% {
      background: linear-gradient(
        82.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    24% {
      background: linear-gradient(
        86.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    25% {
      background: linear-gradient(
        90deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    26% {
      background: linear-gradient(
        93.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    27% {
      background: linear-gradient(
        97.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    28% {
      background: linear-gradient(
        100.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    29% {
      background: linear-gradient(
        104.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    30% {
      background: linear-gradient(
        108deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    31% {
      background: linear-gradient(
        111.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    32% {
      background: linear-gradient(
        115.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    33% {
      background: linear-gradient(
        118.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    34% {
      background: linear-gradient(
        122.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    35% {
      background: linear-gradient(
        126deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    36% {
      background: linear-gradient(
        129.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    37% {
      background: linear-gradient(
        133.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    38% {
      background: linear-gradient(
        136.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    39% {
      background: linear-gradient(
        140.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    40% {
      background: linear-gradient(
        144deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    41% {
      background: linear-gradient(
        147.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    42% {
      background: linear-gradient(
        151.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    43% {
      background: linear-gradient(
        154.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    44% {
      background: linear-gradient(
        158.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    45% {
      background: linear-gradient(
        162deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    46% {
      background: linear-gradient(
        165.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    47% {
      background: linear-gradient(
        169.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    48% {
      background: linear-gradient(
        172.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    49% {
      background: linear-gradient(
        176.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    50% {
      background: linear-gradient(
        180deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    51% {
      background: linear-gradient(
        183.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    52% {
      background: linear-gradient(
        187.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    53% {
      background: linear-gradient(
        190.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    54% {
      background: linear-gradient(
        194.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    55% {
      background: linear-gradient(
        198deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    56% {
      background: linear-gradient(
        201.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    57% {
      background: linear-gradient(
        205.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    58% {
      background: linear-gradient(
        208.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    59% {
      background: linear-gradient(
        212.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    60% {
      background: linear-gradient(
        216deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    61% {
      background: linear-gradient(
        219.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    62% {
      background: linear-gradient(
        223.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    63% {
      background: linear-gradient(
        226.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    64% {
      background: linear-gradient(
        230.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    65% {
      background: linear-gradient(
        234deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    66% {
      background: linear-gradient(
        237.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    67% {
      background: linear-gradient(
        241.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    68% {
      background: linear-gradient(
        244.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    69% {
      background: linear-gradient(
        248.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    70% {
      background: linear-gradient(
        252deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    71% {
      background: linear-gradient(
        255.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    72% {
      background: linear-gradient(
        259.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    73% {
      background: linear-gradient(
        262.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    74% {
      background: linear-gradient(
        266.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    75% {
      background: linear-gradient(
        270deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    76% {
      background: linear-gradient(
        273.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    77% {
      background: linear-gradient(
        277.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    78% {
      background: linear-gradient(
        280.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    79% {
      background: linear-gradient(
        284.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    80% {
      background: linear-gradient(
        288deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    81% {
      background: linear-gradient(
        291.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    82% {
      background: linear-gradient(
        295.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    83% {
      background: linear-gradient(
        298.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    84% {
      background: linear-gradient(
        302.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    85% {
      background: linear-gradient(
        306deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    86% {
      background: linear-gradient(
        309.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    87% {
      background: linear-gradient(
        313.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    88% {
      background: linear-gradient(
        316.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    89% {
      background: linear-gradient(
        320.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    90% {
      background: linear-gradient(
        324deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    91% {
      background: linear-gradient(
        327.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    92% {
      background: linear-gradient(
        331.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    93% {
      background: linear-gradient(
        334.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    94% {
      background: linear-gradient(
        338.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    95% {
      background: linear-gradient(
        342deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    96% {
      background: linear-gradient(
        345.6deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    97% {
      background: linear-gradient(
        349.2deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    98% {
      background: linear-gradient(
        352.8deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    99% {
      background: linear-gradient(
        356.4deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
    100% {
      background: linear-gradient(
        360deg,
        ${Colors.primary},
        ${Colors.secondary}
      );
    }
  }
`;

const resultsColors = {
  stressed: Colors.maroon,
  'not-stressed': Colors.primary,
  neutral: Colors.grey700,
};

const resultsImage = {
  stressed: modelResultNegative,
  'not-stressed': modelResultPositive,
  neutral: modelResultNeutral,
};

const getResultRightColumn = predictionResults => {
  if (predictionResults.predictionResult === 'stressed') return <Stressed />;
  if (predictionResults.predictionResult === 'not-stressed')
    return <NotStressed />;

  return <Neutral />;
};

const CircleAnimation = ({ config, backgroundColor }) => {
  return (
    <React.Fragment>
      <InputCircle
        backgroundColor={backgroundColor}
        id="hidden-checkbox"
        type="checkbox"
        checked={config.isChecked}></InputCircle>
      <CircleLabel
        backgroundColor={backgroundColor}
        for="hidden-checkbox"
        isVisible={config.isVisible}></CircleLabel>
    </React.Fragment>
  );
};

const NewModel = ({ isLoading, predictionResults, getStressModel }) => {
  const { id } = useParams();

  React.useEffect(() => {
    getStressModel(id);
  }, [id]);

  const [isCalculating, setIsCalculating] = React.useState(true);
  const [animationConfig, setAnimationConfig] = React.useState({
    isVisible: false,
    isChecked: false,
  });
  const { isMobile, isTablet } = useMediaQueryContext();

  const isResponsive = isMobile || isTablet;

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsCalculating(false);
      setAnimationConfig({
        isVisible: true,
        isChecked: false,
      });
    }, TIMER);

    const startAnimation = setTimeout(() => {
      setAnimationConfig({
        isVisible: true,
        isChecked: true,
      });
    }, TIMER + 200);

    const endAnimation = setTimeout(() => {
      setAnimationConfig({
        isVisible: false,
        isChecked: false,
      });
    }, TIMER + 1000);

    return () => {
      clearTimeout(timer);
      clearTimeout(startAnimation);
      clearTimeout(endAnimation);
    };
  }, []);

  if (!predictionResults) return <div></div>;

  const resultImage = resultsImage[predictionResults.predictionResult];

  return (
    <React.Fragment>
      {isCalculating || isLoading ? (
        <StyledLayout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              {isCalculating ? (
                <CalculateLoader>
                  <Loader type="elipsis" />
                  <StyledLoaderText bold size="l" lineHeight={1.1}>
                    Calculating results
                  </StyledLoaderText>
                </CalculateLoader>
              ) : (
                <Loader />
              )}
            </LoaderWrapper>
          }
        />
      ) : (
        !animationConfig.isVisible && (
          <Layout
            backgroundColor={resultsColors[predictionResults.predictionResult]}
            multiple
            hasNavigation
            linkElement={Link}
            leftColumn={
              <React.Fragment>
                {resultImage && (
                  <StyledImage
                    isResponsive={isResponsive}
                    src={resultImage}
                    alt={`${predictionResults.predictionResult} result`}
                  />
                )}
              </React.Fragment>
            }
            rightColumn={getResultRightColumn(predictionResults)}
          />
        )
      )}
      <CircleAnimation
        backgroundColor={resultsColors[predictionResults.predictionResult]}
        config={animationConfig}
      />
    </React.Fragment>
  );
};

NewModel.propTypes = {
  createProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NewModel;
