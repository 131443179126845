import types from './types';

const createPredictionModel = ({ modelName, modelFile }) => ({
  type: types.CREATE_PREDICTION_MODEL,
  payload: { modelName, modelFile },
});

const createPredictionModelSuccess = predictionResults => ({
  type: types.CREATE_PREDICTION_MODEL_SUCCESS,
  payload: predictionResults,
});

const createPredictionModelFailure = ({ error }) => ({
  type: types.CREATE_PREDICTION_MODEL_FAILURE,
  payload: error,
});

const getStressModels = () => ({
  type: types.GET_STRESS_MODELS,
});

const getStressModelsSuccess = stressModels => ({
  type: types.GET_STRESS_MODELS_SUCCESS,
  payload: stressModels,
});

const getStressModelsFailure = ({ error }) => ({
  type: types.GET_STRESS_MODELS_FAILURE,
  payload: error,
});

const getStressModel = stressModelId => ({
  type: types.GET_STRESS_MODEL,
  payload: { stressModelId },
});

const getStressModelSuccess = predictionResults => ({
  type: types.GET_STRESS_MODEL_SUCCESS,
  payload: predictionResults,
});

const getStressModelFailure = ({ error }) => ({
  type: types.GET_STRESS_MODEL_FAILURE,
  payload: error,
});

const removeStressModel = stressModelId => ({
  type: types.REMOVE_STRESS_MODEL,
  payload: { stressModelId },
});

const removeStressModelSuccess = () => ({
  type: types.REMOVE_STRESS_MODEL_SUCCESS,
});

const removeStressModelFailure = ({ error }) => ({
  type: types.REMOVE_STRESS_MODEL_FAILURE,
  payload: error,
});

export default {
  createPredictionModel,
  createPredictionModelSuccess,
  createPredictionModelFailure,
  getStressModels,
  getStressModelsSuccess,
  getStressModelsFailure,
  getStressModel,
  getStressModelSuccess,
  getStressModelFailure,
  removeStressModel,
  removeStressModelSuccess,
  removeStressModelFailure,
};
