import { connect } from 'react-redux';

import actions from '../../duck/actions';

import CreateSurvey from './CreateSurvey';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
});

const mapDispatchToProps = dispatch => ({
  createSurvey: (projectId, surveyName) =>
    dispatch(actions.createSurvey(projectId, surveyName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
