import React, { useEffect, useState } from 'react';

import AccordionSection from './AccordionSection';

const Accordion = ({ children }) => {
  const arrayLength = React.Children.toArray(children).length;

  const [openSections, updateOpenSections] = useState({});

  useEffect(() => {
    children.forEach(child => {
      if (child.props.isOpen) {
        updateOpenSections({ [child.props.label]: true });
      }
    });
  }, [children]);

  const onClick = label => {
    const isOpen = !!openSections[label];
    updateOpenSections({ [label]: !isOpen });
  };

  const someSectionIsOpen = Object.values(openSections).some(x => x === true);

  return (
    <div>
      {children &&
        children.map((child, index) => (
          <AccordionSection
            key={child.props.label}
            someSectionIsOpen={someSectionIsOpen}
            isLast={arrayLength === index + 1}
            isFirst={index === 0}
            value={child.props.value}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            onClick={() => onClick(child.props.label)}>
            {child.props.children}
          </AccordionSection>
        ))}
    </div>
  );
};

export default Accordion;
