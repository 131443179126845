import { connect } from 'react-redux';
import actions from '../../duck/actions';

import ModelResults from './ModelResults';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  predictionResults: state.models.predictionResults,
});

const mapDispatchToProps = dispatch => ({
  getStressModel: stressModelId =>
    dispatch(actions.getStressModel(stressModelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelResults);
