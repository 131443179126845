import { connect } from 'react-redux';

import actions from '../../duck/actions';

import SurveyRating from './SurveyDetails';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  surveyDetails: state.projects.surveyDetails,
});

const mapDispatchToProps = dispatch => ({
  getSurveyDetails: surveyId => dispatch(actions.getSurveyDetails(surveyId)),
  resetSurveyDetails: () => dispatch(actions.resetSurveyDetails()),
  getCapabilities: (projectId, ratingConfig, surveyId, isComingFromSurvey) =>
    dispatch(
      actions.getCapabilities(
        projectId,
        ratingConfig,
        surveyId,
        isComingFromSurvey,
      ),
    ),
  getProject: projectId => dispatch(actions.getProject(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRating);
