import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isUndefined from 'lodash/isUndefined';
import { useParams, Link } from 'react-router-dom';

import { Field } from 'redux-form';

import {
  Text,
  Select,
  Layout,
  ReadMore,
  MultiRangeSlider,
  Loader,
  Icon,
  Dashboard,
  useMediaQueryContext,
} from '@humanspace/components';

import { Spacing } from '@humanspace/foundations';
import { CapabilityInfoModal } from '../../components';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const CapabilitySection = styled.div`
  margin-bottom: ${Spacing.xxxl};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spacing.s};
`;

const StyledForm = styled.form`
  margin-bottom: ${Spacing.xxxxl};
`;

const StyledDashboard = styled(Dashboard)`
  margin-bottom: ${Spacing.xxxl};
  max-width: 100%;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.s};
  margin-bottom: ${Spacing.xxxl};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.xl};
`;

const Thumb = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;

  margin-right: ${Spacing.xs};

  ${props =>
    props.empty
      ? `
    border: 2px solid white;
   `
      : `background-color: white`}
`;

const industryOptions = [
  {
    label: 'A&D Construction',
    value: 'A&D Construction',
  },
  {
    label: 'Defense',
    value: 'Defense',
  },
  {
    label: 'Energy',
    value: 'Energy',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Government',
    value: 'Government',
  },
  {
    label: 'Healthcare',
    value: 'Healthcare',
  },
  {
    label: 'Hi Tech',
    value: 'Hi Tech',
  },
  {
    label: 'Higher Ed',
    value: 'Higher Ed',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Pharmaceutical',
    value: 'Pharmaceutical',
  },
  {
    label: 'Professional Services',
    value: 'Professional Services',
  },
];

const calculaIndustryModelDifference = (project, industryConfig) => ({
  workPlacePercentageChange:
    project?.workPlacePercentageChange -
    industryConfig?.workPlacePercentageChange,
  changeManagementPercentageChange:
    project?.changeManagementPercentageChange -
    industryConfig?.changeManagementPercentageChange,
  activityPercentageChange:
    project?.activityPercentageChange -
    industryConfig?.activityPercentageChange,
  interiorDesignPercentageChange:
    project?.interiorDesignPercentageChange -
    industryConfig?.interiorDesignPercentageChange,
  groupSpacePercentageChange:
    project?.groupSpacePercentageChange -
    industryConfig?.groupSpacePercentageChange,
  brandPercentageChange:
    project?.brandPercentageChange - industryConfig?.brandPercentageChange,
});

const Benchmark = ({
  industry,
  getBenchmarkData,
  benchmarkData,
  getProject,
  getBenchmarkModel,
  isLoading,
  project,
  results,
  benchmarkModel,
}) => {
  const placeholder = 'Select an industry';
  const isStressNegative =
    benchmarkModel && benchmarkModel.stressPercentageChange < 0;
  const isNegative =
    benchmarkModel &&
    benchmarkModel.humanCapitalBenefitAsPercentOfCompensation < 0;
  const hasIndustryValue = !isUndefined(industry) && industry !== placeholder;
  const [selectedIndustryConfig, setSelectedIndustryConfig] = useState({});
  const [infoModal, setInfoModal] = useState(undefined);
  const [isModalOpen, setModalOpen] = useState(false);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  const currentDifferenceValue = calculaIndustryModelDifference(
    project,
    selectedIndustryConfig,
  );

  const { id } = useParams();

  useEffect(() => {
    getBenchmarkData();
    getProject(id);
  }, [id, getBenchmarkData, getProject]);

  useEffect(() => {
    if (benchmarkData && benchmarkData.industriesBenchmark) {
      setSelectedIndustryConfig(benchmarkData.industriesBenchmark[industry]);
    }
  }, [industry, benchmarkData]);

  useEffect(() => {
    getBenchmarkModel(id, currentDifferenceValue);
  }, [getBenchmarkModel, id, currentDifferenceValue]);

  return (
    <React.Fragment>
      {id && isLoading ? (
        <Layout
          hasNavigation
          linkElement={Link}
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          align="top"
          scroll="sticky"
          hasNavigation
          multiple
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Benchmark
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  Select the industry that you want to benchmark your results
                  against.
                </ReadMore>
              </StyledText>

              <StyledForm>
                <Field
                  component={Select}
                  options={industryOptions}
                  name="industry"
                  placeholder={placeholder}
                />
              </StyledForm>

              {hasIndustryValue && isResponsive && (
                <StyledDashboard
                  isNegative={isNegative}
                  isStressNegative={isStressNegative}
                  simplified
                  results={benchmarkModel || results}
                  isResponsive={isResponsive}
                />
              )}

              {hasIndustryValue && (
                <>
                  <Text size="xl" bold lineHeight={1.1}>
                    Comparison of workspace capabilities to benchmark
                  </Text>
                  <StyledText size="m" lineHeight={1.7}>
                    <ReadMore>
                      For each of the six capabilities shown below, we show the
                      % difference between your workspace score (solid circle)
                      and benchmark (empty circle).
                    </ReadMore>
                  </StyledText>
                  <Legend>
                    <LegendItem>
                      <Thumb />
                      <Text>Your project</Text>
                    </LegendItem>
                    <LegendItem>
                      <Thumb empty />
                      <Text>Benchmark</Text>
                    </LegendItem>
                  </Legend>

                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Individual workspace
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('workPlacePercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.workPlacePercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.workPlacePercentageChange
                      }
                    />
                  </CapabilitySection>

                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Communication / Training
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('changeManagementPercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.changeManagementPercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.changeManagementPercentageChange
                      }
                    />
                  </CapabilitySection>

                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Ambient Environment
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('activityPercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.activityPercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.activityPercentageChange
                      }
                    />
                  </CapabilitySection>
                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Space planning
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('interiorDesignPercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.interiorDesignPercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.interiorDesignPercentageChange
                      }
                    />
                  </CapabilitySection>
                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Social / Group Spaces
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('groupSpacePercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.groupSpacePercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.groupSpacePercentageChange
                      }
                    />
                  </CapabilitySection>
                  <CapabilitySection>
                    <Header>
                      <Text bold size="s">
                        Brand, Aesthetics
                      </Text>
                      <Icon
                        icon="info"
                        onClick={() => {
                          setInfoModal('brandPercentageChange');
                          setModalOpen(true);
                        }}
                      />
                    </Header>

                    <MultiRangeSlider
                      showValue
                      currentValue={
                        currentDifferenceValue?.brandPercentageChange
                      }
                      benchmarkValue={
                        selectedIndustryConfig?.brandPercentageChange
                      }
                    />
                  </CapabilitySection>
                </>
              )}
            </React.Fragment>
          }
          rightColumn={
            hasIndustryValue &&
            !isResponsive && (
              <StyledDashboard
                isNegative={isNegative}
                isStressNegative={isStressNegative}
                simplified
                results={benchmarkModel || results}
                isResponsive={isResponsive}
              />
            )
          }
        />
      )}
      <CapabilityInfoModal
        isOpen={isModalOpen}
        type={infoModal}
        onClose={() => setModalOpen(false)}
      />
    </React.Fragment>
  );
};

export default Benchmark;
