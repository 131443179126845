import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Text, Button, Layout } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.xl};
`;

const RegisterConfirmation = () => {
  const history = useHistory();
  return (
    <Layout
      render={
        <>
          <StyledText align="center" size="xl" bold>
            Thank you for joining
          </StyledText>
          <StyledText align="center" size="l">
            Your new account has been created
          </StyledText>
          <StyledButton onClick={() => history.push('/login')}>
            Done
          </StyledButton>
        </>
      }
    />
  );
};

export default RegisterConfirmation;
