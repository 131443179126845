import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing, Colors, Sizes } from '../../foundations';

import { useMediaQueryContext } from '../MediaQueryProvider';

const getProperSize = (size, isResponsive) => {
  if (isResponsive && size === 'xxs') return Sizes[size];
  if (isResponsive) {
    const currentPosition = Object.keys(Sizes).indexOf(size);
    const previousSize = Object.keys(Sizes)[currentPosition - 1];
    return Sizes[previousSize];
  }
  return Sizes[size];
};

const StyledTextarea = styled.textarea`
  font-family: 'Montserrat', serif;
  color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
  font-size: ${({ isResponsive }) => getProperSize('s', isResponsive)};
  line-height: 1.4;
  font-weight: 400;
  box-shadow: none;
  border: 0;
  background-color: ${props =>
    props.dark ? Colors.secondary : Colors.white200};
  padding: ${Spacing.s} ${Spacing.m};
  border-bottom: 2px solid ${Colors.black200};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  flex: 1;
  outline: 0;
  resize: none;
  max-height: 180px;

  &::placeholder {
    color: ${props => (props.dark ? Colors.anakiwa : Colors.white)};
    opacity: 1;
  }
`;

const Textarea = ({
  input,
  placeholder,
  disabled,
  dark,
  meta: { touched, error },
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <StyledTextarea
      isResponsive={isResponsive}
      dark={dark}
      disabled={disabled}
      error={error && touched}
      {...input}
      placeholder={placeholder}
      rows="10"
      cols="50"
    />
  );
};

Textarea.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  dark: PropTypes.bool,
};

Textarea.defaultProps = {
  placeholder: '',
  disabled: false,
  meta: {
    error: undefined,
    touched: false,
  },
  dark: true,
};

export default Textarea;
