import React from 'react';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Spacing } from '../../foundations';

import { useMediaQueryContext } from '../MediaQueryProvider';

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${Spacing.xs};
  width: 900px;
  margin: 0 auto;

  ${props =>
    props.isResponsive
      ? `justify-content: flex-start;`
      : `justify-content: center;`}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  padding-bottom: ${Spacing.xxxxl};
`;

const Wrapper = styled.div`
  margin-right: ${Spacing.m};

  &:last-child {
    margin-right: 0;
  }
`;

const HorizontalLayout = ({ children }) => {
  const { isMobile, isTablet } = useMediaQueryContext();

  const isResponsive = isMobile || isTablet;

  return (
    <StyledScrollContainer>
      <Container isResponsive={isResponsive}>
        {React.Children.map(children, child => (
          <Wrapper>{React.cloneElement(child)}</Wrapper>
        ))}
      </Container>
    </StyledScrollContainer>
  );
};

export default HorizontalLayout;
