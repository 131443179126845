import types from './types';

const createProject = ({
  name,
  squareFeet,
  cost,
  numberOfExecutives,
  numberOfManagers,
  numberOfProfesionalOrTechnical,
  numberOfAdministrative,
  roles,
}) => ({
  type: types.CREATE_PROJECT,
  payload: {
    name,
    squareFeet,
    cost,
    numberOfExecutives,
    numberOfManagers,
    numberOfProfesionalOrTechnical,
    numberOfAdministrative,
    roles,
  },
});

const createProjectSuccess = () => ({
  type: types.CREATE_PROJECT_SUCCESS,
});

const createProjectFailure = ({ error }) => ({
  type: types.CREATE_PROJECT_FAILURE,
  payload: error,
});

const updateProject = (
  {
    name,
    squareFeet,
    cost,
    numberOfExecutives,
    numberOfManagers,
    numberOfProfesionalOrTechnical,
    numberOfAdministrative,
    roles,
  },
  projectId,
) => ({
  type: types.UPDATE_PROJECT,
  payload: {
    name,
    squareFeet,
    cost,
    numberOfExecutives,
    numberOfManagers,
    numberOfProfesionalOrTechnical,
    numberOfAdministrative,
    projectId,
    roles,
  },
});

const updateProjectSuccess = () => ({
  type: types.UPDATE_PROJECT_SUCCESS,
});

const updateProjectFailure = ({ error }) => ({
  type: types.UPDATE_PROJECT_FAILURE,
  payload: error,
});

const getProjects = () => ({
  type: types.GET_PROJECTS,
});

const getProjectsSuccess = projects => ({
  type: types.GET_PROJECTS_SUCCESS,
  payload: projects,
});

const getProjectsFailure = error => ({
  type: types.GET_PROJECTS_FAILURE,
  payload: error,
});

const getProjectRoleCharacteristics = projectId => ({
  type: types.GET_PROJECT_ROLE_CHARACTERISTICS,
  payload: projectId,
});

const getProjectRoleCharacteristicsSuccess = roleCharacteristics => ({
  type: types.GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS,
  payload: roleCharacteristics,
});

const getProjectRoleCharacteristicsFailure = error => ({
  type: types.GET_PROJECT_ROLE_CHARACTERISTICS_FAILURE,
  payload: error,
});

const updateProjectRoleCharacteristics = (
  projectId,
  roleCharacteristicsFormValues,
) => ({
  type: types.UPDATE_PROJECT_ROLE_CHARACTERISTICS,
  payload: {
    projectId,
    roleCharacteristicsFormValues,
  },
});

const updateProjectRoleCharacteristicsSuccess = () => ({
  type: types.UPDATE_PROJECT_ROLE_CHARACTERISTICS_SUCCESS,
});

const updateProjectRoleCharacteristicsFailure = error => ({
  type: types.UPDATE_PROJECT_ROLE_CHARACTERISTICS_FAILURE,
  payload: error,
});

const getProject = projectId => ({
  type: types.GET_PROJECT,
  payload: projectId,
});

const getProjectSuccess = project => ({
  type: types.GET_PROJECT_SUCCESS,
  payload: project,
});

const getProjectFailure = error => ({
  type: types.GET_PROJECT_FAILURE,
  payload: error,
});

const getCapabilities = (
  projectId,
  {
    workPlacePercentageChange,
    changeManagementPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
    interiorDesignPercentageChange,
  },
  surveyId = null,
  isComingFromSurvey = false,
) => ({
  type: types.GET_CAPABILITIES,
  payload: {
    projectId,
    workPlacePercentageChange,
    changeManagementPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
    interiorDesignPercentageChange,
    surveyId,
    isComingFromSurvey,
  },
});

const getCapabilitiesSuccess = capabilities => ({
  type: types.GET_CAPABILITIES_SUCCESS,
  payload: capabilities,
});

const getCapabilitiesFailure = error => ({
  type: types.GET_CAPABILITIES_FAILURE,
  payload: error,
});

const getModel = (
  projectId,
  {
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
) => ({
  type: types.CREATE_MODEL,
  payload: {
    projectId,
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
});

const getModelSuccess = results => ({
  type: types.CREATE_MODEL_SUCCESS,
  payload: results,
});

const getModelFailure = error => ({
  type: types.CREATE_MODEL_FAILURE,
  payload: error,
});

const getBenchmarkModel = (
  projectId,
  {
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
) => ({
  type: types.GET_BENCHMARK_MODEL,
  payload: {
    projectId,
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
});

const getBenchmarkModelSuccess = results => ({
  type: types.GET_BENCHMARK_MODEL_SUCCESS,
  payload: results,
});

const getBenchmarkModelFailure = error => ({
  type: types.GET_BENCHMARK_MODEL_FAILURE,
  payload: error,
});

const removeProject = projectId => ({
  type: types.REMOVE_PROJECT,
  payload: projectId,
});

const removeProjectSuccess = () => ({
  type: types.REMOVE_PROJECT_SUCCESS,
});

const removeProjectFailure = error => ({
  type: types.REMOVE_PROJECT_FAILURE,
  payload: error,
});

const contact = (projectId, contactInfo) => ({
  type: types.CONTACT,
  payload: {
    projectId,
    contactInfo,
  },
});

const contactSuccess = () => ({
  type: types.CONTACT_SUCCESS,
});

const contactFailure = error => ({
  type: types.CONTACT_FAILURE,
  payload: error,
});

const getBenchmarkData = () => ({
  type: types.GET_BENCHMARK_DATA,
});

const getBenchmarkDataSuccess = benchmarkData => ({
  type: types.GET_BENCHMARK_DATA_SUCCESS,
  payload: benchmarkData,
});

const getBenchmarkDataFailure = error => ({
  type: types.GET_BENCHMARK_DATA_FAILURE,
  payload: error,
});

const getModelDetailsData = (
  projectId,
  {
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
) => ({
  type: types.GET_MODEL_DETAILS_DATA,
  payload: {
    projectId,
    workPlacePercentageChange,
    changeManagementPercentageChange,
    activityPercentageChange,
    interiorDesignPercentageChange,
    groupSpacePercentageChange,
    brandPercentageChange,
  },
});

const getModelDetailsDataSuccess = modelDetails => ({
  type: types.GET_MODEL_DETAILS_DATA_SUCCESS,
  payload: modelDetails,
});

const getModelDetailsDataFailure = error => ({
  type: types.GET_MODEL_DETAILS_DATA_FAILURE,
  payload: error,
});

const getSurveys = projectId => ({
  type: types.GET_SURVEYS,
  payload: projectId,
});

const getSurveysSuccess = surveys => ({
  type: types.GET_SURVEYS_SUCCESS,
  payload: surveys,
});

const getSurveysFailure = error => ({
  type: types.GET_SURVEYS_FAILURE,
  payload: error,
});

const createSurvey = (projectId, surveyName) => ({
  type: types.CREATE_SURVEY,
  payload: { projectId, surveyName },
});

const createSurveySuccess = surveyLink => ({
  type: types.CREATE_SURVEY_SUCCESS,
  payload: surveyLink,
});

const createSurveyFailure = error => ({
  type: types.CREATE_SURVEY_FAILURE,
  payload: error,
});

const removeSurvey = (projectId, surveyId) => ({
  type: types.REMOVE_SURVEY,
  payload: { surveyId, projectId },
});

const removeSurveySuccess = () => ({
  type: types.REMOVE_SURVEY_SUCCESS,
});

const removeSurveyFailure = error => ({
  type: types.REMOVE_SURVEY_FAILURE,
  payload: error,
});

const answerSurvey = (surveyId, projectId, capabilitiesValues) => ({
  type: types.ANSWER_SURVEY,
  payload: { surveyId, projectId, capabilitiesValues },
});

const answerSurveySuccess = () => ({
  type: types.ANSWER_SURVEY_SUCCESS,
});

const answerSurveyFailure = error => ({
  type: types.ANSWER_SURVEY_FAILURE,
});

const getSurveyDetails = surveyId => ({
  type: types.GET_SURVEY_DETAILS,
  payload: { surveyId },
});

const getSurveyDetailsSuccess = surveyDetails => ({
  type: types.GET_SURVEY_DETAILS_SUCCESS,
  payload: surveyDetails,
});

const getSurveyDetailsFailure = error => ({
  type: types.GET_SURVEY_DETAILS_FAILURE,
  payload: error,
});

const resetSurveyDetails = () => ({
  type: types.RESET_SURVEY_DETAILS,
});

export default {
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  getProject,
  getProjectSuccess,
  getProjectFailure,
  getProjectRoleCharacteristics,
  getProjectRoleCharacteristicsSuccess,
  getProjectRoleCharacteristicsFailure,
  updateProjectRoleCharacteristics,
  updateProjectRoleCharacteristicsSuccess,
  updateProjectRoleCharacteristicsFailure,
  getCapabilities,
  getCapabilitiesSuccess,
  getCapabilitiesFailure,
  getModel,
  getModelSuccess,
  getModelFailure,
  removeProject,
  removeProjectSuccess,
  removeProjectFailure,
  contact,
  contactSuccess,
  contactFailure,
  getBenchmarkData,
  getBenchmarkDataSuccess,
  getBenchmarkDataFailure,
  getBenchmarkModel,
  getBenchmarkModelSuccess,
  getBenchmarkModelFailure,
  getModelDetailsData,
  getModelDetailsDataSuccess,
  getModelDetailsDataFailure,
  getSurveys,
  getSurveysSuccess,
  getSurveysFailure,
  createSurvey,
  createSurveySuccess,
  createSurveyFailure,
  removeSurvey,
  removeSurveySuccess,
  removeSurveyFailure,
  answerSurvey,
  answerSurveySuccess,
  answerSurveyFailure,
  getSurveyDetails,
  getSurveyDetailsSuccess,
  getSurveyDetailsFailure,
  resetSurveyDetails,
};
