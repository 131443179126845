import React from 'react';
import styled from 'styled-components';

import { Text, Layout } from '@humanspace/components';
import { Spacing } from '@humanspace/foundations';

import { RequestPasswordForm } from '../../components';

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.l};
`;

const RequestPassword = ({ passwordRequest }) => (
  <Layout
    render={
      <>
        <StyledText align="center" size="l" bold>
          Reset password
        </StyledText>
        <RequestPasswordForm
          onSubmit={({ email }) => passwordRequest({ email })}
        />
      </>
    }
  />
);

export default RequestPassword;
