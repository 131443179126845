import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getUserId, getUserToken } from '../../../auth/services';

const PrivateRoute = ({ children, ...props }) => (
  <Route
    {...props}
    render={({ location }) => {
      const userToken = getUserToken();
      const userId = getUserId();
      return userId && userToken ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
