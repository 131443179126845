import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

const Container = styled.div`
  background: ${Colors.white200};
  border-radius: 10px;
  margin-bottom: ${Spacing.xxxl};

  ${props =>
    props.isResponsive
      ? `
  padding: ${Spacing.l} ${Spacing.s};
  `
      : `
  padding: ${Spacing.xxxl} ${Spacing.xxxxxl};
  `}
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${Spacing.xl};
`;

const BenchmarkSection = ({ isResponsive, id, ...props }) => {
  const history = useHistory();

  return (
    <Container {...props} isResponsive={isResponsive}>
      <Text
        align={isResponsive ? 'left' : 'center'}
        size={isResponsive ? 'xl' : 'xxl'}
        bold
        lineHeight={1.2}>
        Compare your results against industry benchmarks
      </Text>
      <StyledButton
        full={isResponsive}
        secondary
        onClick={() => history.push(`/projects/${id}/benchmark`)}>
        Go to Benchmark
      </StyledButton>
    </Container>
  );
};

export default BenchmarkSection;
