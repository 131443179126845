import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes, Colors } from '../../foundations';
import { useMediaQueryContext } from '../MediaQueryProvider';

const getProperSize = (size, isResponsive) => {
  if (isResponsive && size === 'xxs') return Sizes[size];
  if (isResponsive) {
    const currentPosition = Object.keys(Sizes).indexOf(size);
    const previousSize = Object.keys(Sizes)[currentPosition - 1];
    return Sizes[previousSize];
  }
  return Sizes[size];
};

const StyledText = styled.p`
  font-family: 'Montserrat', serif;
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  font-size: ${({ size, isResponsive }) => getProperSize(size, isResponsive)};
  text-align: ${({ align }) => align};
  letter-spacing: ${({ spacing }) => spacing};
  ${({ bold }) => bold && `font-weight: 600`};
  ${({ uppercase }) => uppercase && `text-transform: uppercase`};
  ${({ noWrap }) => noWrap && `white-space: nowrap`};
  ${({ underline }) => underline && `text-decoration: underline;`}

  margin: 0;
  padding: 0;
`;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Text = ({
  size,
  color,
  bold,
  uppercase,
  lineHeight,
  underline,
  align,
  spacing,
  children,
  isLink,
  noWrap,
  ...props
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();

  return (
    <ConditionalWrapper
      condition={isLink}
      wrapper={children => <a {...props}>{children}</a>}>
      <StyledText
        underline={underline}
        noWrap={noWrap}
        isResponsive={isMobile || isTablet}
        size={size}
        color={color}
        bold={bold}
        uppercase={uppercase}
        lineHeight={lineHeight}
        align={align}
        spacing={spacing}
        {...props}>
        {children}
      </StyledText>
    </ConditionalWrapper>
  );
};

Text.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.string,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spacing: PropTypes.string,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  underline: PropTypes.bool,
  isLink: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

Text.defaultProps = {
  size: 's',
  align: 'left',
  color: Colors.white,
  lineHeight: '140%',
  spacing: '0px',
  bold: false,
  underline: false,
  isLink: false,
  uppercase: false,
  children: undefined,
};

export default Text;
