import { StyleSheet } from '@react-pdf/renderer';

import { Colors } from '@humanspace/foundations';

const textStyles = {
  paragraph: {
    fontFamily: 'Montserrat',
    fontSize: '9px',
    fontWeight: 400,
    lineHeight: '1.8',
  },
  small: {
    fontFamily: 'Montserrat',
    fontSize: '7px',
    fontWeight: 400,
    lineHeight: '1.6',
  },
  main: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '1.6',
  },
  xxl: {
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: 400,
  },
  xxxl: {
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontWeight: 700,
  },
  link: {
    fontFamily: 'Montserrat',
    color: Colors.heliotrope,
    fontWeight: 700,
  },
  strong: {
    fontFamily: 'Montserrat',
    color: Colors.primary,
    fontWeight: 700,
  },
  strongAnakiwa: {
    fontFamily: 'Montserrat',
    color: Colors.anakiwa,
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  center: {
    textAlign: 'center',
  },
};

const colorStyles = {
  colorDefault: {
    color: Colors.secondary,
  },
  colorInverse: {
    color: Colors.white,
  },
  colorAnakiwa: {
    color: Colors.anakiwa,
  },
  colorLightPink: {
    color: Colors.lightPink,
  },
  colorGrey: {
    color: Colors.grey700,
  },
  backgroundGrey: {
    backgroundColor: Colors.grey300,
  },
};

const mainStyles = {
  page: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '40px',
  },
  pageDefault: {
    backgroundColor: Colors.white,
  },
  pageInverse: {
    backgroundColor: Colors.secondary,
  },
  pageGradient: {
    backgroundColor: Colors.secondary,
  },
  wrapper: {
    flexDirection: 'row',
  },
};

const spaceStyles = {
  mb5: {
    marginBottom: '5px',
  },
  mb15: {
    marginBottom: '15px',
  },
  mb20: {
    marginBottom: '20px',
  },
  mb30: {
    marginBottom: '30px',
  },
  mb60: {
    marginBottom: '60px',
  },
};

const layoutStyles = {
  column: {
    flexGrow: 1,
    marginTop: '40px',
    width: '50%',
    padding: '8px 0',
  },
  spaceBetween: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  w33: {
    width: '33.3%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  w75: {
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  radiusTop: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  radiusBottom: {
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
};

const dashboardStyles = {
  dashboard: {
    width: '100%',
    marginLeft: '30px',
    borderRadius: '10px',
  },
  dashboardSection: {
    padding: '20px',
    backgroundColor: Colors.primary,
  },
  dashboardSectionNegative: {
    padding: '20px',
    backgroundColor: Colors.maroon,
  },
  dashboardValue: {
    marginRight: '10px',
  },
  dashboardFlex: {
    flexDirection: 'row',
    flexGrow: '1',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  dashboardElement: {
    padding: '5px',
  },
  dashboardFeature: {
    borderTop: '2px solid white',
    backgroundColor: Colors.primary,
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    padding: '10px 12px',
  },
  dashboardFeatureNegative: {
    borderTop: '2px solid white',
    backgroundColor: Colors.maroon,
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    padding: '10px 12px',
  },
  dashboardImage: {
    width: '20px',
    height: 'auto',
    marginRight: '16px',
  },
};

const styles = StyleSheet.create({
  rightsWrapper: {
    width: '340px',
  },
  footerImage: {
    width: '90px',
    height: 'auto',
  },
  backgroundWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImage: {
    width: '367px',
  },
  linkedinImage: {
    width: '185px',
    height: 'auto',
  },
  feature: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  featureImage: {
    width: '70px',
    height: 'auto',
    marginRight: '20px',
  },
  featureDescription: {
    flexGrow: 1,
  },
  ...dashboardStyles,
  ...spaceStyles,
  ...layoutStyles,
  ...textStyles,
  ...colorStyles,
  ...mainStyles,
});

export default styles;
