import React from 'react';
import styled from 'styled-components';

import { Layout } from '../../../components';
import { Spacing } from '../../../foundations';

const ModalContainer = styled.div`
  width: 100%;
  margin-bottom: ${Spacing.xl};
`;

const ResponsiveModal = ({ title, render, back, onBack, withoutHeader }) => {
  return (
    <ModalContainer>
      <Layout
        backgroundColor="transparent"
        title={title}
        render={render}
        withoutHeader={withoutHeader}
        hasNavigation={!withoutHeader}
        back={back}
        onBack={onBack}
      />
    </ModalContainer>
  );
};

export default ResponsiveModal;
