import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Text, Layout } from '@humanspace/components';
import { Colors, Spacing } from '@humanspace/foundations';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.xxxl};
`;

const CompletedSurvey = () => (
  <React.Fragment>
    <Layout
      backgroundColor={Colors.primary}
      linkElement={Link}
      render={
        <>
          <Text size="l" bold lineHeight={1.1} align="center">
            Survey completed!
          </Text>
          <StyledText size="m" lineHeight={1.7} align="center">
            Thanks for helping us to improve your work experience and
            well-being.
            <br />
            <br />
            <strong>For security, please close this browser window.</strong>
          </StyledText>
        </>
      }
    />
  </React.Fragment>
);

CompletedSurvey.propTypes = {
  isLoading: PropTypes.bool,
};

CompletedSurvey.defaultProps = {
  isLoading: false,
};

export default CompletedSurvey;
