import { combineReducers } from 'redux';
import types from './types';

import authTypes from '../../auth/duck/types';
import projectsTypes from '../../projects/duck/types';
import modelsTypes from '../../models/duck/types';

const currentFlash = (state = { isVisible: false }, action) => {
  switch (action.type) {
    case types.SHOW_FLASH_MESSAGE:
      return { isVisible: true, ...action.payload };
    case types.HIDE_FLASH_MESSAGE:
      return { isVisible: false };
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case authTypes.LOGIN:
    case authTypes.REGISTER:
    case authTypes.PASSWORD_REQUEST:
    case authTypes.PASSWORD_RESET:
    case projectsTypes.GET_PROJECT:
    case projectsTypes.GET_PROJECTS:
    case projectsTypes.CREATE_PROJECT:
    case projectsTypes.CREATE_MODEL:
    case projectsTypes.UPDATE_PROJECT:
    case projectsTypes.CONTACT:
    case projectsTypes.GET_SURVEYS:
    case projectsTypes.GET_SURVEY_DETAILS:
    case projectsTypes.GET_CAPABILITIES:
    case projectsTypes.GET_MODEL_DETAILS_DATA:
    case projectsTypes.GET_PROJECT_ROLE_CHARACTERISTICS:
    case projectsTypes.UPDATE_PROJECT_ROLE_CHARACTERISTICS:
    case projectsTypes.REMOVE_PROJECT:
    case modelsTypes.CREATE_PREDICTION_MODEL:
    case modelsTypes.GET_STRESS_MODELS:
    case modelsTypes.GET_STRESS_MODEL:
    case modelsTypes.REMOVE_STRESS_MODEL:
      return true;
    case authTypes.REGISTER_SUCCESS:
    case authTypes.REGISTER_FAILURE:
    case authTypes.LOGIN_SUCCESS:
    case authTypes.LOGIN_FAILURE:
    case authTypes.PASSWORD_REQUEST_SUCCESS:
    case authTypes.PASSWORD_REQUEST_FAILURE:
    case authTypes.PASSWORD_RESET_SUCCESS:
    case authTypes.PASSWORD_RESET_FAILURE:
    case projectsTypes.CREATE_PROJECT_SUCCESS:
    case projectsTypes.CREATE_PROJECT_FAILURE:
    case projectsTypes.CREATE_MODEL_SUCCESS:
    case projectsTypes.CREATE_MODEL_FAILURE:
    case projectsTypes.UPDATE_PROJECT_SUCCESS:
    case projectsTypes.UPDATE_PROJECT_FAILURE:
    case projectsTypes.CONTACT_SUCCESS:
    case projectsTypes.CONTACT_FAILURE:
    case projectsTypes.GET_SURVEYS_SUCCESS:
    case projectsTypes.GET_SURVEYS_FAILURE:
    case projectsTypes.GET_SURVEY_DETAILS_SUCCESS:
    case projectsTypes.GET_SURVEY_DETAILS_FAILURE:
    case projectsTypes.GET_CAPABILITIES_SUCCESS:
    case projectsTypes.GET_CAPABILITIES_FAILURE:
    case projectsTypes.GET_MODEL_DETAILS_DATA_SUCCESS:
    case projectsTypes.GET_MODEL_DETAILS_DATA_FAILURE:
    case projectsTypes.GET_PROJECT_SUCCESS:
    case projectsTypes.GET_PROJECT_FAILURE:
    case projectsTypes.GET_PROJECTS_SUCCESS:
    case projectsTypes.GET_PROJECTS_FAILURE:
    case projectsTypes.GET_PROJECT_ROLE_CHARACTERISTICS_SUCCESS:
    case projectsTypes.GET_PROJECT_ROLE_CHARACTERISTICS_FAILURE:
    case projectsTypes.UPDATE_PROJECT_ROLE_CHARACTERISTICS_SUCCESS:
    case projectsTypes.UPDATE_PROJECT_ROLE_CHARACTERISTICS_FAILURE:
    case projectsTypes.REMOVE_PROJECT_SUCCESS:
    case projectsTypes.REMOVE_PROJECT_FAILURE:
    case modelsTypes.CREATE_PREDICTION_MODEL_SUCCESS:
    case modelsTypes.CREATE_PREDICTION_MODEL_FAILURE:
    case modelsTypes.GET_STRESS_MODELS_SUCCESS:
    case modelsTypes.GET_STRESS_MODELS_FAILURE:
    case modelsTypes.GET_STRESS_MODEL_SUCCESS:
    case modelsTypes.GET_STRESS_MODEL_FAILURE:
    case modelsTypes.REMOVE_STRESS_MODEL_SUCCESS:
    case modelsTypes.REMOVE_STRESS_MODEL_FAILURE:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  currentFlash,
  isLoading,
});

export default reducer;
