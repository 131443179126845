import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '../Text';
import { useMediaQueryContext } from '../MediaQueryProvider';
import { Spacing, Colors, Sizes } from '../../foundations';

const INPUT_HEIGHT = '40px';

const unitFormatter = unit => value => {
  if (value !== undefined) {
    if (unit === '$') return `${unit} ${value}`;
    return `${value} ${unit}`;
  }

  return '';
};

const getProperSize = (size, isResponsive) => {
  if (isResponsive && size === 'xxs') return Sizes[size];
  if (isResponsive) {
    const currentPosition = Object.keys(Sizes).indexOf(size);
    const previousSize = Object.keys(Sizes)[currentPosition - 1];
    return Sizes[previousSize];
  }
  return Sizes[size];
};

const StyledInputValue = styled(Text)`
  border: 2px solid ${Colors.white};
  line-height: 1.4;
  font-weight: 400;
  padding: 0 ${Spacing.s};
  max-height: ${INPUT_HEIGHT};
  width: 100%;
`;

const StyledInput = styled.input`
  font-family: 'Montserrat', serif;
  border: 2px solid ${props =>
    props.editable ? Colors.white : Colors.primary};
  border-radius: 6px;
  color: ${Colors.primary};
  font-size: ${({ isResponsive, editable }) =>
    editable
      ? getProperSize('s', isResponsive)
      : getProperSize('m', isResponsive)};
  line-height: 1.4;
  font-weight: 600;
  box-shadow: none;
  width: 100%;
  background-color: ${Colors.white}
  padding: ${props => (props.editable ? '0' : Spacing.s)};
  outline: 0;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0 0 auto;
  text-align: center;
  max-height: ${INPUT_HEIGHT};
  -moz-appearance: textfield;

  ${({ editable }) =>
    editable &&
    `
    margin: 0 auto;
    max-width: 120px;`}

  &::placeholder {
    color: ${Colors.primary};
    opacity: 1;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }



  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${Colors.primary};
    transition: background-color 5000s ease-in-out 0s;
    background-color: ${Colors.primary};
  }
`;

const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${Spacing.xs};
  background-color: ${Colors.white};
  box-shadow: 0px 20px 40px rgba(34, 6, 59, 0.4);
`;

const Placeholder = styled(Text)`
  padding: ${Spacing.s};
  outline: 0;
  white-space: normal;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : '70%')};
  flex: 1;

  ${({ editable }) =>
    !editable &&
    `
    width: 100%;
  white-space: nowrap;
  align-self: stretch;
`}

  box-sizing: border-box;
  height: 100%;

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom-left-radius:8px;
    border-bottom: 0;
  `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top-left-radius:8px;
  `};
`;

const Child = styled.div`
  min-height: ${INPUT_HEIGHT};
`;

const InputChild = styled.div`
  min-height: ${INPUT_HEIGHT};

  ${props => !props.editable && ` width: 80px;`}
`;

const Label = styled.label`
  height: ${INPUT_HEIGHT};

  ${props =>
    props.editable &&
    `
    width: 75%;
  `}
`;

const RightSide = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${({ editable }) =>
    editable &&
    `
  min-width: 250px;
  padding: ${Spacing.s};
  `}
`;

const EditSaveLink = styled(Text)`
  cursor: pointer;
  text-align: center;
`;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const AccordionInput = ({
  type,
  isLast,
  isFirst,
  disabled,
  placeholder,
  input,
  secondary,
  editable,
  valueFormat,
  onSave,
  meta: { touched, error },
}) => {
  const [isEditModeActivated, setEditMode] = useState(false);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <ConditionalWrapper
      condition={secondary}
      wrapper={children => (
        <SecondaryWrapper>
          <Child>
            <Label editable={editable} htmlFor={input.name}>
              <Placeholder
                editable={editable}
                bold={!editable}
                size={editable ? 'xs' : 's'}
                isResponsive={isResponsive}
                color={editable ? Colors.grey700 : Colors.primary}
                isFirst={isFirst}
                isLast={isLast}>
                {placeholder}
              </Placeholder>
            </Label>
          </Child>

          <InputChild editable={editable}>{children}</InputChild>
        </SecondaryWrapper>
      )}>
      <RightSide editable={editable}>
        {!isEditModeActivated && editable ? (
          <StyledInputValue align="center" size="xs" color={Colors.grey700}>
            {unitFormatter(valueFormat)(input.value)}
          </StyledInputValue>
        ) : (
          <StyledInput
            value={input.value}
            editable={editable}
            isResponsive={isResponsive}
            id={input.name}
            secondary={secondary}
            {...input}
            disabled={disabled}
            type={type}
            isFirst={isFirst}
            isLast={isLast}
            placeholder={!secondary ? placeholder : ''}
            error={error && touched}
          />
        )}

        {editable && (
          <EditSaveLink
            color={Colors.primary}
            underline
            size="xs"
            onClick={() => {
              if (isEditModeActivated) onSave();
              setEditMode(!isEditModeActivated);
            }}>
            {isEditModeActivated ? 'Save' : 'Edit'}
          </EditSaveLink>
        )}
      </RightSide>
    </ConditionalWrapper>
  );
};

AccordionInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  secondary: PropTypes.bool,
};

AccordionInput.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  isLast: false,
  isFirst: false,
  meta: {
    error: undefined,
    touched: false,
  },
  secondary: false,
};

export default AccordionInput;
