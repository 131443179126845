import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing, Colors } from '../../foundations';
import Text from '../Text';
import Icon from '../Icon';
import Button from '../Button';
import PopoverMenu from '../PopoverMenu';
import { useMediaQueryContext } from '../MediaQueryProvider';

const StyledCapability = styled.button`
  position: relative;
  padding: ${Spacing.l};
  border-radius: 20px;

  background: ${Colors.background};
  border: 0;

  width: 100%;
  height: 100%;
  min-height: ${props => (props.isExtended ? '100px' : '150px')};
  min-width: 165px;

  outline: 0;
`;

const SideWrapper = styled.div`
  position: absolute;
  right: ${Spacing.s};
  top: ${Spacing.s};
  cursor: pointer;
`;

const CapabilityWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isExtended ? 'row' : 'column')}
  justify-content: ${props => (props.isExtended ? 'flex-start' : 'center')};
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  ${props =>
    props.isResponsive
      ? `  width: 50px;
height: 50px;
margin-bottom: ${Spacing.s};
`
      : `width: 30px;
    height: 30px;
    margin-right: ${Spacing.s}`}
`;

const InfoSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.s};
`;

const Description = styled(Text)`
  margin-top: ${Spacing.xxs};
`;

const ButtonSeparator = styled.div`
  margin-right: ${Spacing.xs};
`;

const Capability = ({
  onClick,
  icon,
  value,
  hasValue,
  secondaryAction,
  mainAction,
  isExtended,
  popoverOptions,
  removable,
  onRemove,
  title,
  description,
  mainActionColor,
}) => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <StyledCapability
      isExtended={isExtended}
      onClick={event => {
        onClick && event.preventDefault();
        onClick && onClick();
      }}>
      {removable && (
        <SideWrapper onClick={onRemove}>
          <Icon icon="trash" />
        </SideWrapper>
      )}
      {popoverOptions && (
        <SideWrapper>
          <PopoverMenu options={popoverOptions} />
        </SideWrapper>
      )}
      <CapabilityWrapper isExtended={isExtended}>
        {hasValue && (
          <Text align="center" size="xl" bold>
            {value}
          </Text>
        )}
        {!hasValue && icon && (
          <StyledIcon isResponsive={isResponsive || !isExtended} icon={icon} />
        )}
        <InfoSection>
          <Text align={isExtended ? 'left' : 'center'} size="s" bold>
            {title}
          </Text>
          {description && (
            <Description align={isExtended ? 'left' : 'center'} size="xs">
              {description}
            </Description>
          )}

          <ButtonWrapper>
            {secondaryAction && (
              <ButtonSeparator>
                <Button secondary size="small" onClick={secondaryAction.action}>
                  {secondaryAction.name}
                </Button>
              </ButtonSeparator>
            )}
            {mainAction && (
              <Button
                size="small"
                backgroundColor={mainActionColor}
                onClick={mainAction.action}>
                {mainAction.name}
              </Button>
            )}
          </ButtonWrapper>
        </InfoSection>
      </CapabilityWrapper>
    </StyledCapability>
  );
};

Capability.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  isExtended: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasValue: PropTypes.bool,
  removable: PropTypes.bool,
  mainAction: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
  secondaryAction: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

Capability.defaultProps = {
  onClick: undefined,
  title: '',
  description: undefined,
  icon: undefined,
  isExtended: false,
  value: undefined,
  hasValue: false,
  removable: false,
  mainAction: undefined,
  secondaryAction: undefined,
};

export default Capability;
