import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify-redux';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';

import { errorHandler } from '@humanspace/infrastructure';
import { Colors, Sizes } from '@humanspace/foundations';
import { MediaQueryProvider } from '@humanspace/components';

import sagas from './shared/sagas';
import reducers from './shared/reducers';
import routes from './routes';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const composeEnhancers = composeWithDevTools({
  realtime: true,
});

const store = createStore(
  reducers(history),
  composeEnhancers(
    applyMiddleware(sagaMiddleware, errorHandler, routerMiddleware(history)),
  ),
);

sagaMiddleware.run(sagas);

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

    html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    background-repeat: no-repeat;
    background: ${Colors.verticalGradient};
    min-height: 100vh;
    height: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -moz-font-feature-settings: "liga" on;


  }
  body::-webkit-scrollbar {
    display: none;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  ul {
    margin: 32px 0;
    padding-left: 16px;
  }
  a {
    text-decoration: none;
  }
  strong {
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button, input {
    outline: none;
  }
`;

const StyledToastContainer = styled(ToastContainer).attrs()`
  .Toastify__toast {
    font-family: 'Montserrat', serif;
    font-size: ${Sizes.xs};
  }
  .Toastify__toast--error {
    background-color: ${Colors.error};
  }
  .Toastify__toast--warning {
    background-color: ${Colors.warning};
  }
  .Toastify__toast--success {
    background-color: ${Colors.success};
  }
`;

TagManager.initialize({
  gtmId: 'GTM-W8487L5',
});

const App = () => (
  <Provider store={store}>
    <MediaQueryProvider>
      <ConnectedRouter history={history}>
        {routes}
        <GlobalStyle />
        <StyledToastContainer
          autoClose={3000}
          hideProgressBar
          pauseOnHover={false}
        />
      </ConnectedRouter>
    </MediaQueryProvider>
  </Provider>
);

export default App;
