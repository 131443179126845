import { reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';

import { isRequired } from '@humanspace/utils/validations';

import CreateSurveyForm from './CreateSurveyForm';

const form = 'CreateSurveyForm';

const mapStateToProps = state => ({
  isLoading: state.shared.isLoading,
  synchronousError: getFormSyncErrors(form)(state),
});

const validate = ({ surveyName }) => ({
  surveyName: isRequired(surveyName),
});

const DecoratedForm = reduxForm({
  form,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(CreateSurveyForm);

export default connect(mapStateToProps, null)(DecoratedForm);
