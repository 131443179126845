import React, { useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import styled from 'styled-components';

import {
  Capability,
  Text,
  Loader,
  Layout,
  useMediaQueryContext,
  Icon,
  ReadMore,
  Button,
} from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';
import { mergeProjectsAndModels } from '../../utils/transformers';

const CenterGrid = styled.div`
  width: 100%;
  margin-bottom: ${Spacing.xxl};

  ${props =>
    !props.isResponsive &&
    `  overflow-y: scroll;
    flex-grow: 1;
      padding: 0px ${Spacing.l};
      max-height: 480px;`}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
`;

const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const GridWrapper = styled.div`
  position: relative;
`;

const ArrowWrapper = styled.div`
  padding: ${Spacing.s};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const StyledButton = styled(Button)`
  width: ${props => (props.isResponsive ? '100%' : '220px')};
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: ${Spacing.s};
`;

const ButtonWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CapabilityWrapper = styled.div`
  margin-bottom: ${Spacing.s};
`;

const CreateButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => (props.isResponsive ? Spacing.l : Spacing.s)};
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.l};
  margin-left: 0;

  flex-direction: ${props => (props.isResponsive ? 'column' : 'row')};
`;

const ConditionalWrapper = ({ conditional, isResponsive, children }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const elementsNumber = Children.count(children);
  const hasMoreThanThreeElements = elementsNumber > 3;
  const isArrowVisible = !isResponsive && hasMoreThanThreeElements;

  const handleScrollDown = () => {
    document.querySelector('.js-projects-grid').scroll({
      top: scrollPosition + 100,
      behavior: 'smooth',
    });

    setScrollPosition(scrollPosition + 100);
  };

  return (
    <GridWrapper>
      <CenterGrid className="js-projects-grid" isResponsive={isResponsive}>
        {children}
      </CenterGrid>
      {isArrowVisible && (
        <ArrowWrapper onClick={() => handleScrollDown(1)}>
          <Icon icon="arrow-down" />
        </ArrowWrapper>
      )}
    </GridWrapper>
  );
};

const tagManagerArgs = {
  dataLayer: {
    page: 'home',
  },
  dataLayerName: 'PageDataLayer',
};

const Projects = ({
  getProjects,
  getStressModels,
  isLoading,
  projects,
  removeProject,
  removeStressModel,
  stressModels,
}) => {
  useEffect(() => {
    getProjects();
    localStorage.removeItem('newProject');
    TagManager.dataLayer(tagManagerArgs);
  }, [getProjects]);

  useEffect(() => {
    getStressModels();
  }, [getStressModels]);

  const [items, setItems] = React.useState([]);
  const hasItemsCreated = items && items.length > 0;

  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;
  const history = useHistory();

  useEffect(() => {
    const itemsArray = mergeProjectsAndModels(projects, stressModels);
    setItems(itemsArray);
  }, [projects, stressModels]);

  return (
    <>
      {isLoading ? (
        <Layout
          hasNavigation
          render={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      ) : (
        <Layout
          linkElement={Link}
          multiple
          leftColumn={
            <React.Fragment>
              <Text size="xl" bold lineHeight={1.1}>
                Create or edit your project
              </Text>
              <StyledText size="m" lineHeight={1.7}>
                <ReadMore>
                  HumanSpace ™ helps you find the invisible value of your
                  workspace. Start by creating a project and then organizing all
                  the information within it.
                </ReadMore>
              </StyledText>
              <CreateButtonsContainer isResponsive={isResponsive}>
                <StyledButton
                  isResponsive={isResponsive}
                  onClick={() => history.push('/projects/new')}>
                  <ButtonWithIconWrapper>
                    <StyledIcon icon="plus" />
                    Create project
                  </ButtonWithIconWrapper>
                </StyledButton>
                <StyledButton
                  backgroundColor={Colors.heliotrope}
                  isResponsive={isResponsive}
                  onClick={() => history.push('/models/new')}>
                  <ButtonWithIconWrapper>
                    <StyledIcon icon="plus" />
                    Create model
                  </ButtonWithIconWrapper>
                </StyledButton>
              </CreateButtonsContainer>
            </React.Fragment>
          }
          rightColumn={
            <React.Fragment>
              <ConditionalWrapper
                conditional={hasItemsCreated}
                isResponsive={isResponsive}>
                {items &&
                  items.map(item => (
                    <CapabilityWrapper>
                      <Capability
                        title={item.name}
                        isExtended
                        popoverOptions={[
                          ...(item.type === 'project'
                            ? [
                                {
                                  name: 'Survey',
                                  action: () =>
                                    history.push(
                                      `/projects/${item.id}/surveys`,
                                    ),
                                },
                                {
                                  name: 'Benchmark',
                                  action: () =>
                                    history.push(
                                      `/projects/${item.id}/benchmark`,
                                    ),
                                },
                              ]
                            : []),
                          {
                            name: 'Delete',
                            action: event => {
                              const confirmMessage =
                                item.type === 'model'
                                  ? 'Are you sure you want to delete this model?'
                                  : 'Are you sure you want to delete this project?';

                              const response = window.confirm(confirmMessage);

                              event.preventDefault();
                              if (response === true) {
                                item.type === 'model'
                                  ? removeStressModel(item.id)
                                  : removeProject(item.id);
                              }
                            },
                            secondary: true,
                          },
                        ]}
                        mainActionColor={
                          item.type === 'model'
                            ? Colors.heliotrope
                            : Colors.primary
                        }
                        mainAction={{
                          name:
                            item.type === 'model'
                              ? 'Open model'
                              : 'Open project',
                          action: () => {
                            return item.type === 'model'
                              ? history.push(`/models/${item.id}/results`)
                              : history.push(`/projects/${item.id}/details`);
                          },
                        }}
                      />
                    </CapabilityWrapper>
                  ))}
              </ConditionalWrapper>
            </React.Fragment>
          }
          hasNavigation
        />
      )}
    </>
  );
};

Projects.propTypes = {
  removeProject: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

Projects.defaultProps = {
  projects: [],
  stressModels: [],
};

export default Projects;
