import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { toastsReducer as toasts } from 'react-toastify-redux';

import sharedReducers from './duck';
import projectsReducers from '../projects/duck';
import modelsReducers from '../models/duck';

const reducers = history =>
  combineReducers({
    form,
    router: connectRouter(history),
    shared: sharedReducers,
    projects: projectsReducers,
    models: modelsReducers,
    toasts,
  });

export default reducers;
