import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Text,
  ReadMore,
  Button,
  useMediaQueryContext,
  Link,
  Info,
} from '@humanspace/components';
import { Spacing, Colors } from '@humanspace/foundations';

const StyledButton = styled(Button)`
  margin: ${props => (props.isResponsive ? '0 auto' : '0')};
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.m};
  width: 220px;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

const AboutTheModel = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Info visible={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <React.Fragment>
        <Text color={Colors.black} lineHeight={1.7}>
          <ReadMore>
            <strong>More technical details</strong> <br />
            This model uses a neural network, binary classifier to predict if a
            given combination of design features, environmental conditions, and
            user perceptions (survey data) of the space increase or decrease
            average stress reactions for occupants. We used TensorFlow in python
            for training and TensorFlow JS on the client side for inference.
            <br />
            <br />
          </ReadMore>
        </Text>

        <Text color={Colors.black} lineHeight={1.7}>
          <ReadMore>
            <strong>Even more detail</strong>
            <br /> To avoid overfit, we chose a simple model architecture
            consisting of 3 Dense layers with 24, 12, 6 hidden units
            respectively using relu activation functions and an output layer of
            1 which utilizes a SoftMax activation function to classify a binary
            stress label. We chose a BinaryCrossentropy loss function which
            computes the cross-entropy loss between true labels and predicted
            labels and an RMSProp optimizer.
          </ReadMore>
        </Text>
      </React.Fragment>
    </Info>
  );
};

const Stressed = () => {
  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <React.Fragment>
      <StyledText size="xl" bold lineHeight={1.1}>
        This workspace <Underline>increases stress</Underline>
      </StyledText>
      <Text size="m" lineHeight={1.7}>
        <ReadMore>
          The model in this application uses data from a variety of sources:
          workplace survey ratings, quality of views to windows, visual access
          within the space, enclosure at each work point. The model can also use
          air quality data (temperature and humidity) from the building control
          system or room sensors which is time stamped and can be matched with
          the time of survey completion.
          <br />
          <Link
            size="xs"
            color={Colors.white}
            onClick={() => setIsModalOpen(true)}>
            More About the Model
          </Link>{' '}
          <br />
        </ReadMore>
      </Text>

      <StyledButton
        isResponsive={isResponsive}
        backgroundColor={Colors.heliotrope}
        onClick={() => history.push('/projects')}>
        Go to main screen
      </StyledButton>
      <AboutTheModel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </React.Fragment>
  );
};

const NotStressed = () => {
  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <React.Fragment>
      <StyledText size="xl" bold lineHeight={1.1}>
        This workspace <Underline>reduces stress</Underline>
      </StyledText>
      <Text size="m" lineHeight={1.7}>
        <ReadMore>
          The model in this application uses data from a variety of sources:
          workplace survey ratings, quality of views to windows, visual access
          within the space, enclosure at each work point. The model can also use
          air quality data (temperature and humidity) from the building control
          system or room sensors which is time stamped and can be matched with
          the time of survey completion. <br />
          <Link
            size="xs"
            color={Colors.white}
            onClick={() => setIsModalOpen(true)}>
            More About the Model
          </Link>
          <br />
        </ReadMore>
      </Text>
      <StyledButton
        isResponsive={isResponsive}
        backgroundColor={Colors.heliotrope}
        onClick={() => history.push('/projects')}>
        Go to main screen
      </StyledButton>
      <AboutTheModel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </React.Fragment>
  );
};

const Neutral = () => {
  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isMobile, isTablet } = useMediaQueryContext();
  const isResponsive = isMobile || isTablet;

  return (
    <React.Fragment>
      <StyledText size="xl" bold lineHeight={1.1}>
        This workspace has <Underline>no impact on stress</Underline>
      </StyledText>
      <Text size="m" lineHeight={1.7}>
        <ReadMore>
          The model in this application uses data from a variety of sources:
          workplace survey ratings, quality of views to windows, visual access
          within the space, enclosure at each work point. The model can also use
          air quality data (temperature and humidity) from the building control
          system or room sensors which is time stamped and can be matched with
          the time of survey completion.
          <br />
          <Link
            size="xs"
            color={Colors.white}
            onClick={() => setIsModalOpen(true)}>
            More About the Model
          </Link>
          <br />
        </ReadMore>
      </Text>
      <StyledButton
        isResponsive={isResponsive}
        backgroundColor={Colors.heliotrope}
        onClick={() => history.push('/projects')}>
        Go to main screen
      </StyledButton>
      <AboutTheModel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </React.Fragment>
  );
};

export { Stressed, NotStressed, Neutral };
