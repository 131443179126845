import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Colors, Spacing } from '../../foundations';
import Text from '../Text';

const BUTTON_HEIGHT = '44px';
const BUTTON_HEIGHT_SMALL = '34px';

const getButtonSize = ({ size }) => {
  switch (size) {
    case 'small':
      return '113px';
    default:
      return '220px';
  }
};

const getBackgroundColor = ({ secondary, backgroundColor }) => {
  if (backgroundColor && !secondary) return backgroundColor;
  if (secondary) return 'transparent';
  return Colors.primary;
};

const getBorderColor = ({ secondary, backgroundColor }) => {
  if (backgroundColor) return backgroundColor;
  if (secondary) return Colors.white;
  return Colors.primary;
};

const getProperPadding = ({ size }) => {
  switch (size) {
    case 'small':
      return `${Spacing.xs} ${Spacing.s}`;
    default:
      return `${Spacing.s}`;
  }
};

const getProperHeight = ({ size }) => {
  switch (size) {
    case 'small':
      return BUTTON_HEIGHT_SMALL;
    default:
      return BUTTON_HEIGHT;
  }
};

const getBoxShadow = ({ secondary }) => {
  if (secondary) return 'none';
  return '0px 20px 40px rgba(34, 6, 59, 0.4), inset 0px 1px 3px rgba(255, 255, 255, 0.5);';
};

const StyledButton = styled.button`
  height: ${getProperHeight};
  background-color: ${getBackgroundColor};
  border-radius: 25px;
  padding: ${getProperPadding};
  width: ${props => (props.full ? '100%' : 'auto')};
  min-width: ${getButtonSize};
  border: 1px solid ${getBorderColor};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  box-shadow: ${getBoxShadow};
  :disabled {
    border: 1px solid ${Colors.grey700};
    background-color: ${Colors.grey700};
    cursor: not-allowed;
  }
`;

const Button = ({
  onClick,
  disabled,
  children,
  isLoading,
  secondary,
  backgroundColor,
  size,
  full,
  ...props
}) => (
  <StyledButton
    {...props}
    full={full}
    backgroundColor={backgroundColor}
    size={size}
    secondary={secondary}
    disabled={disabled}
    onClick={!disabled ? onClick : undefined}>
    <Text
      color={secondary && backgroundColor ? backgroundColor : Colors.white}
      lineHeight={0}
      bold
      size={size === 'small' ? 'xs' : 's'}
      align="center"
      spacing="0.5px">
      {isLoading ? 'Loading...' : children}
    </Text>
  </StyledButton>
);

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  full: PropTypes.bool,
};

Button.defaultProps = {
  onClick: undefined,
  disabled: false,
  secondary: false,
  size: 'medium',
  full: false,
};

export default Button;
