import types from './types';

const login = (email, password) => ({
  type: types.LOGIN,
  payload: { email, password },
});

const loginSuccess = () => ({
  type: types.LOGIN_SUCCESS,
});

const loginFailure = error => ({
  type: types.LOGIN_FAILURE,
  payload: error,
});

const register = ({ name, company, email, plainPassword }) => ({
  type: types.REGISTER,
  payload: { name, company, email, plainPassword },
});

const registerSuccess = () => ({
  type: types.REGISTER_SUCCESS,
});

const registerFailure = error => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

const passwordRequest = ({ email }) => ({
  type: types.PASSWORD_REQUEST,
  payload: { email },
});

const passwordRequestSuccess = () => ({
  type: types.PASSWORD_REQUEST_SUCCESS,
});

const passwordRequestFailure = error => ({
  type: types.PASSWORD_REQUEST_FAILURE,
  payload: error,
});

const passwordReset = ({ newPassword, token }) => ({
  type: types.PASSWORD_RESET,
  payload: { newPassword, token },
});

const passwordResetSuccess = () => ({
  type: types.PASSWORD_RESET_SUCCESS,
});

const passwordResetFailure = error => ({
  type: types.PASSWORD_RESET_FAILURE,
  payload: error,
});

export default {
  login,
  loginSuccess,
  loginFailure,
  register,
  registerSuccess,
  registerFailure,
  passwordRequest,
  passwordRequestSuccess,
  passwordRequestFailure,
  passwordReset,
  passwordResetSuccess,
  passwordResetFailure,
};
